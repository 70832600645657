import React, { useEffect, useState, Fragment } from 'react';
import PageTitle from "../../../../layouts/PageTitle";
import '../../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../../services/AxiosInstance';

const Tutoriales = () => {
  const URL = 'api/v3/home/tutoriales/';
  const activeMenu = "Tutoriales";
  const motherMenu = "Soporte";

  const [tutoriales, setTutoriales] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(URL);
      const data = await response.data;
      setTutoriales(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []); // Empty dependency array means this effect runs once after the initial render


  return (
    <Fragment>
      <PageTitle activeMenu={activeMenu} motherMenu={motherMenu} />

      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="row">
          {tutoriales.map((tutorial, index) => (
            <div key={index} className="col-md-6 col-lg-4 mb-4">
              <div className="card">
                {tutorial.is_video ? (
                  <div className="embed-responsive embed-responsive-16by9">
                    <div dangerouslySetInnerHTML={{ __html: tutorial.link_video }} />
                  </div>
                ) : (
                  <img
                    className="card-img-top"
                    alt={tutorial.titulo}
                  />
                )}
                <div className="card-body">
                  <h5 className="card-title">{tutorial.titulo}</h5>
                  <p className="card-text">{tutorial.texto}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </Fragment>
  );
};

export default Tutoriales;
