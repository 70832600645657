import axios from 'axios';
import { store } from '../store/store';

const backendUrl = process.env.REACT_APP_BACKEND;

const axiosInstance = axios.create({
  baseURL: backendUrl,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.auth.auth.access;
  config.params = config.params || {};
  // config.params['Authorization'] = `Bearer ${token}`;
  config.headers['Authorization'] = `Bearer ${token}`;
  config.headers['Accept-Language'] = ['es-ES,es;q=0.9,en'];
  // config.headers['Access-Control-Allow-Headers'] = '*';
  return config;
});

axiosInstance.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response && error.response.status === 401) {
    window.location.href = '/login';
    store.dispatch({ type: 'LOGOUT' });
  }
  return Promise.reject(error);
});

export default axiosInstance;
