import React, { Fragment, useContext, useRef, useState } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";
import { useEffect } from 'react';
import Lightbox from 'lightbox2';
import 'react-perfect-scrollbar/dist/css/styles.css'
import axiosInstance from '../../../../services/AxiosInstance';
import Loading from './loading';
import { handleErrors } from './ErrorHandlingComponent';
import 'lightbox2/dist/css/lightbox.min.css';
import { ThemeContext } from '../../../../context/ThemeContext';
import logo from "../../../../images/logos_citoplus_only_logo.png";
import logoBlanco from "../../../../images/logos_citoplus_only_logo_blanco.png";
import swal from 'sweetalert';
import { CitoPlusLinkIconPrimary } from './CitoPlusLink';
import _ from 'lodash';



const Comentarios = ({
  showSidebarComments,
  setShowSidebarComments,
  urlComentarios,
  urlComentariosParams,
  urlPost,
  selectedPrePostData,
  handleNewComment
}) => {
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [comentarios, setComentarios] = useState([]);
  const [text, setText] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState("");
  const [nombreArchivoAdjunto, setNombreArchivoAdjunto] = useState("");
  const textareaRef = useRef(null);
  const classCard = useRef(null);
  const { background } = useContext(ThemeContext);

  const fetchInitialValues = async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(urlComentariosParams).toString();
      const response = await axiosInstance.get(`${urlComentarios}?${queryParams}`);
      const data = await response.data.results;
      setComentarios(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const container = document.getElementById('DZ_W_Contacts_Body3');
    if (container) {
      new PerfectScrollbar(container);
    }
  }, [showSidebarComments]);

  useEffect(() => {
    if (showSidebarComments) {
      fetchInitialValues();
    }
  }, [showSidebarComments]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const data = {
      ...selectedPrePostData,
      nota: text,
      foto: fileBase64 ? fileBase64 : null
    };
    setLoadingBtn(true);
    try {
      const response = await axiosInstance.post(urlPost, data);
      setText('');
      setFile(null);
      setFileBase64("");
      setNombreArchivoAdjunto("");
      fetchInitialValues();
      handleNewComment(true);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoadingBtn(false);
      setIsSubmitting(false);
    }
  }

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setFile(selectedFile);
      setNombreArchivoAdjunto(selectedFile.name);
      const reader = new FileReader();
      reader.onloadend = () => {
        setFileBase64(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    }
  }

  const handleClearFile = () => {
    setFile(null);
    setFileBase64("");
    setNombreArchivoAdjunto("");
  }

  const handleTextChange = (event) => {
    setText(event.target.value);
    adjustCardHeight();
  }

  const adjustCardHeight = () => {
    if (textareaRef.current) {
      const textareaHeight = textareaRef.current.scrollHeight;
      const cardHeight = textareaHeight + 1000;
      textareaRef.current.style.height = `${cardHeight}px`;
    }
  };

  if (loading) {
    return (
      <>
        <div className={`chatbox ${showSidebarComments ? 'active' : ''}`}>
          <div className='card'>
            <div className="chatbox-close" onClick={() => setShowSidebarComments(false)}></div>
            <div className='card mb-sm-3 mb-md-0 note_card'>
              <div className='card-header chat-list-header text-center'>
                <div></div>
                <div>
                  <h6 className='mb-1'>Comentarios</h6>
                  <p className='mb-0'>Agregar comentario</p>
                </div>
                <div></div>
              </div>
              <PerfectScrollbar
                className='card-body msg_card_body dlab-scroll ps ps--active-y'
                id='DZ_W_Contacts_Body3'
                ref={classCard}
              >
                <ul className='contacts' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <li>
                    <div className='d-flex bd-highlight aling-items-center'>
                      <Loading />
                    </div>
                  </li>
                </ul>
              </PerfectScrollbar>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className={`chatbox ${showSidebarComments ? 'active' : ''}`}>
      <div className='card'>
        <div className="chatbox-close" onClick={() => {
          setShowSidebarComments(false);
          handleClearFile();
        }}></div>
        <div className='card mb-sm-3 mb-md-0 note_card'>
          <div className='card-header chat-list-header text-center'>
            <div></div>
            <div>
              <h6 className='mb-1'>Comentarios</h6>
              <p className='mb-0'>Agregar comentario</p>
            </div>
            <div></div>
          </div>
          <PerfectScrollbar
            className='card-body msg_card_body dlab-scroll ps ps--active-y'
            id='DZ_W_Contacts_Body3'
          >
            <ul className='contacts'>
              {comentarios.length > 0 ? (
                comentarios.map((comentario) => (
                  comentario.tipo_usuario === "administrador" ? (
                    <div
                      className={`d-flex mb-4 justify-content-start`}
                      key={comentario.slug}
                    >
                      <div className="img_cont_msg">
                        {background.value === "dark" ? (
                          <Fragment>
                            <img src={logoBlanco} alt="CitoPlus Admin" width={"90%"} className="rounded-circle user_img_msg" />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img src={logo} alt="CitoPlus Admin" width={"90%"} className="rounded-circle user_img_msg" />
                          </Fragment>
                        )}
                      </div>
                      <div className="msg_cotainer">
                        {comentario.foto !== null && (
                          <div className="pb-4">
                            <a href={comentario.foto} data-lightbox="image">
                              <img src={comentario.foto} alt="Comentario" className="img-fluid" />
                            </a>
                          </div>
                        )}
                        {_.capitalize(comentario.nota)}
                        <span className="msg_time">{comentario.username}</span>
                        <span className="msg_time">{comentario.created_at}</span>
                      </div>
                    </div>
                  ) : (
                    <div
                      className={`d-flex mb-4 justify-content-end`}
                      key={comentario.slug}
                    >
                      <div className="msg_cotainer_send" style={{ backgroundColor: "#0f2242", color: "white" }}>
                        {comentario.foto !== null && (
                          <div className="pb-4">
                            <a href={comentario.foto} data-lightbox="image">
                              <img src={comentario.foto} alt="Comentario" className="img-fluid" />
                            </a>
                          </div>
                        )}
                        {_.capitalize(comentario.nota)}
                        <span className="msg_time_send">
                          {comentario.username ? `(${comentario.username})` : ""}
                          {comentario.nombre_user_nota ? ` - ${comentario.nombre_user_nota}` : ""}
                        </span>
                        <span className="msg_time_send">{comentario.created_at}</span>
                      </div>
                      <div className="img_cont_msg">
                        {background.value === "dark" ? (
                          <Fragment>
                            <img src={logoBlanco} alt="CitoPlus Admin" width={"90%"} className="rounded-circle user_img_msg" />
                          </Fragment>
                        ) : (
                          <Fragment>
                            <img src={logo} alt="CitoPlus Admin" width={"90%"} className="rounded-circle user_img_msg" />
                          </Fragment>
                        )}
                      </div>
                    </div>
                  )
                ))
              ) : (
                <li>
                  <div className='d-flex bd-highlight'>
                    <span>No hay comentarios disponibles.</span>
                  </div>
                </li>
              )}
            </ul>
          </PerfectScrollbar>
        </div>
        <div className="card-footer p-0 justify-content-between align-items-center">
          {file && (
            <div className="file-name mx-5 d-flex justify-content-between align-items-center">
              {nombreArchivoAdjunto}
              <CitoPlusLinkIconPrimary onClick={handleClearFile}>
                <i className='fas fa-trash-alt'></i>
              </CitoPlusLinkIconPrimary>
            </div>
          )}
          <form onSubmit={handleSubmit} className="d-flex w-100">
            <textarea
              className="form-control"
              placeholder="Escribe un comentario..."
              ref={textareaRef}
              value={text}
              onChange={handleTextChange}
              style={{ width: '100%', minWidth: 0, flex: 1 }}
              rows={4}
            />
            <label className="d-flex align-items-center" style={{ padding: '10px', cursor: 'pointer' }}>
              <i className="fas fa-paperclip" style={{ fontSize: '20px' }}></i>
              <input
                type="file"
                className="d-none"
                onChange={handleFileChange}
              />
            </label>
            <button
              type="submit"
              className='btn'
              style={{ paddingTop: '10px', paddingBottom: '10px', paddingLeft: '0px' }}
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                <i className="fas fa-spinner fa-spin" style={{ fontSize: '20px' }}></i>
              ) : (
                <i className="fas fa-paper-plane" style={{ fontSize: '20px' }}></i>
              )}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Comentarios;
