import React, { useEffect, useState } from "react";
import axiosInstance from "services/AxiosInstance";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import _ from "lodash";


const CustomBanner = () => {

  const URL = "/api/v3/core/banner_administrador/";
  const [data, setData] = useState([]);
  /*
  {
    "banner": {
        "data": {
            "activo": true,
            "href": "https://google.com"
        },
        "imagen": "https://us-east-1.linodeobjects.com/citoplusrecmedia/media/core/settings/2024/10/10/image.png?AWSAccessKeyId=YL9V1LDH3LQ0JAI38LPF&Signature=YpLrVeD9whTdJM5SIjkN6FwClmU%3D&Expires=1728569757"
    }
}
    */

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(URL);
      const data = await response.data;
      setData(data);
    } catch (error) {
      handleErrors(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="">
        {data.banner?.data?.activo && (
          <div className="card">
            <a
              href={data.banner?.data?.href}
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={data.banner?.imagen}
                alt=""
                className="img-fluid"
                style={{ width: "100%" }}
              />
            </a>
          </div>
        )}
      </div>
    </>
  );
}

export default CustomBanner;
