import React, { useState } from 'react';
import axiosInstance from 'services/AxiosInstance';
import { Modal, Spinner } from 'react-bootstrap';
import { BotonPrimaryText, BotonDangerText } from 'jsx/components/CitoPlus/components/BotonesCustom';
import { handleErrors } from 'jsx/components/CitoPlus/components/ErrorHandlingComponent';
import { notifySuccess } from 'jsx/components/CitoPlus/components/CitoPlusToastr';

const ImportCSVModal = ({ show, onClose }) => {
  const URL = '/api/v3/usuarios/grupos_copropiedades/importar-csv/';
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false); // Estado de carga

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    if (!file) {
      return; // Asegúrate de que un archivo esté seleccionado antes de continuar
    }

    const formData = new FormData();
    formData.append('file', file);

    setLoading(true); // Mostrar spinner
    try {
      const response = await axiosInstance.post(URL, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        notifySuccess('Archivo CSV importado correctamente');
        onClose(); // Cerrar modal solo si la respuesta es exitosa
      }
    } catch (error) {
      handleErrors(error); // Manejar errores de forma adecuada
    } finally {
      setLoading(false); // Ocultar spinner después de la respuesta
    }
  };

  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Importar CSV</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <input type="file" onChange={handleFileChange} />
      </Modal.Body>
      <Modal.Footer>
        <BotonDangerText onClick={onClose}>
          <i className="fas fa-times me-2"></i>
          Cancelar
        </BotonDangerText>
        <BotonPrimaryText onClick={handleUpload} disabled={loading}>
          {loading ? (
            <>
              <Spinner animation="border" size="sm" className="me-2" />
              Cargando...
            </>
          ) : (
            <>
              <i className="fas fa-upload me-2"></i>
              Importar CSV
            </>
          )}
        </BotonPrimaryText>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportCSVModal;
