import React from 'react';
import MyProfile from './MyProfile';
import CustomBanner from './CustomBanner';

const ConsejoDashboard = () => {
	return(
		<>
			<div className="row">
				<div className="col-xl-12">
					<div className="">
					</div>
				</div>
			</div>
		</>
	)
}
export default ConsejoDashboard;
