// SearchInput.js
import React, { useState, useRef, useEffect } from 'react';
import { BotonPrimary } from './BotonesCustom';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const SearchInputInmuebles = ({ value, onSearchChange, placeholder }) => {

  const navigate = useNavigate();

  var { parmFilter } = useParams();

  const initialValues = {
    search: '',
    consejo_admon: ''
  };
  const [parameterSearch, setParameterSearch] = useState(initialValues);
  const [searching, setSearching] = useState(false);

  const selectRef = useRef(null);

  const handleInputChange = (e) => {
    const searchName = e.target.name;
    const searchText = e.target.value;
    setSearching(true);
    setParameterSearch({
      ...parameterSearch,
      [searchName]: searchText
    });
  };


  const onClearClick = () => {
    setParameterSearch(initialValues);
    setSearching(false);
    if (selectRef.current) {
      selectRef.current.value = "";
    }
    onSearchChange(initialValues);
    navigate('/inmuebles');
  };

  const handleSearchClick = () => {
    onSearchChange(parameterSearch);
  };

  const onEnterPress = () => {
    onSearchChange(parameterSearch);
  };

  useEffect(() => {
    if (parmFilter) {
      setParameterSearch({
        'search': parmFilter,
      });
      setSearching(true);
      onSearchChange({
        'search': parmFilter,
      });
    }
  }, [parmFilter]);

  return (
    <div className="row align-items-center">
      <div className="input-group input-group-sm position-relative">
        <input
          autoFocus
          id="search"
          name="search"
          type="text"
          autoComplete='on'
          value={parameterSearch.search}
          className="form-control form-control-sm"
          placeholder={(placeholder && placeholder) || 'Buscar...'}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onEnterPress && onEnterPress();
            }
          }}
          onChange={handleInputChange}
        />
        <select
          ref={selectRef}
          className="form-control form-control-sm"
          id="consejo_admon"
          name="consejo_admon"
          onChange={handleInputChange}
        >
          <option value="" disabled selected>Consejo</option>
          <option value="true">Si</option>
          <option value="false">No</option>
        </select>
        <div className="input-group-append p-2">
          <BotonPrimary onClick={handleSearchClick} title="Buscar">
            <i className="fas fa-search" />
          </BotonPrimary>
          {searching && (
            <BotonPrimary onClick={onClearClick} title="Limpiar">
              <i className="fas fa-times" />
            </BotonPrimary>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchInputInmuebles;
