import React, { useMemo, useEffect, useState, useCallback } from 'react';
import CitoPlusSmallModal from '../../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../../services/DefaultParamets';
import Tables from '../../components/tables';
import SearchInput from '../../components/input_search';

import 'lightbox2/dist/css/lightbox.min.css';
import noImagen from '../../../../../images/no-image.jpg';
import Comentarios from '../../components/sidebar_comments';
import { Badge, ButtonGroup } from 'react-bootstrap';
import PQRDropdownEstados from '../../components/PQRDropdownEstados';
import { CitoPlusLinkIconPrimary } from '../../components/CitoPlusLink';


export const PQR = () => {

  const URL = '/api/v3/usuarios/pqr/';
  const URL_NOTAS = '/api/v3/usuarios/autorizaciones-notas/';

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [selectedPrePostData, setSelectedPrePostData] = useState({});
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState(URL_NOTAS);
  const [urlComentariosParams, setUrlComentariosParams] = useState({});

  const handleComentariosClick = (value, slug) => {
    setSelectedPrePostData({
      autorizacion: slug,
      nota: ''
    });

    setUrlComentariosParams({
      autorizacion__slug: slug,
      page_size: 100
    })
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  }

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );

  const columns = useMemo(() => [
    {
      id: 'imagen',
      Header: 'Imagen',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Imagen</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <a href={value} data-lightbox="image">
            <img
              src={value}
              alt=""
              className="me-3 rounded"
              width={75}
              onError={e => {
                e.target.onerror = null;
                e.target.src = noImagen;
              }}
            />
          </a>
        )
      ),
    },
    {
      id: 'created_at',
      accessor: 'created_at',
      disableFilters: true,
      disableSortable: false,
      Header: "Creado",
      accessor: row => {
        return (
          <div className="left-align-cell">
            {row.created_at}
            {row.consecutivo && <p className="mb-0">Consecutivo: {row.consecutivo}</p>}
          </div>
        );
      },
    },
    {
      id: 'detalles',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div className="media-body left-align-cell">
            <h5 className="m-b-5">{row.asunto}</h5>
            <p className="mb-0">{row.nota}</p>
            <p>
              <Badge bg="primary">{row.estadousr_display}</Badge>
            </p>
          </div>
        );
      },
    },
    {
      id: 'Inmueble',
      Header: 'Inmueble',
      disableFilters: true,
      disableSortable: true,
      accessor: 'apt_number',
    },
    {
      id: 'actions',
      Header: '',
      disableFilters: true,
      disableSortable: true,
      accessor: (row, index) => {
        return (
          <React.Fragment>
            <ButtonGroup>
              <PQRDropdownEstados
                estadoInicial={row.estado_display}
                indice={index}
                url={URL + row.slug + '/'}
              />
              <CitoPlusLinkIconPrimary
                href="#"
                onClick={() => {
                  handleComentariosClick(row.comentarios, row.slug)
                }}
              >
                <i className="fas fa-comments"></i>
                { row.count_notes > 0 &&
                  <span
                    className="badge bg-light rounded-circle position-absolute top-0 start-100 translate-middle"
                    style={{ zIndex: 1, color: 'black', fontSize: '12px' }}
                  >
                    {row.count_notes}
                  </span> }
              </CitoPlusLinkIconPrimary>
            </ButtonGroup>
          </React.Fragment>
        )
      }
    }
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;

    if (searchValue !== '') {
      defaultParams.search = searchValue;
      search = searchValue;
    } else {
      defaultParams.search = search;
    }

    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    console.log('Cerrar modal');
    setSmallShowModal(false);
  };

  const handleNewComment = (e) => {
    fetchUrl(1, searchValue, '');
  }


  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  // Dropdown cambio de estado del administrador
  const [dropdownStates, setDropdownStates] = useState(['EN PROCESO', 'ABIERTO', 'CERRADO']);
  const handleDropdownChange = async (index, display_state, newState, slug) => {
    console.log(display_state, newState, slug);
    if (newState === 'EN PROCESO') {
      setDropdownStates('EN PROCESO');
    } else {
      try {
        await axiosInstance.patch(`${URL}${slug}/`, {
          estado: parseInt(newState),
        })
        setDropdownStates(display_state);
        const newDropdownStates = [...dropdownStates];
        newDropdownStates[index] = newState;
        setDropdownStates(newDropdownStates);
        console.log(display_state, dropdownStates)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  }

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">PQR</h4>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        <Comentarios
          showSidebarComments={showSidebarComments}
          setShowSidebarComments={setShowSidebarComments}
          // comentarios={selectedComentarios}
          urlComentarios={urlComentarios}
          urlComentariosParams={urlComentariosParams}
          urlPost={urlComentarios}
          selectedPrePostData={selectedPrePostData}
          handleNewComment={handleNewComment}
        />


        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
            // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default PQR;
