import React from "react";
import { Button } from "react-bootstrap";

export const BotonCustom = ({ children, ...props }) => {
  return <Button {...props}>{children}</Button>;
}

// Botones sin iconos

export const BotonPrimaryText = ({ children, ...props }) => {
  return (
    <Button
      variant="primary"
      className={`btn btn-sm btn-citoplus-primary shadow ms-1 ${props.className}`}
      data-toggle="tooltip"
      data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}

export const BotonDangerText = ({ children, className, ...props }) => {
  return (
    <Button
      className={`btn btn-sm btn-danger shadow ms-1 ${className} ${props.className}`}
      data-toggle="tooltip"
      data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}

export const BotonInfoText = ({ children, className, ...props }) => {
  return (
    <Button
      className={`btn btn-sm btn-citoplus-info shadow ms-1 ${props.className}`}
      data-toggle="tooltip"
      data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}

// Botones con icon

export const BotonPrimary = ({ children, className, ...props }) => {
  return (
    <Button
      variant="primary"
      className={`btn btn-sm btn-citoplus-primary content-icon ms-1 shadow ${props.className}`}
      data-toggle="tooltip"
      data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}

export const BotonDanger = ({ children, ...props }) => {
  return (
    <Button
    className={`btn btn-sm btn-danger content-icon ms-1 shadow ${props.className}`}
    data-toggle="tooltip"
    data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}

export const BotonInfo = ({ children, ...props }) => {
  return (
    <Button
      className={`btn btn-lg btn-citoplus-info content-icon ms-1 shadow ${props.className}`}
      data-toggle="tooltip"
      data-placement="right"
      {...props}
    >
      {children}
    </Button>
  );
}
