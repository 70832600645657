import React, { useState, useEffect } from 'react';
import Loading from '../components/loading';
import axiosInstance from '../../../../services/AxiosInstance';
import { Link, useParams } from "react-router-dom";
import PageTitle from '../../../layouts/PageTitle';
import { Table } from 'react-bootstrap';
import { notifySuccess, notifyError } from '../components/CitoPlusToastr';


export const EmailDetail = (props) => {

  const [loading, setLoading] = useState(true);
  const [emailData, setEmailData] = useState({});
  const [emailDetails, setEmailDetails] = useState([]);

  const URL = "/api/v3/mensajeria/mensajeria_email/";

  const { slug } = useParams();

  useEffect(() => {
    setLoading(true);
    const fetchInitialValues = async () => {
      try {
        const response = await axiosInstance.get(`${URL}${slug}/`);
        const data = await response.data;
        setEmailData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    const fetchEmailDetails = async () => {
      try {
        const response = await axiosInstance.get(`${URL}${slug}/emails/`);
        const data = await response.data;
        setEmailDetails(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    }

    fetchInitialValues();
    fetchEmailDetails();
  }, [slug])


  if (loading) {
    return (
      <>
      <PageTitle activeMenu={"Form Envio Masivo"} motherMenu={"Mensajeria"} />
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">Detalle Correo</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Loading />
        </div>
      </div>

      </>
    );
  }

  return (
    <>
    <div className="d-flex aling-items-center mb-4 flex-wrap">
      <h4 className="f-20 font-w600 me-auto">Detalle Correo</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <h2>{emailData.titulo}</h2>
          <p><b>Fecha:</b> {emailData.created_at}</p>
          <p><b>Estado:</b> {emailData.estado}</p>
          <p><b>Envio a todos los residentes:</b> {emailData.todos ? "Si" : "No"}</p>
          <p><b>Envio a todos los propietarios:</b> {emailData.todos_propietarios ? "Si" : "No"}</p>
          <p><b>Emails:</b> {emailData.emails}</p>
          <p><b>Asunto:</b> {emailData.titulo}</p>
          <p><b>Grupos: </b>
            <ul>
              {emailData.grupo.map((grupo, index) => (
                <li key={index} style={{ padding: '2px' }}>{grupo.nombre}</li>
              ))}
            </ul>
          </p>
          <p><b>Mensaje:</b> <div dangerouslySetInnerHTML={{ __html: emailData.msg }} /></p>
        </div>
      </div>

      <div>
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">Información de los correos</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Table responsive>
                <thead>
                  <tr>
                    <th>
                      <strong>ID</strong>
                    </th>
                    <th>
                      <strong>Creado</strong>
                    </th>
                    <th>
                      <strong>Actualizado</strong>
                    </th>
                    <th>
                      <strong>Inmueble</strong>
                    </th>
                    <th>
                      <strong>Email</strong>
                    </th>
                    <th>
                      <strong>Enviado</strong>
                    </th>
                    <th>
                      <strong>Fallido</strong>
                    </th>
                    <th>
                      <strong>Abierto</strong>
                    </th>
                    <th>
                      <strong>Click</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {emailDetails.map((email, index) => (
                    <tr key={index}>
                      <td>{email.id}</td>
                      <td>{email.created_at}</td>
                      <td>{email.updated_at}</td>
                      <td>
                        <Link to={`/inmuebles/${email.inmueble && email.inmueble.includes('_') ? email.inmueble.split('_')[1] : ''}`}>
                          {email.inmueble}
                        </Link>
                      </td>
                      <td>{email.email}</td>
                      <td className='text-center'>{email.send}</td>
                      <td className='text-center'>{email.fail}</td>
                      <td className='text-center'>{email.open}</td>
                      <td className='text-center'>{email.click}</td>
                    </tr>
                  ))}
                </tbody>
            </Table>
          </div>
        </div>
      </div>

    </>
  )

}

export default EmailDetail
