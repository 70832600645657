import React, { useState } from 'react';
import { ButtonGroup, Dropdown, DropdownButton } from 'react-bootstrap';
import axiosInstance from '../../../../services/AxiosInstance';


const AutorizacionesDropdownEstados = ({ estadoInicial, indice, url, className }) => {
  const [estado, setEstado] = useState(estadoInicial);

  const handleDropdownChange = async (id_esatdo, nuevoEstado) => {
    try {
      // Realizar una solicitud PATCH al endpoint correspondiente para actualizar el estado
      await axiosInstance.patch(url, {
        estado: id_esatdo,
      });

      // Si la solicitud PATCH es exitosa, actualizar el estado del Dropdown en la interfaz de usuario
      setEstado(nuevoEstado);
    } catch (error) {
      console.error('Error al actualizar el estado del elemento:', error);
    }
  };

  const getVariantByEstado = (estados) => {
    switch (estado) {
      case 'APROBADO':
        return 'citoplus-success';
      case 'RECHAZADO':
        return 'citoplus-danger';
      case 'EN PROCESO':
        return 'citoplus-warning';
      case 'DESHABILITADO':
        return 'citoplus-secondary';
      default:
        return 'citoplus-info';
    }
  };

  const estados = [
    { id: 0, nombre: 'APROBADO', color: 'green' },
    { id: 1, nombre: 'RECHAZADO', color: 'red' },
    { id: 2, nombre: 'EN PROCESO', color: 'orange' },
    { id: 5, nombre: 'DESHABILITADO', color: 'gray' },
  ];

  return (
    <>
      <DropdownButton
        as={ButtonGroup}
        title={estado}
        id='dropdown-basic'
        variant={getVariantByEstado()}
        size="xs">
        <h5 className="dropdown-header" style={{ fontWeight: 'bold' }}>Cambio de Estado</h5>
        {estados.map((estadoItem) => (
          <Dropdown.Item
            key={estadoItem.id}
            onClick={() => handleDropdownChange(estadoItem.id, estadoItem.nombre)}
            style={{ color: estadoItem.color }} // Aplicar color al texto del estado
          >
            {estadoItem.nombre}
          </Dropdown.Item>
        ))}
      </DropdownButton>

    </>
  );
};

export default AutorizacionesDropdownEstados;
