import _ from 'lodash';
import React, { useState, useEffect } from "react";
import { Card, Col, Tabs, Tab, Row, Button } from "react-bootstrap";
import axiosInstance from "../../../../services/AxiosInstance";
import CorrespondenciaSalida from './FormVigilante/CorrespondenciaSalida';

import { useVigilanteReloadContext } from "../../../../context/VisitantesVigilantesContext";
import { LinkPrimary } from '../components/CitoPlusLink';

import { formatDate } from '../utils';
import DatePickerRanger from '../components/DatePickerRanger';

import ingreso_mensajeria from '../../../../images/vigilantes/ingreso_mensajeria_5_transparente.png';
import noImagen from '../../../../images/no-image.jpg';

import { BotonInfo, BotonPrimary, BotonPrimaryText } from '../components/BotonesCustom';

export const Correspondencia = () => {
  const { reloadData, setReloadData } = useVigilanteReloadContext();

  const URL = "/api/v3/correspondencia/vigilantes/";
  const URL_PENDIENTE = `${URL}pendientes/`;
  const URL_HISTORIAL = `${URL}historial/`;

  const [pendientes, setPendientes] = useState([]);
  const [historial, setHistorial] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasNextPage, setHasNextPage] = useState(false);

  // Filtro
  const [filterPendientes, setFilterPendientes] = useState('');

  const [dateRangeStart, setDateRangeStart] = useState([null, null]);
  const [startDateStart, endDateStart] = dateRangeStart;

  const [filterText, setFilterText] = useState('');
  const [filterDate, setFilterDate] = useState({
    date_after: '',
    date_before: ''
  });

  const handleSearch = (event) => {
    event.preventDefault();
    fetchPendientes();
    fetchHistorial(1);
  };

  const clearFilters = () => {
    setFilterText('');
    setFilterDate({
      date_after: '',
      date_before: ''
    });
    fetchPendientes();
    fetchHistorial(1);
  };
  // fin filtro

  useEffect(() => {
    fetchPendientes();
  }, []);

  useEffect(() => {
    if (reloadData.correspondencia) {
      fetchPendientes();
      fetchHistorial(1);
      setReloadData(prev => ({ ...prev, correspondencia: false }));
    }
  }, [reloadData.correspondencia]);

  const fetchPendientes = async () => {
    try {
      const response = await axiosInstance.get(URL_PENDIENTE);
      setPendientes(response.data);
    } catch (error) {
      console.error("Error fetching pendientes:", error);
    }
  };

  const fetchHistorial = async (page = 1, loadMore = false) => {
    try {
      const response = await axiosInstance.get(URL_HISTORIAL, {
        params: {
          page: loadMore ? page + 1 : 1,
          search: filterText,
          ...filterDate
        }
      });
      if (page === 1) {
        setHistorial(response.data.results);
      } else {
        setHistorial((prevHistorial) => [...prevHistorial, ...response.data.results]);
      }
      setHasNextPage(response.data.next !== null);
      setCurrentPage(page);
    } catch (error) {
      console.error("Error fetching historial:", error);
    }
  };

  const handleLoadMore = () => {
    if (hasNextPage) {
      fetchHistorial(currentPage + 1);
    }
  };

  const handleMarcarRecogido = (uuid) => {
    setUuidCorrespondencia(uuid);
    setShowModalSalida(true);
  };

  // Inicio Modal de salida Correspondencia.
  const [showModalSalida, setShowModalSalida] = useState(false);
  const [uuidCorrespondencia, setUuidCorrespondencia] = useState(null);
  // Fin

  return (
    <div>
      <div className='d-flex align-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Correspondencia</h4>
      </div>

      <Tabs
        defaultActiveKey="pendientes"
        id="correspondencia-tabs"
        className="mb-3"
        onSelect={(key) => {
          if (key === "historial") {
            fetchHistorial(1);
          }
        }}
      >
        <Tab eventKey="pendientes" title="Pendientes">
          <Row>
            <form className="row mb-3" onSubmit={e => e.preventDefault()}>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Buscar en pendientes"
                  value={filterPendientes}
                  onChange={(e) => setFilterPendientes(e.target.value)}
                />
              </div>
            </form>
            {pendientes.length === 0 ? (
              <Col xl={12} className="text-center">
                <img src={ingreso_mensajeria} alt="No hay correspondencia pendiente" className="img-fluid" />
                <h3 className="mt-3">No hay correspondencia pendiente</h3>
              </Col>
            ) : (
              pendientes.filter((p) =>
                p.obj.toLowerCase().includes(filterPendientes.toLowerCase()) ||
                p.inmueble?.toLowerCase().includes(filterPendientes.toLowerCase())
              ).map((item, index) => (
                <Col xl={6} key={index}>
                  <Card>
                    <Card.Body>
                      <h4 className="fs-20">{_.startCase(item.vigilante_nombre_ingreso)}</h4>
                      <div className="listline-wrapper mb-4">
                        <span className="item">
                          {item.inmueble && (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                  <rect x="0" y="0" width="24" height="24" />
                                  <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                                  <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                                  <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                                </g>
                              </svg>
                              {item.inmueble}
                            </>
                          )}
                          <i className="fas fa-caret-right ms-2 me-2"></i>
                          {item.created_at}
                        </span>
                      </div>
                      <p className="user d-sm-flex d-block pe-md-3 pe-0">
                        {item.foto ? (
                          <a href={item.foto} data-lightbox={`image${index}`}>
                            <img
                              src={item.foto || noImagen}
                              alt=""
                              style={{ objectFit: "cover" }}
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = noImagen;
                              }}
                            />
                          </a>
                        ) : (
                          <></>
                        )}
                        <div className='ms-sm-3 m3-md-5 md-0'>
                          {_.startCase(_.toLower(item.obj))}
                        </div>
                      </p>
                    </Card.Body>
                    <Card.Footer className="d-flex justify-content-between align-items-center flex-wrap">
                      <div className='mb-2'>
                      </div>
                      <div className='mb-2'>
                        <LinkPrimary
                          href="#"
                          className="position-relative"
                          onClick={() => handleMarcarRecogido(item.slug)}
                        >
                          <i className="fas fa-check me-2"></i>
                          Marcar como Recogido
                        </LinkPrimary>
                      </div>
                    </Card.Footer>
                  </Card>
                </Col>
              ))
            )}
          </Row>
        </Tab>
        <Tab eventKey="historial" title="Historial">
          <Row>
          <div className="row mb-4">
            <div className="col-xl-6">
              <form className="row" onSubmit={handleSearch}>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control sm"
                    placeholder="Buscar por texto"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                </div>
                <div className="col-md-2">
                  <BotonInfo onClick={clearFilters}>
                    <i className="fas fa-times"></i>
                    Limpiar Filtros
                  </BotonInfo>
                </div>
                <div className="col-md-2">
                  <BotonPrimaryText type="submit">
                    <i className="fas fa-filter me-2"></i>
                    Filtrar
                  </BotonPrimaryText>
                </div>
              </form>
            </div>
          </div>
            {historial.map((item, index) => (
              <Col xl={6} key={index}>
                <Card>
                  <Card.Body>
                    <h4 className="fs-20">{_.startCase(item.vigilante_nombre_ingreso)}</h4>
                    <div className="listline-wrapper mb-4">
                      <span className="item">
                        <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" version="1.1" className="svg-main-icon">
                          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                            <rect x="0" y="0" width="24" height="24" />
                            <path d="M13.5,21 L13.5,18 C13.5,17.4477153 13.0522847,17 12.5,17 L11.5,17 C10.9477153,17 10.5,17.4477153 10.5,18 L10.5,21 L5,21 L5,4 C5,2.8954305 5.8954305,2 7,2 L17,2 C18.1045695,2 19,2.8954305 19,4 L19,21 L13.5,21 Z M9,4 C8.44771525,4 8,4.44771525 8,5 L8,6 C8,6.55228475 8.44771525,7 9,7 L10,7 C10.5522847,7 11,6.55228475 11,6 L11,5 C11,4.44771525 10.5522847,4 10,4 L9,4 Z M14,4 C13.4477153,4 13,4.44771525 13,5 L13,6 C13,6.55228475 13.4477153,7 14,7 L15,7 C15.5522847,7 16,6.55228475 16,6 L16,5 C16,4.44771525 15.5522847,4 15,4 L14,4 Z M9,8 C8.44771525,8 8,8.44771525 8,9 L8,10 C8,10.5522847 8.44771525,11 9,11 L10,11 C10.5522847,11 11,10.5522847 11,10 L11,9 C11,8.44771525 10.5522847,8 10,8 L9,8 Z M9,12 C8.44771525,12 8,12.4477153 8,13 L8,14 C8,14.5522847 8.44771525,15 9,15 L10,15 C10.5522847,15 11,14.5522847 11,14 L11,13 C11,12.4477153 10.5522847,12 10,12 L9,12 Z M14,12 C13.4477153,12 13,12.4477153 13,13 L13,14 C13,14.5522847 13.4477153,15 14,15 L15,15 C15.5522847,15 16,14.5522847 16,14 L16,13 C16,12.4477153 15.5522847,12 15,12 L14,12 Z" fill="#000000" />
                            <rect fill="#FFFFFF" x="13" y="8" width="3" height="3" rx="1" />
                            <path d="M4,21 L20,21 C20.5522847,21 21,21.4477153 21,22 L21,22.4 C21,22.7313708 20.7313708,23 20.4,23 L3.6,23 C3.26862915,23 3,22.7313708 3,22.4 L3,22 C3,21.4477153 3.44771525,21 4,21 Z" fill="#000000" opacity="0.3" />
                          </g>
                        </svg>
                        {item.created_at}
                      </span>
                    </div>
                    <p className="user d-sm-flex d-block pe-md-3 pe-0">
                      <picture>
                      {item.foto ? (
                        <a href={item.foto} data-lightbox={`image${index}`}>
                          <img
                            src={item.foto || noImagen}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      ) : null }
                      {item.recibido_firma ? (
                        <a href={item.recibido_firma} data-lightbox={`image${index}`}>
                          <img
                            src={item.recibido_firma || noImagen}
                            alt=""
                            style={{ objectFit: "cover" }}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = noImagen;
                            }}
                          />
                        </a>
                      ) : null }
                      </picture>
                      <div className='ms-sm-3 m3-md-5 md-0'>
                        <p>{item.obj}</p>
                        <p>Nota Salida: {item.nota_salida}</p>
                      </div>
                    </p>
                  </Card.Body>
                  <Card.Footer className="d-flex justify-content-between align-items-center flex-wrap">
                    <div className='mb-2'>
                      <span className="text-muted">
                        Vigilante: {item.vigilante_nombre_salida}
                      </span>
                      {item.recibido_nombre && (
                        <p className='mb-0 text-black font-w500 mt-1'>
                          Recogido: {item.recibido_nombre}
                        </p>
                      )}
                    </div>
                    <div className='mb-2'>
                      <span className="text-muted">Recogido el {item.updated_at}</span>
                    </div>
                  </Card.Footer>
                </Card>
              </Col>
            ))}
          </Row>
          {hasNextPage && (
            <div className="d-flex justify-content-center mt-3">
              <Button onClick={handleLoadMore}>Cargar más</Button>
            </div>
          )}
        </Tab>
      </Tabs>

      <CorrespondenciaSalida
        uuidCorrespondencia={uuidCorrespondencia}
        showModal={showModalSalida}
        onClose={() => setShowModalSalida(false)}
      />
    </div>
  );
};

export default Correspondencia;
