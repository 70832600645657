import React, { useMemo, useEffect, useState, useCallback, Fragment } from 'react';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { useTable, useFilters, usePagination } from 'react-table';
import axiosInstance from 'services/AxiosInstance';
import { getDefaultParams } from 'services/DefaultParamets';
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import swal from 'sweetalert';
import { Link } from 'react-router-dom';
import { LinkPrimary } from 'jsx/components/CitoPlus/components/CitoPlusLink';
import SearchTextWithDateRange from 'jsx/components/CitoPlus/components/SearchWithDateRange';

import '../../../table/FilteringTable/filtering.css';

import 'lightbox2/dist/css/lightbox.min.css';


const getFileExtension = (url) => {
  const pathname = new URL(url).pathname;
  const extension = pathname.split('.').pop().toLowerCase();
  return extension.includes('/') ? '' : extension;
};

const extensionIconMap = {
  'pdf': 'fa-file-pdf',
  'jpg': 'fa-file-image',
  'jpeg': 'fa-file-image',
  'png': 'fa-file-image',
  'doc': 'fa-file-word',
  'docx': 'fa-file-word',
  'xls': 'fa-file-excel',
  'xlsx': 'fa-file-excel',
  // Agrega más extensiones según sea necesario
};

export const Documentos = () => {
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const URL = '/api/v3/usuarios/documentos/';
  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    { id: 'slug', Header: 'Slug', accessor: 'slug', disableFilters: true, disableSortable: true },
    { id: 'title', Header: 'Título', accessor: 'title' },
    { id: 'date', Header: 'Fecha', accessor: 'date' },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async (pageIndex = 1, filterParams, ordering = [], append = false) => {
    defaultParams.page = pageIndex;
    setLoading(true);

    if (append) {
      setLoadingMore(true);
    } else {
      setLoading(true);
    }

    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(prevData => append ? [...prevData, ...response.data.results] : response.data.results);
    } catch (error) {
      handleErrors(error);
    } finally {
      if (append) {
        setLoadingMore(false);
      } else {
        setLoading(false);
      }
    }
  };

  const deleteDocument = async (slug) => {
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este documento.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(async (willDelete) => {
        if (willDelete) {
          try {
            await axiosInstance.delete(`${URL}${slug}/`);
            fetchUrl(1);
          } catch (error) {
            handleErrors(error);
          }
        }
      });
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleSortingChange = (column) => {
    const { id } = column;
    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';
    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  const loadMore = () => {
    if (currentPage < totalPages) {
      fetchUrl(currentPage + 1, "", [], true);
    }
  };

  const openDocumentModal = (documento) => {
    setSelectedDocument(documento);
    setShowDocumentModal(true);
  };

  const closeDocumentModal = () => {
    setSelectedDocument(null);
    setShowDocumentModal(false);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  return (
    <Fragment>
      <div className="d-flex align-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">Documento</h4>
        <div className='d-flex justify-content-center'>
          <SearchTextWithDateRange
            onSearchChange={handleSearchChange}
            onEnterPress={handleEnterPress}
            onClearClick={handleClearClick}
            showDatePicker={true}
            placeholderText="Fecha..."
          />
        </div>
        <LinkPrimary
          to={'/form-agregar-documento/'}
          className="btn btn-citoplus-primary me-3 btn-xs"
        >
          Agregar Documentos
        </LinkPrimary>
      </div>
      <Row>
        {loading ? (
          <p>Cargando...</p>
        ) : data.length === 0 ? (
          <p>No hay datos disponibles.</p>
        ) : (
          <Fragment>
            <Row>
              {data.map((documento, index) => (
                <Col key={index} md={3} className="mb-4">
                  <Card>
                    <Card.Body className="text-center">
                      <i className={`fas ${extensionIconMap[getFileExtension(documento.file)] || 'fa-file'} fa-3x`}></i>
                      <Card.Title className="mt-2">{documento.titulo}</Card.Title>
                      <Card.Text>
                        {documento.date}
                      </Card.Text>
                      <Button onClick={() => deleteDocument(documento.slug) } className="btn btn-danger btn-sm me-3">
                        <i className="fa fa-trash"></i>
                      </Button>
                      <Button onClick={() => openDocumentModal(documento)} className="btn btn-info me-3 btn-sm">
                        <i className="fa fa-eye"></i>
                      </Button>
                      <Link to={`/form-agregar-documento/${documento.slug}`} className="btn btn-warning btn-sm">
                        <i className="fa fa-edit"></i>
                      </Link>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {currentPage < totalPages && (
              <div className="text-center mt-4">
                <Button onClick={loadMore} disabled={loadingMore} className='btn btn-citoplus-primary'>
                  {loadingMore ? 'Cargando...' : 'Cargar más'}
                </Button>
              </div>
            )}
          </Fragment>
        )}
      </Row>

      <Modal show={showDocumentModal} onHide={closeDocumentModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{selectedDocument?.titulo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {selectedDocument && (
              <iframe
                src={selectedDocument.file}
                title={selectedDocument.titulo}
                width="100%"
                height="500px"
              ></iframe>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDocumentModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
};

export default Documentos;
