import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import axiosInstance from '../../../services/AxiosInstance';
import { useNavigate } from 'react-router-dom';

const Statistics = ()=> {

  const URL = "/api/v3/home/dashboard/administradores/"

  const navigate = useNavigate();

  const [data, setData] = useState({
    "correspondencia": 0,
    "minuta": 0,
    "sms": 0,
    "visitantes": 0,
    "parking": 0,
    "emails": 0
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(URL);
        const data = await response.data;
        setData(data)
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);


	return(
		<>
      <div className="card">
        <div className="card-body">
          <div className="row shapreter-row">
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate("/inmuebles")} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="fas fa-home"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.inmuebles} duration={2} /> de <CountUp end={data.cantidad_citofonos} duration={2} />
                </h3>
                <span className="fs-14">Inmuebles</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate(`/inmuebles/rechazado`)} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="fas fa-at"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.emails_error} duration={2}/>
                </h3>
                <span className="fs-14">Email Dañados</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate("/envio_masivo_sms")} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="far fa-message"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.sms} duration={3}/>
                </h3>
                <span className="fs-14">SMS Enviados</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate("/registros_visitantes")} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="fas fa-person"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.visitantes} duration={5}/>
                </h3>
                <span className="fs-14">Visitantes</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate("/facturacion-vehicular")} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="fas fa-parking"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.parking} duration={2}/>
                </h3>
                <span className="fs-14">Vehiculos</span>
              </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-sm-4 col-6" onClick={() => navigate("/correspondencia")} style={{ cursor: 'pointer' }}>
              <div className="static-icon">
                <span>
                  <i className="fas fa-truck-fast"></i>
                </span>
                <h3 className="count mb-0">
                  <CountUp end={data.correspondencia} duration={3}/>
                </h3>
                <span className="fs-14">Correspondencia</span>
              </div>
            </div>
          </div>
        </div>
      </div>
		</>
	)
}
export default Statistics;
