import React, { useState, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import Loading from "../components/loading";
import { Formik, ErrorMessage, Field, FieldArray } from "formik";
import * as Yup from "yup";
import QuillEditor from "../components/field_quill";
import CustomClearIndicator from "../components/MultiSelect";
import axiosInstance from "../../../../services/AxiosInstance";
import { Link } from "react-router-dom";
import DatePicker from 'react-datepicker'; // Asegúrate de importar correctamente
import 'react-datepicker/dist/react-datepicker.css';
import swal from "sweetalert";
import { useNavigate } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import moment from 'moment';
import { MultiSelectSelectVivienda } from "../components/FieldBasic";
import { notifyError } from "../components/CitoPlusToastr";
import ReactGA from 'react-ga4';


const formEmail = Yup.object().shape({
  asunto: Yup.string()
    .required("Por favor, ingresa un asunto.")
    .nullable(true),
  mensaje: Yup.string()
    .required("Por favor, ingresa un mensaje.")
    .nullable(true),
  adjuntos: Yup.array()
    .max(2, 'No puede enviar más de 2 archivos')
    .test('fileSize', 'El tamaño del archivo no puede exceder los 5 MB.', (files) => {
      if (!files) return true;
      return files.every(file => file.size <= 5 * 1024 * 1024);
    })
    .test('fileFormat', 'Solo se permiten archivos de tipo Documento, Imagen o Video.', (files) => {
      if (!files) return true;
      return files.every(file => {
        const allowedFormats = ['application/pdf', 'image/jpeg', 'image/png'];
        return allowedFormats.includes(file.type);
      });
    })

})


export const FormCrearEmail = (props) => {

  ReactGA.event("form-create-email-page", "/create_email_masivo");

  const initialValuesEmpty = {
    asunto: null,
    enviar_a_todos_residentes: false,
    enviar_a_todos_propietarios: false,
    grupos: [],
    citofonos: [],
    mensaje: null,
    adjuntos: [],
    programacion_envio: moment().toDate(),
  }

  const [loadingBtn, setLoadingBtn] = useState(true);
  const [loading, setLoading] = useState(true);
  const [finishedEffects, setFinishedEffects] = useState(0);

  var URL = "/api/v3/mensajeria/mensajeria_email/";
  const URL_GRUPOS = '/api/v3/usuarios/grupos_copropiedades/dropdown/';
  const [grupoOptions, setGrupoOptions] = useState([]);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  const { slug, reenviar } = useParams();

  const navigate = useNavigate();

  const formatProgramacionEnvio = (date) => {

    let parseDate;

    if (date === null) {
      parseDate = new Date();
    } else {
      parseDate = new Date(date);
    }
    console.log(parseDate);
    let formattedDate;

    if (isNaN(parseDate.getTime())) {
      formattedDate = format(date, "yyyy-MM-dd HH:mm:ss");
    } else {
      formattedDate = format(parseDate, "yyyy-MM-dd HH:mm:ss");
    }
    console.log(formattedDate);
    return formattedDate;
  };


  useEffect(() => {
    const fetchGrupOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_GRUPOS);
        const data = await response.data;
        setGrupoOptions(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setFinishedEffects(prev => prev + 1);
      }
    }

    fetchGrupOptions();

  }, [])

  useEffect(() => {
    if (slug && slug.slug != "") {
      setLoading(true);
      const fetchInitialValues = async () => {
        try {
          const response = await axiosInstance.get(`${URL}${slug}/`);
          const data = await response.data;

          // Ajustar Fecha y hora
          let programacion_envio = data.programacion_envio
          if (data.programacion_envio !== null) {
            programacion_envio = moment(data.programacion_envio, "DD/MM/YYYY HH:mm:ss").toDate();
          }

          const initialValues = {
            asunto: data.titulo,
            enviar_a_todos_residentes: data.todos,
            enviar_a_todos_propietarios: data.todos_propietarios,
            grupos: data.grupo.map((group) => ({ slug: group.slug, nombre: group.nombre })),
            citofonos: data.citofonos,
            mensaje: data.msg,
            adjuntos: data.file1 ? [data.file1] : [],
            programacion_envio: programacion_envio
          }
          setInitialValues(initialValues);

        } catch (error) {
          console.error("Error fetching data:", error);
        } finally {
          setLoading(false);
          setLoadingBtn(false);
        }
      }
      fetchInitialValues();
    } else {
      setLoading(false);
      setLoadingBtn(false);
    }
  }, [slug])

  const handleSubmit = async (values, { setSubmitting, resetForm, setFieldError }) => {
    setLoadingBtn(true);
    try {
      let formData = {};
      let headers = {};
      if (values.adjuntos.length > 0) {
        formData = new FormData(); // Crea un objeto FormData para enviar los datos

        // Agrega los valores del formulario al FormData
        console.log(values.citofonos);
        formData.append('titulo', values.asunto);
        formData.append('grupo_slugs', JSON.stringify(values.grupos)); // Convierte a JSON si es necesario
        formData.append('citofonos_slugs', JSON.stringify(values.citofonos));
        formData.append('msg', values.mensaje);
        formData.append('todos', values.enviar_a_todos_residentes);
        formData.append('todos_propietarios', values.enviar_a_todos_propietarios);
        if (values.programacion_envio) {
          formData.append('programacion_envio', formatProgramacionEnvio(values.programacion_envio));
        }

        // Agrega los archivos al FormData
        values.adjuntos.forEach((file, index) => {
          if (index === 0) {
            formData.append('file', file);
          } else if (index === 1) {
            formData.append('file2', file);
          }
          // formData.append(`adjuntos[${index}]`, file); // Adjunta cada archivo con un nombre único
        });

        headers = {
          'Content-Type': 'multipart/form-data',
        }
      } else {
        formData = {
          titulo: values.asunto,
          grupo_slugs: values.grupos,
          citofonos_slugs: values.citofonos || [],
          msg: values.mensaje,
          programacion_envio: formatProgramacionEnvio(values.programacion_envio),
          todos: values.enviar_a_todos_residentes,
          todos_propietarios: values.enviar_a_todos_propietarios,
        }
        headers = {
          'Content-Type': 'application/json',
        }

      }
      if (slug && reenviar !== undefined) {
        const response = await axiosInstance.post(URL, formData, { headers });
        const data = await response.data;
      } else if (slug && reenviar === undefined) {
        URL = `${URL}${slug}/`;
        const response = await axiosInstance.put(URL, formData, { headers });
        const data = await response.data;
      } else {
        const response = await axiosInstance.post(URL, formData, { headers });
        const data = await response.data;
      }
      ReactGA.event("form-create-email-create", "/create_email_masivo");
      resetForm();
      setInitialValues(initialValuesEmpty);
      navigate('/envio_masivo_email');
    } catch (error) {


      if (error.response && error.response.data) {
        const apiErrors = error.response.data.errors || {};

        Object.keys(apiErrors).forEach((fieldName) => {
          let formattedErrors = null;

          if (Array.isArray(apiErrors[fieldName])) {
            formattedErrors = apiErrors[fieldName].map((error, index) => (
              <React.Fragment key={index}>
                {error.message || error}
                {index < apiErrors[fieldName].length - 1 && <br />} {/* Agrega <br /> entre errores */}
              </React.Fragment>
            ));
          } else if (typeof apiErrors[fieldName] === 'string') {
            formattedErrors = (
              <React.Fragment>
                {apiErrors[fieldName]}
              </React.Fragment>
            );
          } else if (typeof apiErrors[fieldName] === 'object' && apiErrors[fieldName] !== null) {

            console.log(apiErrors[fieldName].attr, apiErrors[fieldName].detail);

            formattedErrors = (
              <React.Fragment>
                {apiErrors[fieldName].detail}
              </React.Fragment>
            );

            notifyError(apiErrors[fieldName].detail);
          }
          setFieldError(apiErrors[fieldName].attr, formattedErrors);
        });
      } else {
        swal('Error', error.message, "error");
      }


    } finally {
      setLoadingBtn(false);
    }
  }

  if (loading) {
    return (
      <>
        <PageTitle activeMenu={"Envio Correo"} motherMenu={"Mensajeria"} />
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">Envio Correo</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Loading />
          </div>
        </div>

      </>
    );
  }

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">Envio Correo</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={formEmail}
            onSubmit={handleSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
            }) => (

              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-7 offset-lg-4">
                    {errors.atLeastOne && (
                      <div className="alert alert-danger" role="alert">
                        {errors.atLeastOne}
                      </div>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="asunto">
                      <label>Asunto </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        className="form-control"
                        name="asunto"
                        id="asunto"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.asunto}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.asunto}
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="grupos">
                      <label htmlFor="grupos">Grupos</label>
                    </div>
                    <div className="col-lg-7">
                      <CustomClearIndicator
                        id="grupos"
                        name='grupos'
                        options={grupoOptions}
                        onChange={(selectedOptions) => {
                          setFieldValue('grupos', selectedOptions.map(option => {
                            let { value, label } = option;
                            return { slug: value, nombre: label };
                          }));
                        }}
                        defaultsValues={values.grupos}
                        isMulti={true}
                      />
                    </div>
                  </div>

                  <MultiSelectSelectVivienda
                      id="citofonos"
                      name="citofonos"
                      required={false}
                      onChange={(selectedOptions) => {
                        setFieldValue('citofonos', selectedOptions.map(option => {
                          let { value, label } = option;
                          return {
                            slug: value,
                            label: label
                          }
                        }));
                      }}
                      defaultsValues={values.citofonos}
                      isMulti={true}
                      errors={errors.citofonos}
                    />

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="enviar_a_todos_residentes">
                      <label htmlFor="enviar_a_todos_residentes">Enviar a todos los residentes</label>
                    </div>
                    <div className="col-lg-7">
                      <Field
                        type="checkbox"
                        checked={values.enviar_a_todos_residentes || false}
                        className="form-check-input"
                        name="enviar_a_todos_residentes"
                        id="enviar_a_todos_residentes"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enviar_a_todos_residentes}
                      />
                      <ErrorMessage
                        name="enviar_a_todos_residentes"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="enviar_a_todos_propietarios">
                      <label htmlFor="enviar_a_todos_propietarios">Enviar a todos los propietarios</label>
                    </div>
                    <div className="col-lg-7">
                      <Field
                        type="checkbox"
                        checked={values.enviar_a_todos_propietarios || false}
                        className="form-check-input"
                        name="enviar_a_todos_propietarios"
                        id="enviar_a_todos_propietarios"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.enviar_a_todos_propietarios}
                      />
                      <div
                        id="val-enviar_a_todos_propietarios-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.enviar_a_todos_propietarios}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="programacion_envio">
                      <label htmlFor="programacion_envio">Programación de envío</label>
                    </div>
                    <div className="col-lg-7">
                      <DatePicker
                        id="progarmacion_envio"
                        name="progarmacion_envio"
                        selected={values.programacion_envio}
                        onChange={(date) => setFieldValue('programacion_envio', date)}
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="Hora"
                        className="form-control"
                        dateFormat="dd/MM/yyyy HH:mm"
                      />
                      <div
                        id="val-programacion_envio-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.programacion_envio}
                      </div>
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="email">
                      <label htmlFor="descripcion">Mensaje </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <Field
                      component={QuillEditor}
                      name="mensaje"
                      id="mensaje"
                      placeholder="Escribe algo..."
                    />
                    <div
                      id="val-mensaje-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.mensaje}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="adjuntos">
                      <label htmlFor="adjuntos">Adjuntar archivo</label>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="file"
                        className="form-control"
                        name="adjuntos"
                        multiple
                        accept=".pdf,.jpg,.png"
                        onChange={(event) => {
                          const files = event.target.files;
                          const selectedFiles = Array.from(files);
                          setFieldValue('adjuntos', selectedFiles);
                        }}
                      />
                      <small className="form-text text-muted">
                        <div>Solo se puede adjuntar 1 archivo.</div>
                        <div>Tamaño máximo de archivo: 5 MB</div>
                        <div>Formatos aceptados: .pdf, .jpg, .png</div>
                      </small>
                      <div
                        id="val-adjuntos-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.adjuntos}
                      </div>
                    </div>
                  </div>

                  {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="modal-footer">
                      <Link to='/envio_masivo_email' className="btn btn-citoplus-danger">
                        Cancelar
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-citoplus-success"
                        disabled={isSubmitting}>
                        Enviar
                      </button>
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  )
}

export default FormCrearEmail;
