import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getProfileAction,
  updateProfileAction,
} from "../../../../store/actions/PerfilActions";
import PageTitle from "../../../layouts/PageTitle";
import { Formik } from "formik";
import * as Yup from "yup";
import { ciudades_colombia } from "jsx/components/CitoPlus/data/ciudades";
import { captureConsoleIntegration } from "@sentry/react";

const editarPerfilSchema = Yup.object().shape({
  nombre_representante_legal: Yup.string().required("Campo obligatorio."),
  email_copropiedad: Yup.string().required("Campo obligatorio."),
  nombre_copropiedad: Yup.string().required("Campo obligatorio."),
  identificacion_copropiedad: Yup.string().required("Campo obligatorio."),
  ciudad: Yup.string().required("Campo obligatorio."),
  direccion: Yup.string().required("Campo obligatorio."),
  telefono_fijo: Yup.string().required("Campo obligatorio."),
  telefono_celular: Yup.string().required("Campo obligatorio."),
  nombre_alternativo: Yup.string().required("Campo obligatorio."),
  telefono_fijo_alternativo: Yup.string().required("Campo obligatorio."),
  telefono_celular_alternativo: Yup.string().required("Campo obligatorio."),
  email_alternativo: Yup.string().required("Campo obligatorio."),
  panico_uno: Yup.string().required("Campo obligatorio."),
  panico_dos: Yup.string().required("Campo obligatorio."),
  panico_tres: Yup.string().required("Campo obligatorio."),
  panico_cuatro: Yup.string().required("Campo obligatorio."),
  panico_cinco: Yup.string().required("Campo obligatorio."),
  logo: Yup.string().required("Campo obligatorio."),

});

const EditarPerfil = () => {
  const [logoChange, setLogoChange] = useState(false);

  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.profile.profile);

  useEffect(() => {
    dispatch(getProfileAction());
  }, [dispatch]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    if (!profileDetails) {
      return;
    }

    // Comparar los valores actuales con los valores iniciales
    const hasChanged = Object.keys(values).some(
      (key) => values[key] !== initialValues[key]
    );

    if (!hasChanged) {
      return;
    }

    var postDataJson = {
      user: {
        first_name: values.nombre_representante_legal,
        email: values.email_copropiedad,
      },
      unidad: values.nombre_copropiedad,
      nit_cc: values.identificacion_copropiedad,
      ciudad: values.ciudad,
      direccion: values.direccion,
      telefono_fijo: values.telefono_fijo,
      telefono_celular: values.telefono_celular,
      alt_nombre: values.nombre_alternativo,
      alt_fijo: values.telefono_fijo_alternativo,
      alt_celular: values.telefono_celular_alternativo,
      alt_email: values.email_alternativo,
      panico_uno: values.panico_uno,
      panico_dos: values.panico_dos,
      panico_tres: values.panico_tres,
      panico_cuatro: values.panico_cuatro,
      panico_cinco: values.panico_cinco,
    };

    if (logoChange) {
      setLogoChange(false);
      postDataJson.logo = values.logo;
    }

    try {
      await dispatch(updateProfileAction(postDataJson));
      postDataJson.logo = values.logo;
      initialValues = Object.assign(initialValues, postDataJson);
    } catch (error) {
      // Manejar errores si es necesario
      console.error("Error updating profile");
    }
  };

  const initialValues = {
    nombre_representante_legal: profileDetails?.user?.first_name || "",
    email_copropiedad: profileDetails?.user?.email || "",
    nombre_copropiedad: profileDetails?.unidad || "",
    identificacion_copropiedad: profileDetails?.nit_cc || "",
    ciudad: profileDetails?.ciudad || "",
    direccion: profileDetails?.direccion || "",
    telefono_fijo: profileDetails?.telefono_fijo || "",
    telefono_celular: profileDetails?.telefono_celular || "",
    nombre_alternativo: profileDetails?.alt_nombre || "",
    telefono_fijo_alternativo: profileDetails?.alt_fijo || "",
    telefono_celular_alternativo: profileDetails?.alt_celular || "",
    email_alternativo: profileDetails?.alt_email || "",
    panico_uno: profileDetails?.panico_uno || "",
    panico_dos: profileDetails?.panico_dos || "",
    panico_tres: profileDetails?.panico_tres || "",
    panico_cuatro: profileDetails?.panico_cuatro || "",
    panico_cinco: profileDetails?.panico_cinco || "",
    logo: profileDetails?.logo || null,
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Editar Perfil</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={editarPerfilSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    setFieldValue,
                    /* and other goodies */
                  }) => (
                    <form
                      className="form-valide"
                      encType="multipart/form-data"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <fieldset>
                          <legend className="w-auto">
                            Información principal
                          </legend>
                          <div className="form-group mb-3 row">
                            <div className="col-sm-12 col-md-12 mb-2">
                              <label
                                htmlFor="nombre_representante_legal"
                                class="form-label"
                              >
                                Nombre copropiedad
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="nombre_copropiedad"
                                name="nombre_copropiedad"
                                value={values.nombre_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.nombre_copropiedad}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-2">
                              <label
                                htmlFor="identificacion_copropiedad"
                                class="form-label"
                              >
                                NIT
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control bg-light cursor-not-allowed"
                                id="identificacion_copropiedad"
                                name="identificacion_copropiedad"
                                value={values.identificacion_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                disabled={true}
                              />
                              <small>El NIT no puede ser modificado</small>
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.identificacion_copropiedad}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-2">
                              <label
                                htmlFor="nombre_representante_legal"
                                class="form-label"
                              >
                                Nombre representante
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="nombre_representante_legal"
                                name="nombre_representante_legal"
                                value={values.nombre_representante_legal}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.nombre_representante_legal}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-2">
                              <label
                                htmlFor="email_copropiedad"
                                class="form-label"
                              >
                                Email copropiedad
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="email_copropiedad"
                                name="email_copropiedad"
                                value={values.email_copropiedad}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.email_copropiedad}
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-6 mb-2">
                              <label htmlFor="provincia" class="form-label">
                                Telefono celular
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="telefono_celular"
                                name="telefono_celular"
                                value={values.telefono_celular}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <div
                                id="val-username1-error"
                                className="invalid-feedback animated fadeInUp"
                                style={{ display: "block" }}
                              >
                                {errors.telefono_celular}
                              </div>
                            </div>
                            <div className="text-end">
                              <button
                                type="submit"
                                className={`btn me-2 btn-citoplus-primary`}
                                disabled={isSubmitting}
                              >
                                Guardar
                              </button>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend className="w-auto">
                            Información adicional
                          </legend>
                          <div className="col-xl-6">
                            <div className="form-group mb-3 row">
                              <div className="col-sm-12 col-md-12 mb-2">
                                <label htmlFor="ciudad" class="form-label">
                                  Ciudad
                                  <span className="text-danger">*</span>
                                </label>
                                <select
                                  className="form-control"
                                  id="ciudad"
                                  name="ciudad"
                                  value={values.ciudad || ""}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                >
                                  <option value="">
                                    Selecciona una ciudad
                                  </option>
                                  {ciudades_colombia.map((ciudad) => (
                                    <option
                                      key={ciudad.value}
                                      value={ciudad.value}
                                    >
                                      {ciudad.label}
                                    </option>
                                  ))}
                                </select>
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.ciudad}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Direccion
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="direccion"
                                  name="direccion"
                                  value={values.direccion}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.direccion}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Telefono fijo
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_fijo"
                                  name="telefono_fijo"
                                  value={values.telefono_fijo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_fijo}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <div className="col-xl-6">
                            <legend className="w-auto">
                              Contacto presidente de consejo
                            </legend>
                            <div className="form-group mb-3 row">
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Nombre alternativo
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="nombre_alternativo"
                                  name="nombre_alternativo"
                                  value={values.nombre_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.nombre_alternativo}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Telefono fijo Alternativo
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_fijo_alternativo"
                                  name="telefono_fijo_alternativo"
                                  value={values.telefono_fijo_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_fijo_alternativo}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Telefono celular alternativo
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="telefono_celular_alternativo"
                                  name="telefono_celular_alternativo"
                                  value={values.telefono_celular_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.telefono_celular_alternativo}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Email alternativo
                                  <span className="text-danger">*</span>
                                </label>

                                <input
                                  type="text"
                                  className="form-control"
                                  id="email_alternativo"
                                  name="email_alternativo"
                                  value={values.email_alternativo}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.email_alternativo}
                                </div>
                              </div>

                              <div className="col-sm-12 col-md-12 mb-2">
                                <label htmlFor="provincia" class="form-label">
                                  Logo
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="file"
                                  className="form-file-input form-control"
                                  id="logo"
                                  accept="image/*"
                                  name="logo"
                                  onChange={(e) => {
                                    const fileReader = new FileReader();
                                    fileReader.onload = (e) => {
                                      // Directamente establecer el valor del archivo
                                      setFieldValue("logo", fileReader.result);
                                    };
                                    setLogoChange(true);
                                    // setFieldValue('logo', e.currentTarget.files[0]);
                                    fileReader.readAsDataURL(
                                      e.currentTarget.files[0]
                                    );
                                  }}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.logo}
                                </div>
                                <div className="col-lg-12 p-2">
                                  {values.logo && (
                                    <div className="col-sm-12 col-md-1 mx-auto">
                                      <a
                                        href={values.logo}
                                        data-lightbox="image"
                                        className="col-lg-6 mx-auto"
                                        style={{ paddingTop: "10px" }}
                                      >
                                        <img
                                          src={values.logo}
                                          alt=""
                                          className="me-3 rounded"
                                          width={85}
                                        />
                                      </a>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset>
                          <legend className="w-auto">
                            Números de emergencia (botón de pánico)
                          </legend>
                          <div className="col-xl-6">
                            <div className="form-group mb-3 row">
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="numero_panico_uno" class="form-label">
                                  Primer número de celular
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="panico_uno"
                                  name="panico_uno"
                                  value={values.panico_uno}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.panico_uno}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="numero_panico_dos" class="form-label">
                                  Segundo número de celular
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="panico_dos"
                                  name="panico_dos"
                                  value={values.panico_dos}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.panico_dos}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="numero_panico_tres" class="form-label">
                                  Tercer número de celular
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="panico_tres"
                                  name="panico_tres"
                                  value={values.panico_tres}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.panico_tres}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="numero_panico_cuatro" class="form-label">
                                  Cuarto número de celular
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="panico_cuatro"
                                  name="panico_cuatro"
                                  value={values.panico_cuatro}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.panico_cuatro}
                                </div>
                              </div>
                              <div className="col-sm-12 col-md-6 mb-2">
                                <label htmlFor="numero_panico_cinco" class="form-label">
                                  Quinto número de celular
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="panico_cinco"
                                  name="panico_cinco"
                                  value={values.panico_cinco}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                <div
                                  id="val-username1-error"
                                  className="invalid-feedback animated fadeInUp"
                                  style={{ display: "block" }}
                                >
                                  {errors.panico_cinco}
                                </div>
                              </div>
                            </div>
                          </div>
                        </fieldset>
                        <fieldset className="d-none">
                          <div className="col-xl-6">
                            <legend className="w-auto">
                              Información de Redes Sociales
                            </legend>
                            <div className="form-group mb-3 row">
                              <div
                                className="col-lg-4 col-form-label"
                                htmlFor="provincia"
                              >
                                Facebook
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_facebook"
                                  name="social_facebook"
                                  value={values.social_facebook}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div
                                className="col-lg-4 col-form-label"
                                htmlFor="provincia"
                              >
                                Youtube
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_youtube"
                                  name="social_youtube"
                                  value={values.social_youtube}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div
                                className="col-lg-4 col-form-label"
                                htmlFor="provincia"
                              >
                                Twitter
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_twitter"
                                  name="social_twitter"
                                  value={values.social_twitter}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                            <div className="form-group mb-3 row">
                              <div
                                className="col-lg-4 col-form-label"
                                htmlFor="provincia"
                              >
                                Instagram
                              </div>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="social_instagram"
                                  name="social_instagram"
                                  value={values.social_instagram}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                        </fieldset>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default EditarPerfil;
