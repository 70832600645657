import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';


const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);
  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

const AudioPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const muted = false;
  const volume = 0.8;

  const downloadAudio = async () => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const fileName = url.split('/').pop().split('?')[0] || 'citoplus_grabacion.mp3';
      const finalFileName = fileName.endsWith('.mp3') ? fileName : `${fileName}.mp3`;
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = finalFileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading the audio file', error);
    }
  };


  return (
    <div className="player-wrapper">
      <div className="">
        <ReactPlayer
          url={url}
          playing={playing}
          muted={muted}
          volume={volume}
          onPlay={() => setPlaying(true)}
          onPause={() => setPlaying(false)}
          onDuration={(d) => setDuration(d)}
          onProgress={({ playedSeconds }) => setCurrentTime(playedSeconds)}
          width="0%"
          height="0px"
          controls={false}
        />
        <div className="controls mt-3">
          {playing ? (
            <button className="btn btn-sm btn-citoplus-info btn-sm" onClick={() => setPlaying(!playing)}>
              <i className='fas fa-pause'></i>
            </button>
          ) : (
            <button className="btn btn-sm btn-citoplus-primary btn-sm" onClick={() => setPlaying(!playing)}>
              <i className="fas fa-play"></i>
            </button>
          )}
          <button className="btn btn-citoplus-secondary btn-sm ml-2" onClick={downloadAudio}>
            <i className='fas fa-download'></i>
          </button>
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
