import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../services/AxiosInstance';
import { handleErrors } from '../CitoPlus/components/ErrorHandlingComponent';
import { Modal, Button } from 'react-bootstrap';

//Image
import profile from './../../../images/profile1.jpg';
import pic1 from './../../../images/profile/pic1.jpg';

const MyProfile = () => {

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get('/rest-auth/user/');
        setData(response.data);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  return (
    <>
      <div className="profile-back">
        <img src={profile} alt="" />
        <div className="social-btn">
          <Link to={"/pagar-servicio/"} className="btn btn-primary social">
            Pago de Servicio
          </Link>
          <Link to={"#"} onClick={handleModalShow} className="btn btn-primary social">
            Fecha de vencimiento: {data.perfil_extra?.fecha_vencimiento}
          </Link>
          <Link to={"/editar_perfil"} className="btn btn-primary">Editar Mis Datos</Link>
        </div>
      </div>
      <div className="profile-pic d-flex">
        <img src={data.perfil_extra?.logo} alt="" />
        <div className="profile-info2">
          <h2 className="mb-0">{data.first_name} {data.last_name}</h2>
          <h4>{data.email}</h4>
          <span className="d-block">
            <i className="fas fa-map-marker-alt me-2"></i>
            {data.perfil_extra?.pais} - {data.perfil_extra?.ciudad}
          </span>
        </div>
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title>Fecha de Vencimiento</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          La fecha de vencimiento es: {data.perfil_extra?.fecha_vencimiento}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default MyProfile;
