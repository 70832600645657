export const ciudades_colombia = [
  { value: "BOGOTA", label: "BOGOTA"},
  { value: "EL ENCANTO", label: "EL ENCANTO"},
  { value: "LA CHORRERA", label: "LA CHORRERA"},
  { value: "LA PEDRERA", label: "LA PEDRERA"},
  { value: "LA VICTORIA", label: "LA VICTORIA"},
  { value: "MIRITI-PARANA", label: "MIRITI-PARANA"},
  { value: "PUERTO ALEGRIA", label: "PUERTO ALEGRIA"},
  { value: "PUERTO ARICA", label: "PUERTO ARICA"},
  { value: "PUERTO NARINO", label: "PUERTO NARINO"},
  { value: "SANTANDER", label: "SANTANDER"},
  { value: "TARAPACA", label: "TARAPACA"},
  { value: "ABEJORRAL", label: "ABEJORRAL"},
  { value: "ABRIAQUI", label: "ABRIAQUI"},
  { value: "ALEJANDRIA", label: "ALEJANDRIA"},
  { value: "AMAGA", label: "AMAGA"},
  { value: "AMALFI", label: "AMALFI"},
  { value: "ANDES", label: "ANDES"},
  { value: "ANGELOPOLIS", label: "ANGELOPOLIS"},
  { value: "ANGOSTURA", label: "ANGOSTURA"},
  { value: "ANORI", label: "ANORI"},
  { value: "ANZA", label: "ANZA"},
  { value: "APARTADO", label: "APARTADO"},
  { value: "ARBOLETES", label: "ARBOLETES"},
  { value: "ARGELIA", label: "ARGELIA"},
  { value: "ARMENIA", label: "ARMENIA"},
  { value: "BARBOSA", label: "BARBOSA"},
  { value: "BELLO", label: "BELLO"},
  { value: "BELMIRA", label: "BELMIRA"},
  { value: "BETANIA", label: "BETANIA"},
  { value: "BETULIA", label: "BETULIA"},
  { value: "BRICENO", label: "BRICENO"},
  { value: "BURITICA", label: "BURITICA"},
  { value: "CACERES", label: "CACERES"},
  { value: "CAICEDO", label: "CAICEDO"},
  { value: "CALDAS", label: "CALDAS"},
  { value: "CAMPAMENTO", label: "CAMPAMENTO"},
  { value: "CANASGORDAS", label: "CANASGORDAS"},
  { value: "CARACOLI", label: "CARACOLI"},
  { value: "CARAMANTA", label: "CARAMANTA"},
  { value: "CAREPA", label: "CAREPA"},
  { value: "CARMEN DE VIBORAL", label: "CARMEN DE VIBORAL"},
  { value: "CAROLINA", label: "CAROLINA"},
  { value: "CAUCASIA", label: "CAUCASIA"},
  { value: "CHIGORODO", label: "CHIGORODO"},
  { value: "CISNEROS", label: "CISNEROS"},
  { value: "CIUDAD BOLIVAR", label: "CIUDAD BOLIVAR"},
  { value: "COCORNA", label: "COCORNA"},
  { value: "CONCEPCION", label: "CONCEPCION"},
  { value: "CONCORDIA", label: "CONCORDIA"},
  { value: "COPACABANA", label: "COPACABANA"},
  { value: "DABEIBA", label: "DABEIBA"},
  { value: "DON MATIAS", label: "DON MATIAS"},
  { value: "EBEJICO", label: "EBEJICO"},
  { value: "EL BAGRE", label: "EL BAGRE"},
  { value: "ENTRERRIOS", label: "ENTRERRIOS"},
  { value: "ENVIGADO", label: "ENVIGADO"},
  { value: "FREDONIA", label: "FREDONIA"},
  { value: "FRONTINO", label: "FRONTINO"},
  { value: "GIRALDO", label: "GIRALDO"},
  { value: "GIRARDOTA", label: "GIRARDOTA"},
  { value: "GOMEZ PLATA", label: "GOMEZ PLATA"},
  { value: "GRANADA", label: "GRANADA"},
  { value: "GUADALUPE", label: "GUADALUPE"},
  { value: "GUARNE", label: "GUARNE"},
  { value: "GUATAPE", label: "GUATAPE"},
  { value: "HELICONIA", label: "HELICONIA"},
  { value: "HISPANIA", label: "HISPANIA"},
  { value: "ITAG&UUML;I", label: "ITAG&UUML;I"},
  { value: "ITUANGO", label: "ITUANGO"},
  { value: "JARDIN", label: "JARDIN"},
  { value: "JERICO", label: "JERICO"},
  { value: "LA CEJA", label: "LA CEJA"},
  { value: "LA ESTRELLA", label: "LA ESTRELLA"},
  { value: "LA PINTADA", label: "LA PINTADA"},
  { value: "LA UNION", label: "LA UNION"},
  { value: "LIBORINA", label: "LIBORINA"},
  { value: "MACEO", label: "MACEO"},
  { value: "MARINILLA", label: "MARINILLA"},
  { value: "MEDELLIN", label: "MEDELLIN"},
  { value: "MONTEBELLO", label: "MONTEBELLO"},
  { value: "MURINDO", label: "MURINDO"},
  { value: "MUTATA", label: "MUTATA"},
  { value: "NARINO", label: "NARINO"},
  { value: "NECHI", label: "NECHI"},
  { value: "NECOCLI", label: "NECOCLI"},
  { value: "OLAYA", label: "OLAYA"},
  { value: "PENOL", label: "PENOL"},
  { value: "PEQUE", label: "PEQUE"},
  { value: "PUEBLORRICO", label: "PUEBLORRICO"},
  { value: "PUERTO BERRIO", label: "PUERTO BERRIO"},
  { value: "PUERTO NARE", label: "PUERTO NARE"},
  { value: "PUERTO TRIUNFO", label: "PUERTO TRIUNFO"},
  { value: "REMEDIOS", label: "REMEDIOS"},
  { value: "RETIRO", label: "RETIRO"},
  { value: "RIONEGRO", label: "RIONEGRO"},
  { value: "SABANALARGA", label: "SABANALARGA"},
  { value: "SABANETA", label: "SABANETA"},
  { value: "SALGAR", label: "SALGAR"},
  { value: "SAN ANDRES", label: "SAN ANDRES"},
  { value: "SAN CARLOS", label: "SAN CARLOS"},
  { value: "SAN FRANCISCO", label: "SAN FRANCISCO"},
  { value: "SAN JERONIMO", label: "SAN JERONIMO"},
  { value: "SAN JOSE DE LA MONTANA", label: "SAN JOSE DE LA MONTANA"},
  { value: "SAN JUAN DE URABA", label: "SAN JUAN DE URABA"},
  { value: "SAN LUIS", label: "SAN LUIS"},
  { value: "SAN PEDRO", label: "SAN PEDRO"},
  { value: "SAN PEDRO DE URABA", label: "SAN PEDRO DE URABA"},
  { value: "SAN RAFAEL", label: "SAN RAFAEL"},
  { value: "SAN ROQUE", label: "SAN ROQUE"},
  { value: "SAN VICENTE", label: "SAN VICENTE"},
  { value: "SANTA BARBARA", label: "SANTA BARBARA"},
  { value: "SANTA FE DE ANTIOQUIA", label: "SANTA FE DE ANTIOQUIA"},
  { value: "SANTA ROSA DE OSOS", label: "SANTA ROSA DE OSOS"},
  { value: "SANTO DOMINGO", label: "SANTO DOMINGO"},
  { value: "SANTUARIO", label: "SANTUARIO"},
  { value: "SEGOVIA", label: "SEGOVIA"},
  { value: "SONSON", label: "SONSON"},
  { value: "SOPETRAN", label: "SOPETRAN"},
  { value: "TAMESIS", label: "TAMESIS"},
  { value: "TARAZA", label: "TARAZA"},
  { value: "TARSO", label: "TARSO"},
  { value: "TITIRIBI", label: "TITIRIBI"},
  { value: "TOLEDO", label: "TOLEDO"},
  { value: "TURBO", label: "TURBO"},
  { value: "URAMITA", label: "URAMITA"},
  { value: "URRAO", label: "URRAO"},
  { value: "VALDIVIA", label: "VALDIVIA"},
  { value: "VALPARAISO", label: "VALPARAISO"},
  { value: "VEGACHI", label: "VEGACHI"},
  { value: "VENECIA", label: "VENECIA"},
  { value: "VIGIA DEL FUERTE", label: "VIGIA DEL FUERTE"},
  { value: "YALI", label: "YALI"},
  { value: "YARUMAL", label: "YARUMAL"},
  { value: "YOLOMBO", label: "YOLOMBO"},
  { value: "YONDO", label: "YONDO"},
  { value: "ZARAGOZA", label: "ZARAGOZA"},
  { value: "ARAUCA", label: "ARAUCA"},
  { value: "ARAUQUITA", label: "ARAUQUITA"},
  { value: "CRAVO NORTE", label: "CRAVO NORTE"},
  { value: "FORTUL", label: "FORTUL"},
  { value: "PUERTO RONDON", label: "PUERTO RONDON"},
  { value: "SARAVENA", label: "SARAVENA"},
  { value: "TAME", label: "TAME"},
  { value: "BARANOA", label: "BARANOA"},
  { value: "BARRANQUILLA", label: "BARRANQUILLA"},
  { value: "CAMPO DE LA CRUZ", label: "CAMPO DE LA CRUZ"},
  { value: "CANDELARIA", label: "CANDELARIA"},
  { value: "GALAPA", label: "GALAPA"},
  { value: "JUAN DE ACOSTA", label: "JUAN DE ACOSTA"},
  { value: "LURUACO", label: "LURUACO"},
  { value: "MALAMBO", label: "MALAMBO"},
  { value: "MANATI", label: "MANATI"},
  { value: "PALMAR DE VARELA", label: "PALMAR DE VARELA"},
  { value: "PIOJO", label: "PIOJO"},
  { value: "POLONUEVO", label: "POLONUEVO"},
  { value: "PONEDERA", label: "PONEDERA"},
  { value: "PUERTO COLOMBIA", label: "PUERTO COLOMBIA"},
  { value: "REPELON", label: "REPELON"},
  { value: "SABANAGRANDE", label: "SABANAGRANDE"},
  { value: "SABANALARGA", label: "SABANALARGA"},
  { value: "SANTA LUCIA", label: "SANTA LUCIA"},
  { value: "SANTO TOMAS", label: "SANTO TOMAS"},
  { value: "SOLEDAD", label: "SOLEDAD"},
  { value: "SUAN", label: "SUAN"},
  { value: "TUBARA", label: "TUBARA"},
  { value: "USIACURI", label: "USIACURI"},
  { value: "ACHI", label: "ACHI"},
  { value: "ALTOS DEL ROSARIO", label: "ALTOS DEL ROSARIO"},
  { value: "ARENAL", label: "ARENAL"},
  { value: "ARJONA", label: "ARJONA"},
  { value: "ARROYOHONDO", label: "ARROYOHONDO"},
  { value: "BARRANCO DE LOBA", label: "BARRANCO DE LOBA"},
  { value: "CALAMAR", label: "CALAMAR"},
  { value: "CANTAGALLO", label: "CANTAGALLO"},
  { value: "CARTAGENA DE INDIAS", label: "CARTAGENA DE INDIAS"},
  { value: "CICUCO", label: "CICUCO"},
  { value: "CLEMENCIA", label: "CLEMENCIA"},
  { value: "CORDOBA", label: "CORDOBA"},
  { value: "EL CARMEN DE BOLIVAR", label: "EL CARMEN DE BOLIVAR"},
  { value: "EL GUAMO", label: "EL GUAMO"},
  { value: "EL PENON", label: "EL PENON"},
  { value: "HATILLO DE LOBA", label: "HATILLO DE LOBA"},
  { value: "MAGANGUE", label: "MAGANGUE"},
  { value: "MAHATES", label: "MAHATES"},
  { value: "MARGARITA", label: "MARGARITA"},
  { value: "MARIA LA BAJA", label: "MARIA LA BAJA"},
  { value: "MOMPOS", label: "MOMPOS"},
  { value: "MONTECRISTO", label: "MONTECRISTO"},
  { value: "MORALES", label: "MORALES"},
  { value: "PINILLOS", label: "PINILLOS"},
  { value: "REGIDOR", label: "REGIDOR"},
  { value: "RIOVIEJO", label: "RIOVIEJO"},
  { value: "SAN CRISTOBAL", label: "SAN CRISTOBAL"},
  { value: "SAN ESTANISLAO", label: "SAN ESTANISLAO"},
  { value: "SAN FERNANDO", label: "SAN FERNANDO"},
  { value: "SAN JACINTO", label: "SAN JACINTO"},
  { value: "SAN JACINTO DEL CAUCA", label: "SAN JACINTO DEL CAUCA"},
  { value: "SAN JUAN NEPOMUCENO", label: "SAN JUAN NEPOMUCENO"},
  { value: "SAN MARTIN DE LOBA", label: "SAN MARTIN DE LOBA"},
  { value: "SAN PABLO", label: "SAN PABLO"},
  { value: "SANTA CATALINA", label: "SANTA CATALINA"},
  { value: "SANTA ROSA", label: "SANTA ROSA"},
  { value: "SANTA ROSA DEL SUR", label: "SANTA ROSA DEL SUR"},
  { value: "SIMITI", label: "SIMITI"},
  { value: "SOPLAVIENTO", label: "SOPLAVIENTO"},
  { value: "TALAIGUA NUEVO", label: "TALAIGUA NUEVO"},
  { value: "TIQUISIO", label: "TIQUISIO"},
  { value: "TURBACO", label: "TURBACO"},
  { value: "TURBANA", label: "TURBANA"},
  { value: "VILLANUEVA", label: "VILLANUEVA"},
  { value: "ZAMBRANO", label: "ZAMBRANO"},
  { value: "ALMEIDA", label: "ALMEIDA"},
  { value: "AQUITANIA", label: "AQUITANIA"},
  { value: "ARCABUCO", label: "ARCABUCO"},
  { value: "BELEN", label: "BELEN"},
  { value: "BERBEO", label: "BERBEO"},
  { value: "BETEITIVA", label: "BETEITIVA"},
  { value: "BOAVITA", label: "BOAVITA"},
  { value: "BOYACA", label: "BOYACA"},
  { value: "BRICENO", label: "BRICENO"},
  { value: "BUENAVISTA", label: "BUENAVISTA"},
  { value: "BUSBANZA", label: "BUSBANZA"},
  { value: "CALDAS", label: "CALDAS"},
  { value: "CAMPOHERMOSO", label: "CAMPOHERMOSO"},
  { value: "CERINZA", label: "CERINZA"},
  { value: "CHINAVITA", label: "CHINAVITA"},
  { value: "CHIQUINQUIRA", label: "CHIQUINQUIRA"},
  { value: "CHIQUIZA", label: "CHIQUIZA"},
  { value: "CHISCAS", label: "CHISCAS"},
  { value: "CHITA", label: "CHITA"},
  { value: "CHITARAQUE", label: "CHITARAQUE"},
  { value: "CHIVATA", label: "CHIVATA"},
  { value: "CHIVOR", label: "CHIVOR"},
  { value: "CIENEGA", label: "CIENEGA"},
  { value: "COMBITA", label: "COMBITA"},
  { value: "COPER", label: "COPER"},
  { value: "CORRALES", label: "CORRALES"},
  { value: "COVARACHIA", label: "COVARACHIA"},
  { value: "CUBARA", label: "CUBARA"},
  { value: "CUCAITA", label: "CUCAITA"},
  { value: "CUITIVA", label: "CUITIVA"},
  { value: "DUITAMA", label: "DUITAMA"},
  { value: "EL COCUY", label: "EL COCUY"},
  { value: "EL ESPINO", label: "EL ESPINO"},
  { value: "FIRAVITOBA", label: "FIRAVITOBA"},
  { value: "FLORESTA", label: "FLORESTA"},
  { value: "GACHANTIVA", label: "GACHANTIVA"},
  { value: "GAMEZA", label: "GAMEZA"},
  { value: "GARAGOA", label: "GARAGOA"},
  { value: "GUACAMAYAS", label: "GUACAMAYAS"},
  { value: "GUATEQUE", label: "GUATEQUE"},
  { value: "GUAYATA", label: "GUAYATA"},
  { value: "GUICAN", label: "GUICAN"},
  { value: "IZA", label: "IZA"},
  { value: "JENESANO", label: "JENESANO"},
  { value: "JERICO", label: "JERICO"},
  { value: "LA CAPILLA", label: "LA CAPILLA"},
  { value: "LA UVITA", label: "LA UVITA"},
  { value: "LA VICTORIA", label: "LA VICTORIA"},
  { value: "LABRANZAGRANDE", label: "LABRANZAGRANDE"},
  { value: "MACANAL", label: "MACANAL"},
  { value: "MARIPI", label: "MARIPI"},
  { value: "MIRAFLORES", label: "MIRAFLORES"},
  { value: "MONGUA", label: "MONGUA"},
  { value: "MONGUI", label: "MONGUI"},
  { value: "MONIQUIRA", label: "MONIQUIRA"},
  { value: "MOTAVITA", label: "MOTAVITA"},
  { value: "MUZO", label: "MUZO"},
  { value: "NOBSA", label: "NOBSA"},
  { value: "NUEVO COLON", label: "NUEVO COLON"},
  { value: "OICATA", label: "OICATA"},
  { value: "OTANCHE", label: "OTANCHE"},
  { value: "PACHAVITA", label: "PACHAVITA"},
  { value: "PAEZ", label: "PAEZ"},
  { value: "PAIPA", label: "PAIPA"},
  { value: "PAJARITO", label: "PAJARITO"},
  { value: "PANQUEBA", label: "PANQUEBA"},
  { value: "PAUNA", label: "PAUNA"},
  { value: "PAYA", label: "PAYA"},
  { value: "PAZ DE RIO", label: "PAZ DE RIO"},
  { value: "PESCA", label: "PESCA"},
  { value: "PISVA", label: "PISVA"},
  { value: "PUERTO BOYACA", label: "PUERTO BOYACA"},
  { value: "QUIPAMA", label: "QUIPAMA"},
  { value: "RAMIRIQUI", label: "RAMIRIQUI"},
  { value: "RAQUIRA", label: "RAQUIRA"},
  { value: "RONDON", label: "RONDON"},
  { value: "SABOYA", label: "SABOYA"},
  { value: "SACHICA", label: "SACHICA"},
  { value: "SAMACA", label: "SAMACA"},
  { value: "SAN EDUARDO", label: "SAN EDUARDO"},
  { value: "SAN JOSEDE PARE", label: "SAN JOSEDE PARE"},
  { value: "SAN LUIS DE GACENO", label: "SAN LUIS DE GACENO"},
  { value: "SAN MATEO", label: "SAN MATEO"},
  { value: "SAN MIGUEL DE SEMA", label: "SAN MIGUEL DE SEMA"},
  { value: "SAN PABLO DE BORBUR", label: "SAN PABLO DE BORBUR"},
  { value: "SANTA MARIA", label: "SANTA MARIA"},
  { value: "SANTA ROSA DE VITERBO", label: "SANTA ROSA DE VITERBO"},
  { value: "SANTA SOFIA", label: "SANTA SOFIA"},
  { value: "SANTANA", label: "SANTANA"},
  { value: "SATIVANORTE", label: "SATIVANORTE"},
  { value: "SATIVASUR", label: "SATIVASUR"},
  { value: "SIACHOQUE", label: "SIACHOQUE"},
  { value: "SOATA", label: "SOATA"},
  { value: "SOCHA", label: "SOCHA"},
  { value: "SOCOTA", label: "SOCOTA"},
  { value: "SOGAMOSO", label: "SOGAMOSO"},
  { value: "SOMONDOCO", label: "SOMONDOCO"},
  { value: "SORA", label: "SORA"},
  { value: "SORACA", label: "SORACA"},
  { value: "SOTAQUIRA", label: "SOTAQUIRA"},
  { value: "SUSACON", label: "SUSACON"},
  { value: "SUTAMARCHAN", label: "SUTAMARCHAN"},
  { value: "SUTATENZA", label: "SUTATENZA"},
  { value: "TASCO", label: "TASCO"},
  { value: "TENZA", label: "TENZA"},
  { value: "TIBANA", label: "TIBANA"},
  { value: "TIBASOSA", label: "TIBASOSA"},
  { value: "TINJACA", label: "TINJACA"},
  { value: "TIPACOQUE", label: "TIPACOQUE"},
  { value: "TOCA", label: "TOCA"},
  { value: "TOG&UUML;I", label: "TOG&UUML;I"},
  { value: "TOPAGA", label: "TOPAGA"},
  { value: "TOTA", label: "TOTA"},
  { value: "TUNJA", label: "TUNJA"},
  { value: "TUNUNGUA", label: "TUNUNGUA"},
  { value: "TURMEQUE", label: "TURMEQUE"},
  { value: "TUTA", label: "TUTA"},
  { value: "TUTAZA", label: "TUTAZA"},
  { value: "UMBITA", label: "UMBITA"},
  { value: "VENTAQUEMADA", label: "VENTAQUEMADA"},
  { value: "VILLA DE LEIVA", label: "VILLA DE LEIVA"},
  { value: "VIRACACHA", label: "VIRACACHA"},
  { value: "ZETAQUIRA", label: "ZETAQUIRA"},
  { value: "AGUADAS", label: "AGUADAS"},
  { value: "ANSERMA", label: "ANSERMA"},
  { value: "ARANZAZU", label: "ARANZAZU"},
  { value: "BELALCAZAR", label: "BELALCAZAR"},
  { value: "CHINCHINA", label: "CHINCHINA"},
  { value: "FILADELFIA", label: "FILADELFIA"},
  { value: "LA DORADA", label: "LA DORADA"},
  { value: "LA MERCED", label: "LA MERCED"},
  { value: "MANIZALES", label: "MANIZALES"},
  { value: "MANZANARES", label: "MANZANARES"},
  { value: "MARMATO", label: "MARMATO"},
  { value: "MARQUETALIA", label: "MARQUETALIA"},
  { value: "MARULANDA", label: "MARULANDA"},
  { value: "NEIRA", label: "NEIRA"},
  { value: "NORCASIA", label: "NORCASIA"},
  { value: "PACORA", label: "PACORA"},
  { value: "PALESTINA", label: "PALESTINA"},
  { value: "PENSILVANIA", label: "PENSILVANIA"},
  { value: "RIOSUCIO", label: "RIOSUCIO"},
  { value: "RISARALDA", label: "RISARALDA"},
  { value: "SALAMINA", label: "SALAMINA"},
  { value: "SAMANA", label: "SAMANA"},
  { value: "SAN JOSE", label: "SAN JOSE"},
  { value: "SUPIA", label: "SUPIA"},
  { value: "VICTORIA", label: "VICTORIA"},
  { value: "VILLAMARIA", label: "VILLAMARIA"},
  { value: "VITERBO", label: "VITERBO"},
  { value: "ALBANIA", label: "ALBANIA"},
  { value: "BELEN DE LOS ANDAQUIES", label: "BELEN DE LOS ANDAQUIES"},
  { value: "CARTAGENA DEL CHAIRA", label: "CARTAGENA DEL CHAIRA"},
  { value: "CURILLO", label: "CURILLO"},
  { value: "EL DONCELLO", label: "EL DONCELLO"},
  { value: "EL PAUJIL", label: "EL PAUJIL"},
  { value: "FLORENCIA", label: "FLORENCIA"},
  { value: "MILAN", label: "MILAN"},
  { value: "MONTANITA", label: "MONTANITA"},
  { value: "MORELIA", label: "MORELIA"},
  { value: "PUERTO RICO", label: "PUERTO RICO"},
  { value: "SAN JOSE DEL FRAGUA", label: "SAN JOSE DEL FRAGUA"},
  { value: "SAN VICENTE DEL CAGUAN", label: "SAN VICENTE DEL CAGUAN"},
  { value: "SOLANO", label: "SOLANO"},
  { value: "SOLITA", label: "SOLITA"},
  { value: "VALPARAISO", label: "VALPARAISO"},
  { value: "AGUAZUL", label: "AGUAZUL"},
  { value: "CHAMEZA", label: "CHAMEZA"},
  { value: "HATO COROZAL", label: "HATO COROZAL"},
  { value: "LA SALINA", label: "LA SALINA"},
  { value: "MANI", label: "MANI"},
  { value: "MONTERREY", label: "MONTERREY"},
  { value: "NUNCHIA", label: "NUNCHIA"},
  { value: "OROCUE", label: "OROCUE"},
  { value: "PAZ DE ARIPORO", label: "PAZ DE ARIPORO"},
  { value: "PORE", label: "PORE"},
  { value: "RECETOR", label: "RECETOR"},
  { value: "SABANALARGA", label: "SABANALARGA"},
  { value: "SACAMA", label: "SACAMA"},
  { value: "SAN LUIS DE PALENQUE", label: "SAN LUIS DE PALENQUE"},
  { value: "TAMARA", label: "TAMARA"},
  { value: "TAURAMENA", label: "TAURAMENA"},
  { value: "TRINIDAD", label: "TRINIDAD"},
  { value: "VILLANUEVA", label: "VILLANUEVA"},
  { value: "YOPAL", label: "YOPAL"},
  { value: "ALMAGUER", label: "ALMAGUER"},
  { value: "ARGELIA", label: "ARGELIA"},
  { value: "BALBOA", label: "BALBOA"},
  { value: "BOLIVAR", label: "BOLIVAR"},
  { value: "BUENOS AIRES", label: "BUENOS AIRES"},
  { value: "CAJIBIO", label: "CAJIBIO"},
  { value: "CALDONO", label: "CALDONO"},
  { value: "CALOTO", label: "CALOTO"},
  { value: "CORINTO", label: "CORINTO"},
  { value: "EL TAMBO", label: "EL TAMBO"},
  { value: "FLORENCIA", label: "FLORENCIA"},
  { value: "GUAPI", label: "GUAPI"},
  { value: "INZA", label: "INZA"},
  { value: "JAMBALO", label: "JAMBALO"},
  { value: "LA SIERRA", label: "LA SIERRA"},
  { value: "LA VEGA", label: "LA VEGA"},
  { value: "LOPEZ", label: "LOPEZ"},
  { value: "MERCADERES", label: "MERCADERES"},
  { value: "MIRANDA", label: "MIRANDA"},
  { value: "MORALES", label: "MORALES"},
  { value: "PADILLA", label: "PADILLA"},
  { value: "PAEZ", label: "PAEZ"},
  { value: "PATIA", label: "PATIA"},
  { value: "PIAMONTE", label: "PIAMONTE"},
  { value: "PIENDAMO", label: "PIENDAMO"},
  { value: "POPAYAN", label: "POPAYAN"},
  { value: "PUERTO TEJADA", label: "PUERTO TEJADA"},
  { value: "PURACE", label: "PURACE"},
  { value: "ROSAS", label: "ROSAS"},
  { value: "SAN SEBASTIAN", label: "SAN SEBASTIAN"},
  { value: "SANTA ROSA", label: "SANTA ROSA"},
  { value: "SANTANDER DE QUILICHAO", label: "SANTANDER DE QUILICHAO"},
  { value: "SILVIA", label: "SILVIA"},
  { value: "SOTARA", label: "SOTARA"},
  { value: "SUAREZ", label: "SUAREZ"},
  { value: "SUCRE", label: "SUCRE"},
  { value: "TIMBIO", label: "TIMBIO"},
  { value: "TIMBIQUI", label: "TIMBIQUI"},
  { value: "TORIBIO", label: "TORIBIO"},
  { value: "TOTORO", label: "TOTORO"},
  { value: "VILLA RICA", label: "VILLA RICA"},
  { value: "AGUACHICA", label: "AGUACHICA"},
  { value: "AGUSTIN CODAZZI", label: "AGUSTIN CODAZZI"},
  { value: "ASTREA", label: "ASTREA"},
  { value: "BECERRIL", label: "BECERRIL"},
  { value: "BOSCONIA", label: "BOSCONIA"},
  { value: "CHIMI HAGUA", label: "CHIMI HAGUA"},
  { value: "CHIRIGUANA", label: "CHIRIGUANA"},
  { value: "CURUMANI", label: "CURUMANI"},
  { value: "EL COPEY", label: "EL COPEY"},
  { value: "EL PASO", label: "EL PASO"},
  { value: "GAMARRA", label: "GAMARRA"},
  { value: "GONZALEZ", label: "GONZALEZ"},
  { value: "LA GLORIA", label: "LA GLORIA"},
  { value: "LA JAGUA DE IBIRICO", label: "LA JAGUA DE IBIRICO"},
  { value: "LA PAZ", label: "LA PAZ"},
  { value: "MANAURE BALCON DEL CESAR", label: "MANAURE BALCON DEL CESAR"},
  { value: "PAILITAS", label: "PAILITAS"},
  { value: "PELAYA", label: "PELAYA"},
  { value: "PUEBLO BELLO", label: "PUEBLO BELLO"},
  { value: "RIO DE ORO", label: "RIO DE ORO"},
  { value: "SAN ALBERTO", label: "SAN ALBERTO"},
  { value: "SAN DIEGO", label: "SAN DIEGO"},
  { value: "SAN MARTIN", label: "SAN MARTIN"},
  { value: "TAMALAMEQUE", label: "TAMALAMEQUE"},
  { value: "VALLEDUPAR", label: "VALLEDUPAR"},
  { value: "ACANDI", label: "ACANDI"},
  { value: "ALTO BAUDO", label: "ALTO BAUDO"},
  { value: "ATRATO", label: "ATRATO"},
  { value: "BAGADO", label: "BAGADO"},
  { value: "BAHIA SOLANO", label: "BAHIA SOLANO"},
  { value: "BAJO BAUDO", label: "BAJO BAUDO"},
  { value: "BOJAYA", label: "BOJAYA"},
  { value: "CARMEN DEL DARIEN", label: "CARMEN DEL DARIEN"},
  { value: "CERTEGUI", label: "CERTEGUI"},
  { value: "CONDOTO", label: "CONDOTO"},
  { value: "EL CANTON DEL SAN PABLO", label: "EL CANTON DEL SAN PABLO"},
  { value: "EL CARMEN", label: "EL CARMEN"},
  { value: "EL LITORAL DEL SAN JUAN", label: "EL LITORAL DEL SAN JUAN"},
  { value: "ITSMINA", label: "ITSMINA"},
  { value: "JURADO", label: "JURADO"},
  { value: "LLORO", label: "LLORO"},
  { value: "MEDIO ATRATO", label: "MEDIO ATRATO"},
  { value: "MEDIO BAUDO", label: "MEDIO BAUDO"},
  { value: "MEDIO SAN JUAN", label: "MEDIO SAN JUAN"},
  { value: "NOVITA", label: "NOVITA"},
  { value: "NUQUI", label: "NUQUI"},
  { value: "QUIBDO", label: "QUIBDO"},
  { value: "RIO IRO", label: "RIO IRO"},
  { value: "RIO QUITO", label: "RIO QUITO"},
  { value: "RIOSUCIO", label: "RIOSUCIO"},
  { value: "SAN JOSE DEL PALMAR", label: "SAN JOSE DEL PALMAR"},
  { value: "SIPI", label: "SIPI"},
  { value: "TADO", label: "TADO"},
  { value: "UNGUIA", label: "UNGUIA"},
  { value: "UNION PANAMERICANA", label: "UNION PANAMERICANA"},
  { value: "AYAPEL", label: "AYAPEL"},
  { value: "BUENAVISTA", label: "BUENAVISTA"},
  { value: "CANALETE", label: "CANALETE"},
  { value: "CERETE", label: "CERETE"},
  { value: "CHIMA", label: "CHIMA"},
  { value: "CHINU", label: "CHINU"},
  { value: "CIENAGA DE ORO", label: "CIENAGA DE ORO"},
  { value: "COTORRA", label: "COTORRA"},
  { value: "LA APARTADA", label: "LA APARTADA"},
  { value: "LORICA", label: "LORICA"},
  { value: "LOS CORDOBAS", label: "LOS CORDOBAS"},
  { value: "MOMIL", label: "MOMIL"},
  { value: "MONITOS", label: "MONITOS"},
  { value: "MONTELIBANO", label: "MONTELIBANO"},
  { value: "MONTERIA", label: "MONTERIA"},
  { value: "PLANETA RICA", label: "PLANETA RICA"},
  { value: "PUEBLO NUEVO", label: "PUEBLO NUEVO"},
  { value: "PUERTO ESCONDIDO", label: "PUERTO ESCONDIDO"},
  { value: "PUERTO LIBERTADOR", label: "PUERTO LIBERTADOR"},
  { value: "PURISIMA", label: "PURISIMA"},
  { value: "SAHAGUN", label: "SAHAGUN"},
  { value: "SAN ANDRES DE SOTAVENTO", label: "SAN ANDRES DE SOTAVENTO"},
  { value: "SAN ANTERO", label: "SAN ANTERO"},
  { value: "SAN BERNARDO DEL VIENTO", label: "SAN BERNARDO DEL VIENTO"},
  { value: "SAN CARLOS", label: "SAN CARLOS"},
  { value: "SAN PELAYO", label: "SAN PELAYO"},
  { value: "TIERRALTA", label: "TIERRALTA"},
  { value: "VALENCIA", label: "VALENCIA"},
  { value: "AGUA DE DIOS", label: "AGUA DE DIOS"},
  { value: "ALBAN", label: "ALBAN"},
  { value: "ANAPOIMA", label: "ANAPOIMA"},
  { value: "ANOLAIMA", label: "ANOLAIMA"},
  { value: "APULO", label: "APULO"},
  { value: "ARBELAEZ", label: "ARBELAEZ"},
  { value: "BELTRAN", label: "BELTRAN"},
  { value: "BITUIMA", label: "BITUIMA"},
  { value: "BOJACA", label: "BOJACA"},
  { value: "CABRERA", label: "CABRERA"},
  { value: "CACHIPAY", label: "CACHIPAY"},
  { value: "CAJICA", label: "CAJICA"},
  { value: "CAPARRAPI", label: "CAPARRAPI"},
  { value: "CAQUEZA", label: "CAQUEZA"},
  { value: "CARMEN DE CARUPA", label: "CARMEN DE CARUPA"},
  { value: "CHAGUANI", label: "CHAGUANI"},
  { value: "CHIA", label: "CHIA"},
  { value: "CHIPAQUE", label: "CHIPAQUE"},
  { value: "CHOACHI", label: "CHOACHI"},
  { value: "CHOCONTA", label: "CHOCONTA"},
  { value: "COGUA", label: "COGUA"},
  { value: "COTA", label: "COTA"},
  { value: "CUCUNUBA", label: "CUCUNUBA"},
  { value: "EL COLEGIO", label: "EL COLEGIO"},
  { value: "EL PENON", label: "EL PENON"},
  { value: "EL ROSAL", label: "EL ROSAL"},
  { value: "FACATATIVA", label: "FACATATIVA"},
  { value: "FOMEQUE", label: "FOMEQUE"},
  { value: "FOSCA", label: "FOSCA"},
  { value: "FUNZA", label: "FUNZA"},
  { value: "FUQUENE", label: "FUQUENE"},
  { value: "FUSAGASUGA", label: "FUSAGASUGA"},
  { value: "GACHALA", label: "GACHALA"},
  { value: "GACHANCIPA", label: "GACHANCIPA"},
  { value: "GACHETA", label: "GACHETA"},
  { value: "GAMA", label: "GAMA"},
  { value: "GIRARDOT", label: "GIRARDOT"},
  { value: "GRANADA", label: "GRANADA"},
  { value: "GUACHETA", label: "GUACHETA"},
  { value: "GUADUAS", label: "GUADUAS"},
  { value: "GUASCA", label: "GUASCA"},
  { value: "GUATAQUI", label: "GUATAQUI"},
  { value: "GUATAVITA", label: "GUATAVITA"},
  { value: "GUAYABAL DE SIQUIMA", label: "GUAYABAL DE SIQUIMA"},
  { value: "GUAYABETAL", label: "GUAYABETAL"},
  { value: "GUTIERREZ", label: "GUTIERREZ"},
  { value: "JERUSALEN", label: "JERUSALEN"},
  { value: "JUNIN", label: "JUNIN"},
  { value: "LA CALERA", label: "LA CALERA"},
  { value: "LA MESA", label: "LA MESA"},
  { value: "LA PALMA", label: "LA PALMA"},
  { value: "LA PENA", label: "LA PENA"},
  { value: "LA VEGA", label: "LA VEGA"},
  { value: "LENGUAZAQUE", label: "LENGUAZAQUE"},
  { value: "MACHETA", label: "MACHETA"},
  { value: "MADRID", label: "MADRID"},
  { value: "MANTA", label: "MANTA"},
  { value: "MEDINA", label: "MEDINA"},
  { value: "MOSQUERA", label: "MOSQUERA"},
  { value: "NARINO", label: "NARINO"},
  { value: "NEMOCON", label: "NEMOCON"},
  { value: "NILO", label: "NILO"},
  { value: "NIMAIMA", label: "NIMAIMA"},
  { value: "NOCAIMA", label: "NOCAIMA"},
  { value: "PACHO", label: "PACHO"},
  { value: "PAIME", label: "PAIME"},
  { value: "PANDI", label: "PANDI"},
  { value: "PARATEBUENO", label: "PARATEBUENO"},
  { value: "PASCA", label: "PASCA"},
  { value: "PUERTO SALGAR", label: "PUERTO SALGAR"},
  { value: "PULI", label: "PULI"},
  { value: "QUEBRADANEGRA", label: "QUEBRADANEGRA"},
  { value: "QUETAME", label: "QUETAME"},
  { value: "QUIPILE", label: "QUIPILE"},
  { value: "RICAURTE", label: "RICAURTE"},
  { value: "SAN ANTONIO DE TEQUENDAMA", label: "SAN ANTONIO DE TEQUENDAMA"},
  { value: "SAN BERNARDO", label: "SAN BERNARDO"},
  { value: "SAN CAYETANO", label: "SAN CAYETANO"},
  { value: "SAN FRANCISCO", label: "SAN FRANCISCO"},
  { value: "SAN JUAN DE RIOSECO", label: "SAN JUAN DE RIOSECO"},
  { value: "SASAIMA", label: "SASAIMA"},
  { value: "SESQUILE", label: "SESQUILE"},
  { value: "SIBATE", label: "SIBATE"},
  { value: "SILVANIA", label: "SILVANIA"},
  { value: "SIMIJACA", label: "SIMIJACA"},
  { value: "SOACHA", label: "SOACHA"},
  { value: "SOPO", label: "SOPO"},
  { value: "SUBACHOQUE", label: "SUBACHOQUE"},
  { value: "SUESCA", label: "SUESCA"},
  { value: "SUPATA", label: "SUPATA"},
  { value: "SUSA", label: "SUSA"},
  { value: "SUTATAUSA", label: "SUTATAUSA"},
  { value: "TABIO", label: "TABIO"},
  { value: "TAUSA", label: "TAUSA"},
  { value: "TENA", label: "TENA"},
  { value: "TENJO", label: "TENJO"},
  { value: "TIBACUY", label: "TIBACUY"},
  { value: "TIBIRITA", label: "TIBIRITA"},
  { value: "TOCAIMA", label: "TOCAIMA"},
  { value: "TOCANCIPA", label: "TOCANCIPA"},
  { value: "TOPAIPI", label: "TOPAIPI"},
  { value: "UBALA", label: "UBALA"},
  { value: "UBAQUE", label: "UBAQUE"},
  { value: "UBATE", label: "UBATE"},
  { value: "UNE", label: "UNE"},
  { value: "UTICA", label: "UTICA"},
  { value: "VENECIA", label: "VENECIA"},
  { value: "VERGARA", label: "VERGARA"},
  { value: "VIANI", label: "VIANI"},
  { value: "VILLAGOMEZ", label: "VILLAGOMEZ"},
  { value: "VILLAPINZON", label: "VILLAPINZON"},
  { value: "VILLETA", label: "VILLETA"},
  { value: "VIOTA", label: "VIOTA"},
  { value: "YACOPI", label: "YACOPI"},
  { value: "ZIPACON", label: "ZIPACON"},
  { value: "ZIPAQUIRA", label: "ZIPAQUIRA"},
  { value: "BARRANCO MINA", label: "BARRANCO MINA"},
  { value: "CACAHUAL", label: "CACAHUAL"},
  { value: "INIRIDA", label: "INIRIDA"},
  { value: "LA GUADALUPE", label: "LA GUADALUPE"},
  { value: "MAPIRIPANA", label: "MAPIRIPANA"},
  { value: "MORICHAL", label: "MORICHAL"},
  { value: "PANA-PANA", label: "PANA-PANA"},
  { value: "PUERTO COLOMBIA", label: "PUERTO COLOMBIA"},
  { value: "SAN FELIPE", label: "SAN FELIPE"},
  { value: "ALBANIA", label: "ALBANIA"},
  { value: "BARRANCAS", label: "BARRANCAS"},
  { value: "DIBULLA", label: "DIBULLA"},
  { value: "DISTRACCION", label: "DISTRACCION"},
  { value: "EL MOLINO", label: "EL MOLINO"},
  { value: "FONSECA", label: "FONSECA"},
  { value: "HATO NUEVO", label: "HATO NUEVO"},
  { value: "LA JAGUA DEL PILAR", label: "LA JAGUA DEL PILAR"},
  { value: "MAICAO", label: "MAICAO"},
  { value: "MANAURE", label: "MANAURE"},
  { value: "RIOHACHA", label: "RIOHACHA"},
  { value: "SAN JUAN DEL CESAR", label: "SAN JUAN DEL CESAR"},
  { value: "URIBIA", label: "URIBIA"},
  { value: "URUMITA", label: "URUMITA"},
  { value: "VILLANUEVA", label: "VILLANUEVA"},
  { value: "CALAMAR", label: "CALAMAR"},
  { value: "EL RETORNO", label: "EL RETORNO"},
  { value: "MIRAFLORES", label: "MIRAFLORES"},
  { value: "SAN JOSE DEL GUAVIARE", label: "SAN JOSE DEL GUAVIARE"},
  { value: "ACEVEDO", label: "ACEVEDO"},
  { value: "AGRADO", label: "AGRADO"},
  { value: "AIPE", label: "AIPE"},
  { value: "ALGECIRAS", label: "ALGECIRAS"},
  { value: "ALTAMIRA", label: "ALTAMIRA"},
  { value: "BARAYA", label: "BARAYA"},
  { value: "CAMPOALEGRE", label: "CAMPOALEGRE"},
  { value: "COLOMBIA", label: "COLOMBIA"},
  { value: "ELIAS", label: "ELIAS"},
  { value: "GARZON", label: "GARZON"},
  { value: "GIGANTE", label: "GIGANTE"},
  { value: "GUADALUPE", label: "GUADALUPE"},
  { value: "HOBO", label: "HOBO"},
  { value: "IQUIRA", label: "IQUIRA"},
  { value: "ISNOS", label: "ISNOS"},
  { value: "LA ARGENTINA", label: "LA ARGENTINA"},
  { value: "LA PLATA", label: "LA PLATA"},
  { value: "NATAGA", label: "NATAGA"},
  { value: "NEIVA", label: "NEIVA"},
  { value: "OPORAPA", label: "OPORAPA"},
  { value: "PAICOL", label: "PAICOL"},
  { value: "PALERMO", label: "PALERMO"},
  { value: "PALESTINA", label: "PALESTINA"},
  { value: "PITAL", label: "PITAL"},
  { value: "PITALITO", label: "PITALITO"},
  { value: "RIVERA", label: "RIVERA"},
  { value: "SALADOBLANCO", label: "SALADOBLANCO"},
  { value: "SAN AGUSTIN", label: "SAN AGUSTIN"},
  { value: "SANTA MARIA", label: "SANTA MARIA"},
  { value: "SUAZA", label: "SUAZA"},
  { value: "TARQUI", label: "TARQUI"},
  { value: "TELLO", label: "TELLO"},
  { value: "TERUEL", label: "TERUEL"},
  { value: "TESALIA", label: "TESALIA"},
  { value: "TIMANA", label: "TIMANA"},
  { value: "VILLAVIEJA", label: "VILLAVIEJA"},
  { value: "YAGUARA", label: "YAGUARA"},
  { value: "ALGARROBO", label: "ALGARROBO"},
  { value: "ARACATACA", label: "ARACATACA"},
  { value: "ARIGUANI", label: "ARIGUANI"},
  { value: "CERRO DE SAN ANTONIO", label: "CERRO DE SAN ANTONIO"},
  { value: "CHIVOLO", label: "CHIVOLO"},
  { value: "CIENAGA", label: "CIENAGA"},
  { value: "CONCORDIA", label: "CONCORDIA"},
  { value: "EL BANCO", label: "EL BANCO"},
  { value: "EL PINON", label: "EL PINON"},
  { value: "EL RETEN", label: "EL RETEN"},
  { value: "FUNDACION", label: "FUNDACION"},
  { value: "GUAMAL", label: "GUAMAL"},
  { value: "NUEVA GRANADA", label: "NUEVA GRANADA"},
  { value: "PEDRAZA", label: "PEDRAZA"},
  { value: "PIJINO DEL CARMEN", label: "PIJINO DEL CARMEN"},
  { value: "PIVIJAY", label: "PIVIJAY"},
  { value: "PLATO", label: "PLATO"},
  { value: "PUEBLOVIEJO", label: "PUEBLOVIEJO"},
  { value: "REMOLINO", label: "REMOLINO"},
  { value: "SABANAS DE SAN ANGEL", label: "SABANAS DE SAN ANGEL"},
  { value: "SALAMINA", label: "SALAMINA"},
  { value: "SAN SEBASTIAN DE BUENAVISTA", label: "SAN SEBASTIAN DE BUENAVISTA"},
  { value: "SAN ZENON", label: "SAN ZENON"},
  { value: "SANTA ANA", label: "SANTA ANA"},
  { value: "SANTA BARBARA DE PINTO", label: "SANTA BARBARA DE PINTO"},
  { value: "SANTA MARTA", label: "SANTA MARTA"},
  { value: "SITIONUEVO", label: "SITIONUEVO"},
  { value: "TENERIFE", label: "TENERIFE"},
  { value: "ZAPAYAN", label: "ZAPAYAN"},
  { value: "ZONA BANANERA", label: "ZONA BANANERA"},
  { value: "ACACIAS", label: "ACACIAS"},
  { value: "BARRANCA DE UPIA", label: "BARRANCA DE UPIA"},
  { value: "CABUYARO", label: "CABUYARO"},
  { value: "CASTILLA LA NUEVA", label: "CASTILLA LA NUEVA"},
  { value: "CUBARRAL", label: "CUBARRAL"},
  { value: "CUMARAL", label: "CUMARAL"},
  { value: "EL CALVARIO", label: "EL CALVARIO"},
  { value: "EL CASTILLO", label: "EL CASTILLO"},
  { value: "EL DORADO", label: "EL DORADO"},
  { value: "FUENTE DE ORO", label: "FUENTE DE ORO"},
  { value: "GRANADA", label: "GRANADA"},
  { value: "GUAMAL", label: "GUAMAL"},
  { value: "LA MACARENA", label: "LA MACARENA"},
  { value: "LEJANIAS", label: "LEJANIAS"},
  { value: "MAPIRIPAN", label: "MAPIRIPAN"},
  { value: "MESETAS", label: "MESETAS"},
  { value: "PUERTO CONCORDIA", label: "PUERTO CONCORDIA"},
  { value: "PUERTO GAITAN", label: "PUERTO GAITAN"},
  { value: "PUERTO LLERAS", label: "PUERTO LLERAS"},
  { value: "PUERTO LOPEZ", label: "PUERTO LOPEZ"},
  { value: "PUERTO RICO", label: "PUERTO RICO"},
  { value: "RESTREPO", label: "RESTREPO"},
  { value: "SAN CARLOS DE GUAROA", label: "SAN CARLOS DE GUAROA"},
  { value: "SAN JUAN DE ARAMA", label: "SAN JUAN DE ARAMA"},
  { value: "SAN JUANITO", label: "SAN JUANITO"},
  { value: "SAN MARTIN", label: "SAN MARTIN"},
  { value: "URIBE", label: "URIBE"},
  { value: "VILLAVICENCIO", label: "VILLAVICENCIO"},
  { value: "VISTAHERMOSA", label: "VISTAHERMOSA"},
  { value: "ABREGO", label: "ABREGO"},
  { value: "ARBOLEDAS", label: "ARBOLEDAS"},
  { value: "BOCHALEMA", label: "BOCHALEMA"},
  { value: "BUCARASICA", label: "BUCARASICA"},
  { value: "CACHIRA", label: "CACHIRA"},
  { value: "CACOTA", label: "CACOTA"},
  { value: "CHINACOTA", label: "CHINACOTA"},
  { value: "CHITAGA", label: "CHITAGA"},
  { value: "CONVENCION", label: "CONVENCION"},
  { value: "CUCUTA", label: "CUCUTA"},
  { value: "CUCUTILLA", label: "CUCUTILLA"},
  { value: "DURANIA", label: "DURANIA"},
  { value: "EL CARMEN", label: "EL CARMEN"},
  { value: "EL TARRA", label: "EL TARRA"},
  { value: "EL ZULIA", label: "EL ZULIA"},
  { value: "GRAMALOTE", label: "GRAMALOTE"},
  { value: "HACARI", label: "HACARI"},
  { value: "HERRAN", label: "HERRAN"},
  { value: "LA ESPERANZA", label: "LA ESPERANZA"},
  { value: "LA PLAYA", label: "LA PLAYA"},
  { value: "LABATECA", label: "LABATECA"},
  { value: "LOS PATIOS", label: "LOS PATIOS"},
  { value: "LOURDES", label: "LOURDES"},
  { value: "MUTISCUA", label: "MUTISCUA"},
  { value: "OCANA", label: "OCANA"},
  { value: "PAMPLONA", label: "PAMPLONA"},
  { value: "PAMPLONITA", label: "PAMPLONITA"},
  { value: "PUERTO SANTANDER", label: "PUERTO SANTANDER"},
  { value: "RAGONVALIA", label: "RAGONVALIA"},
  { value: "SALAZAR", label: "SALAZAR"},
  { value: "SAN CALIXTO", label: "SAN CALIXTO"},
  { value: "SAN CAYETANO", label: "SAN CAYETANO"},
  { value: "SANTIAGO", label: "SANTIAGO"},
  { value: "SARDINATA", label: "SARDINATA"},
  { value: "SILOS", label: "SILOS"},
  { value: "TEORAMA", label: "TEORAMA"},
  { value: "TIBU", label: "TIBU"},
  { value: "TOLEDO", label: "TOLEDO"},
  { value: "VILLA CARO", label: "VILLA CARO"},
  { value: "VILLA DEL ROSARIO", label: "VILLA DEL ROSARIO"},
  { value: "ALBAN", label: "ALBAN"},
  { value: "ALDANA", label: "ALDANA"},
  { value: "ANCUYA", label: "ANCUYA"},
  { value: "ARBOLEDA", label: "ARBOLEDA"},
  { value: "BARBACOAS", label: "BARBACOAS"},
  { value: "BELEN", label: "BELEN"},
  { value: "BUESACO", label: "BUESACO"},
  { value: "CHACHAGUI", label: "CHACHAGUI"},
  { value: "COLON (GENOVA)", label: "COLON (GENOVA)"},
  { value: "CONSACA", label: "CONSACA"},
  { value: "CONTADERO", label: "CONTADERO"},
  { value: "CORDOBA", label: "CORDOBA"},
  { value: "CUASPUD", label: "CUASPUD"},
  { value: "CUMBAL", label: "CUMBAL"},
  { value: "CUMBITARA", label: "CUMBITARA"},
  { value: "EL CHARCO", label: "EL CHARCO"},
  { value: "EL PENOL", label: "EL PENOL"},
  { value: "EL ROSARIO", label: "EL ROSARIO"},
  { value: "EL TABLON", label: "EL TABLON"},
  { value: "EL TAMBO", label: "EL TAMBO"},
  { value: "FRANCISCO PIZARRO", label: "FRANCISCO PIZARRO"},
  { value: "FUNES", label: "FUNES"},
  { value: "GUACHUCAL", label: "GUACHUCAL"},
  { value: "GUAITARILLA", label: "GUAITARILLA"},
  { value: "GUALMATAN", label: "GUALMATAN"},
  { value: "ILES", label: "ILES"},
  { value: "IMUES", label: "IMUES"},
  { value: "IPIALES", label: "IPIALES"},
  { value: "LA CRUZ", label: "LA CRUZ"},
  { value: "LA FLORIDA", label: "LA FLORIDA"},
  { value: "LA LLANADA", label: "LA LLANADA"},
  { value: "LA TOLA", label: "LA TOLA"},
  { value: "LA UNION", label: "LA UNION"},
  { value: "LEIVA", label: "LEIVA"},
  { value: "LINARES", label: "LINARES"},
  { value: "LOS ANDES", label: "LOS ANDES"},
  { value: "MAG&UUML;I", label: "MAG&UUML;I"},
  { value: "MALLAMA", label: "MALLAMA"},
  { value: "MOSQUERA", label: "MOSQUERA"},
  { value: "NARINO", label: "NARINO"},
  { value: "OLAYA HERRERA", label: "OLAYA HERRERA"},
  { value: "OSPINA", label: "OSPINA"},
  { value: "PASTO", label: "PASTO"},
  { value: "POLICARPA", label: "POLICARPA"},
  { value: "POTOSI", label: "POTOSI"},
  { value: "PROVIDENCIA", label: "PROVIDENCIA"},
  { value: "PUERRES", label: "PUERRES"},
  { value: "PUPIALES", label: "PUPIALES"},
  { value: "RICAURTE", label: "RICAURTE"},
  { value: "ROBERTO PAYAN", label: "ROBERTO PAYAN"},
  { value: "SAMANIEGO", label: "SAMANIEGO"},
  { value: "SAN BERNARDO", label: "SAN BERNARDO"},
  { value: "SAN LORENZO", label: "SAN LORENZO"},
  { value: "SAN PABLO", label: "SAN PABLO"},
  { value: "SAN PEDRO DE CARTAGO", label: "SAN PEDRO DE CARTAGO"},
  { value: "SANDONA", label: "SANDONA"},
  { value: "SANTA BARBARA", label: "SANTA BARBARA"},
  { value: "SANTA CRUZ", label: "SANTA CRUZ"},
  { value: "SAPUYES", label: "SAPUYES"},
  { value: "TAMINANGO", label: "TAMINANGO"},
  { value: "TANGUA", label: "TANGUA"},
  { value: "TUMACO", label: "TUMACO"},
  { value: "TUQUERRES", label: "TUQUERRES"},
  { value: "YACUANQUER", label: "YACUANQUER"},
  { value: "COLON", label: "COLON"},
  { value: "MOCOA", label: "MOCOA"},
  { value: "ORITO", label: "ORITO"},
  { value: "PUERTO ASIS", label: "PUERTO ASIS"},
  { value: "PUERTO CAICEDO", label: "PUERTO CAICEDO"},
  { value: "PUERTO GUZMAN", label: "PUERTO GUZMAN"},
  { value: "PUERTO LEGUIZAMO", label: "PUERTO LEGUIZAMO"},
  { value: "SAN FRANCISCO", label: "SAN FRANCISCO"},
  { value: "SAN MIGUEL", label: "SAN MIGUEL"},
  { value: "SANTIAGO", label: "SANTIAGO"},
  { value: "SIBUNDOY", label: "SIBUNDOY"},
  { value: "VALLE DEL GUAMUEZ", label: "VALLE DEL GUAMUEZ"},
  { value: "VILLAGARZON", label: "VILLAGARZON"},
  { value: "ARMENIA", label: "ARMENIA"},
  { value: "BUENAVISTA", label: "BUENAVISTA"},
  { value: "CALARCA", label: "CALARCA"},
  { value: "CIRCASIA", label: "CIRCASIA"},
  { value: "CORDOBA", label: "CORDOBA"},
  { value: "FILANDIA", label: "FILANDIA"},
  { value: "GENOVA", label: "GENOVA"},
  { value: "LA TEBAIDA", label: "LA TEBAIDA"},
  { value: "MONTENEGRO", label: "MONTENEGRO"},
  { value: "PIJAO", label: "PIJAO"},
  { value: "QUIMBAYA", label: "QUIMBAYA"},
  { value: "SALENTO", label: "SALENTO"},
  { value: "APIA", label: "APIA"},
  { value: "BALBOA", label: "BALBOA"},
  { value: "BELEN DE UMBRIA", label: "BELEN DE UMBRIA"},
  { value: "DOSQUEBRADAS", label: "DOSQUEBRADAS"},
  { value: "GUATICA", label: "GUATICA"},
  { value: "LA CELIA", label: "LA CELIA"},
  { value: "LA VIRGINIA", label: "LA VIRGINIA"},
  { value: "MARSELLA", label: "MARSELLA"},
  { value: "MISTRATO", label: "MISTRATO"},
  { value: "PEREIRA", label: "PEREIRA"},
  { value: "PUEBLO RICO", label: "PUEBLO RICO"},
  { value: "QUINCHIA", label: "QUINCHIA"},
  { value: "SANTA ROSA DE CABAL", label: "SANTA ROSA DE CABAL"},
  { value: "SANTUARIO", label: "SANTUARIO"},
  { value: "PROVIDENCIA Y SANTA CATALINA", label: "PROVIDENCIA Y SANTA CATALINA"},
  { value: "SAN ANDRES", label: "SAN ANDRES"},
  { value: "AGUADA", label: "AGUADA"},
  { value: "ALBANIA", label: "ALBANIA"},
  { value: "ARATOCA", label: "ARATOCA"},
  { value: "BARBOSA", label: "BARBOSA"},
  { value: "BARICHARA", label: "BARICHARA"},
  { value: "BARRANCABERMEJA", label: "BARRANCABERMEJA"},
  { value: "BETULIA", label: "BETULIA"},
  { value: "BOLIVAR", label: "BOLIVAR"},
  { value: "BUCARAMANGA", label: "BUCARAMANGA"},
  { value: "CABRERA", label: "CABRERA"},
  { value: "CALIFORNIA", label: "CALIFORNIA"},
  { value: "CAPITANEJO", label: "CAPITANEJO"},
  { value: "CARCASI", label: "CARCASI"},
  { value: "CEPITA", label: "CEPITA"},
  { value: "CERRITO", label: "CERRITO"},
  { value: "CHARALA", label: "CHARALA"},
  { value: "CHARTA", label: "CHARTA"},
  { value: "CHIMA", label: "CHIMA"},
  { value: "CHIPATA", label: "CHIPATA"},
  { value: "CIMITARRA", label: "CIMITARRA"},
  { value: "CONCEPCION", label: "CONCEPCION"},
  { value: "CONFINES", label: "CONFINES"},
  { value: "CONTRATACION", label: "CONTRATACION"},
  { value: "COROMORO", label: "COROMORO"},
  { value: "CURITI", label: "CURITI"},
  { value: "EL CARMEN", label: "EL CARMEN"},
  { value: "EL GUACAMAYO", label: "EL GUACAMAYO"},
  { value: "EL PENON", label: "EL PENON"},
  { value: "EL PLAYON", label: "EL PLAYON"},
  { value: "ENCINO", label: "ENCINO"},
  { value: "ENCISO", label: "ENCISO"},
  { value: "FLORIAN", label: "FLORIAN"},
  { value: "FLORIDABLANCA", label: "FLORIDABLANCA"},
  { value: "GALAN", label: "GALAN"},
  { value: "GAMBITA", label: "GAMBITA"},
  { value: "GIRON", label: "GIRON"},
  { value: "GUACA", label: "GUACA"},
  { value: "GUADALUPE", label: "GUADALUPE"},
  { value: "GUAPOTA", label: "GUAPOTA"},
  { value: "GUAVATA", label: "GUAVATA"},
  { value: "G&UUML;EPSA", label: "G&UUML;EPSA"},
  { value: "HATO", label: "HATO"},
  { value: "JESUS MARIA", label: "JESUS MARIA"},
  { value: "JORDAN", label: "JORDAN"},
  { value: "LA BELLEZA", label: "LA BELLEZA"},
  { value: "LA PAZ", label: "LA PAZ"},
  { value: "LANDAZURI", label: "LANDAZURI"},
  { value: "LEBRIJA", label: "LEBRIJA"},
  { value: "LOS SANTOS", label: "LOS SANTOS"},
  { value: "MACARAVITA", label: "MACARAVITA"},
  { value: "MALAGA", label: "MALAGA"},
  { value: "MATANZA", label: "MATANZA"},
  { value: "MOGOTES", label: "MOGOTES"},
  { value: "MOLAGAVITA", label: "MOLAGAVITA"},
  { value: "OCAMONTE", label: "OCAMONTE"},
  { value: "OIBA", label: "OIBA"},
  { value: "ONZAGA", label: "ONZAGA"},
  { value: "PALMAR", label: "PALMAR"},
  { value: "PALMAS DEL SOCORRO", label: "PALMAS DEL SOCORRO"},
  { value: "PARAMO", label: "PARAMO"},
  { value: "PIEDECUESTA", label: "PIEDECUESTA"},
  { value: "PINCHOTE", label: "PINCHOTE"},
  { value: "PUENTE NACIONAL", label: "PUENTE NACIONAL"},
  { value: "PUERTO PARRA", label: "PUERTO PARRA"},
  { value: "PUERTO WILCHES", label: "PUERTO WILCHES"},
  { value: "RIONEGRO", label: "RIONEGRO"},
  { value: "SABANA DE TORRES", label: "SABANA DE TORRES"},
  { value: "SAN ANDRES", label: "SAN ANDRES"},
  { value: "SAN BENITO", label: "SAN BENITO"},
  { value: "SAN GIL", label: "SAN GIL"},
  { value: "SAN JOAQUIN", label: "SAN JOAQUIN"},
  { value: "SAN JOSE DE MIRANDA", label: "SAN JOSE DE MIRANDA"},
  { value: "SAN MIGUEL", label: "SAN MIGUEL"},
  { value: "SAN VICENTE DE CHUCURI", label: "SAN VICENTE DE CHUCURI"},
  { value: "SANTA BARBARA", label: "SANTA BARBARA"},
  { value: "SANTA HELENA DEL OPON", label: "SANTA HELENA DEL OPON"},
  { value: "SIMACOTA", label: "SIMACOTA"},
  { value: "SOCORRO", label: "SOCORRO"},
  { value: "SUAITA", label: "SUAITA"},
  { value: "SUCRE", label: "SUCRE"},
  { value: "SURATA", label: "SURATA"},
  { value: "TONA", label: "TONA"},
  { value: "VALLE DE SAN JOSE", label: "VALLE DE SAN JOSE"},
  { value: "VELEZ", label: "VELEZ"},
  { value: "VETAS", label: "VETAS"},
  { value: "VILLANUEVA", label: "VILLANUEVA"},
  { value: "ZAPATOCA", label: "ZAPATOCA"},
  { value: "BUENAVISTA", label: "BUENAVISTA"},
  { value: "CAIMITO", label: "CAIMITO"},
  { value: "CHALAN", label: "CHALAN"},
  { value: "COLOSO", label: "COLOSO"},
  { value: "COROZAL", label: "COROZAL"},
  { value: "COVENAS", label: "COVENAS"},
  { value: "EL ROBLE", label: "EL ROBLE"},
  { value: "GALERAS", label: "GALERAS"},
  { value: "GUARANDA", label: "GUARANDA"},
  { value: "LA UNION", label: "LA UNION"},
  { value: "LOS PALMITOS", label: "LOS PALMITOS"},
  { value: "MAJAGUAL", label: "MAJAGUAL"},
  { value: "MORROA", label: "MORROA"},
  { value: "OVEJAS", label: "OVEJAS"},
  { value: "PALMITO", label: "PALMITO"},
  { value: "SAMPUES", label: "SAMPUES"},
  { value: "SAN BENITO ABAD", label: "SAN BENITO ABAD"},
  { value: "SAN JUAN DE BETULIA", label: "SAN JUAN DE BETULIA"},
  { value: "SAN MARCOS", label: "SAN MARCOS"},
  { value: "SAN ONOFRE", label: "SAN ONOFRE"},
  { value: "SAN PEDRO", label: "SAN PEDRO"},
  { value: "SINCE", label: "SINCE"},
  { value: "SINCELEJO", label: "SINCELEJO"},
  { value: "SUCRE", label: "SUCRE"},
  { value: "TOLU", label: "TOLU"},
  { value: "TOLUVIEJO", label: "TOLUVIEJO"},
  { value: "ALPUJARRA", label: "ALPUJARRA"},
  { value: "ALVARADO", label: "ALVARADO"},
  { value: "AMBALEMA", label: "AMBALEMA"},
  { value: "ANZOATEGUI", label: "ANZOATEGUI"},
  { value: "ARMERO", label: "ARMERO"},
  { value: "ATACO", label: "ATACO"},
  { value: "CAJAMARCA", label: "CAJAMARCA"},
  { value: "CARMEN DE APICALA", label: "CARMEN DE APICALA"},
  { value: "CASABIANCA", label: "CASABIANCA"},
  { value: "CHAPARRAL", label: "CHAPARRAL"},
  { value: "COELLO", label: "COELLO"},
  { value: "COYAIMA", label: "COYAIMA"},
  { value: "CUNDAY", label: "CUNDAY"},
  { value: "DOLORES", label: "DOLORES"},
  { value: "ESPINAL", label: "ESPINAL"},
  { value: "FALAN", label: "FALAN"},
  { value: "FLANDES", label: "FLANDES"},
  { value: "FRESNO", label: "FRESNO"},
  { value: "GUAMO", label: "GUAMO"},
  { value: "HERVEO", label: "HERVEO"},
  { value: "HONDA", label: "HONDA"},
  { value: "IBAGUE", label: "IBAGUE"},
  { value: "ICONONZO", label: "ICONONZO"},
  { value: "LERIDA", label: "LERIDA"},
  { value: "LIBANO", label: "LIBANO"},
  { value: "MARIQUITA", label: "MARIQUITA"},
  { value: "MELGAR", label: "MELGAR"},
  { value: "MURILLO", label: "MURILLO"},
  { value: "NATAGAIMA", label: "NATAGAIMA"},
  { value: "ORTEGA", label: "ORTEGA"},
  { value: "PALOCABILDO", label: "PALOCABILDO"},
  { value: "PIEDRAS", label: "PIEDRAS"},
  { value: "PLANADAS", label: "PLANADAS"},
  { value: "PRADO", label: "PRADO"},
  { value: "PURIFICACION", label: "PURIFICACION"},
  { value: "RIOBLANCO", label: "RIOBLANCO"},
  { value: "RONCESVALLES", label: "RONCESVALLES"},
  { value: "ROVIRA", label: "ROVIRA"},
  { value: "SALDANA", label: "SALDANA"},
  { value: "SAN ANTONIO", label: "SAN ANTONIO"},
  { value: "SAN LUIS", label: "SAN LUIS"},
  { value: "SANTA ISABEL", label: "SANTA ISABEL"},
  { value: "SUAREZ", label: "SUAREZ"},
  { value: "VALLE DE SAN JUAN", label: "VALLE DE SAN JUAN"},
  { value: "VENADILLO", label: "VENADILLO"},
  { value: "VILLAHERMOSA", label: "VILLAHERMOSA"},
  { value: "VILLARRICA", label: "VILLARRICA"},
  { value: "ALCALA", label: "ALCALA"},
  { value: "ANDALUCIA", label: "ANDALUCIA"},
  { value: "ANSERMANUEVO", label: "ANSERMANUEVO"},
  { value: "ARGELIA", label: "ARGELIA"},
  { value: "BOLIVAR", label: "BOLIVAR"},
  { value: "BUENAVENTURA", label: "BUENAVENTURA"},
  { value: "BUGA", label: "BUGA"},
  { value: "BUGALAGRANDE", label: "BUGALAGRANDE"},
  { value: "CAICEDONIA", label: "CAICEDONIA"},
  { value: "CALI", label: "CALI"},
  { value: "CALIMA", label: "CALIMA"},
  { value: "CANDELARIA", label: "CANDELARIA"},
  { value: "CARTAGO", label: "CARTAGO"},
  { value: "DAGUA", label: "DAGUA"},
  { value: "EL AGUILA", label: "EL AGUILA"},
  { value: "EL CAIRO", label: "EL CAIRO"},
  { value: "EL CERRITO", label: "EL CERRITO"},
  { value: "EL DOVIO", label: "EL DOVIO"},
  { value: "FLORIDA", label: "FLORIDA"},
  { value: "GINEBRA", label: "GINEBRA"},
  { value: "GUACARI", label: "GUACARI"},
  { value: "JAMUNDI", label: "JAMUNDI"},
  { value: "LA CUMBRE", label: "LA CUMBRE"},
  { value: "LA UNION", label: "LA UNION"},
  { value: "LA VICTORIA", label: "LA VICTORIA"},
  { value: "OBANDO", label: "OBANDO"},
  { value: "PALMIRA", label: "PALMIRA"},
  { value: "PRADERA", label: "PRADERA"},
  { value: "RESTREPO", label: "RESTREPO"},
  { value: "RIOFRIO", label: "RIOFRIO"},
  { value: "ROLDANILLO", label: "ROLDANILLO"},
  { value: "SAN PEDRO", label: "SAN PEDRO"},
  { value: "SEVILLA", label: "SEVILLA"},
  { value: "TORO", label: "TORO"},
  { value: "TRUJILLO", label: "TRUJILLO"},
  { value: "TULUA", label: "TULUA"},
  { value: "ULLOA", label: "ULLOA"},
  { value: "VERSALLES", label: "VERSALLES"},
  { value: "VIJES", label: "VIJES"},
  { value: "YOTOCO", label: "YOTOCO"},
  { value: "YUMBO", label: "YUMBO"},
  { value: "ZARZAL", label: "ZARZAL"},
  { value: "CARURU", label: "CARURU"},
  { value: "MITU", label: "MITU"},
  { value: "PACOA", label: "PACOA"},
  { value: "PAPUNAUA", label: "PAPUNAUA"},
  { value: "TARAIRA", label: "TARAIRA"},
  { value: "YAVARATE", label: "YAVARATE"},
  { value: "CUMARIBO", label: "CUMARIBO"},
  { value: "LA PRIMAVERA", label: "LA PRIMAVERA"},
  { value: "PUERTO CARRENO", label: "PUERTO CARRENO"},
  { value: "SANTA ROSALIA", label: "SANTA ROSALIA"},
];
