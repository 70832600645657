import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import _ from "lodash";

const CardColor = (tipo_parqueadero) => {

  switch (tipo_parqueadero) {
    case "COMUN":
      return "card-header-comun";
    case "DE_VISITANTES":
      return "card-header-visitantes";
    case "PRIVADA":
      return "card-header-privada";
    case "DISCAPACITADOS":
      return "card-header-discapacitados";
    case "BICICLETERO":
      return "card-header-bicicletero";
    default:
      return "";
  }
}

export const CardPlanoParqueaderos = ({ parking }) => {
  return (
    <Card style={{ width: '300px' }}>
      <Card.Header className={`${CardColor(parking.tipo_parqueadero)}`}>
        <div>{_.upperCase(parking.no_parqueadero)}</div>
      </Card.Header>
      <Card.Body className="p-2">
        <Card.Title>{parking.tipo_parqueadero}</Card.Title>
        <Card.Text>
          {parking.get_tipo_parqueadero_display}
          <ListGroup className="no-padding">
            {parking.vehiculos.map((vehiculo) => (
              <>
                <ListGroup.Item key={vehiculo.placa}>
                  Placa: {vehiculo.placa}
                </ListGroup.Item>
                <ListGroup.Item key={vehiculo.tipo_vehiculo}>
                  Vehiculo: {vehiculo.tipo_vehiculo}
                </ListGroup.Item>
              </>
            ))}
            { parking.visitante !== null && (
              <>
                <ListGroup.Item key={parking.visitante.vehiculo}>
                  Placa: {parking.visitante.vehiculo}
                </ListGroup.Item>
                <ListGroup.Item key={parking.visitante.visitante__nombre}>
                  Nombre: {parking.visitante.visitante__nombre}
                </ListGroup.Item>
                <ListGroup.Item key={parking.visitante.visitante__identificacion}>
                  Cedula: {parking.visitante.visitante__identificacion}
                </ListGroup.Item>
              </>
            ) || null }
            <ListGroup.Item className="no-padding">
              {parking.ocupado ? "Ocupado" : "Disponible"}
            </ListGroup.Item>
          </ListGroup>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
