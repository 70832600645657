/**
 * Genera el icono de WhatsApp para uso generalizado
 * @returns Retorna el icono de WhatsApp
 */
export const WhatsAppIcono = () => (
    <i class="bi bi-whatsapp"></i>
)

/**
 * Genera el icono de descarga para el uso generalizado
 * @returns Retorna el icono de descargar
 */
export const DescargarIcono = () => (
    <i className="fa fa-download"></i>
)

/**
 * Genera el icono para las notas o listado de uso generalizado
 * @returns Retorna el icono de un listado
 */
export const NotaIcono = () => (
    <i class="bi bi-card-list"></i>
)

/**
 * Genera el icono para el historico de uso generalizado
 * @returns Retorna el icono de tiempo como historico
 */
export const HistorialIcono = () => (
    <i class="bi bi-clock-history"></i>
)

/**
 * Genera el icono de bloqueo para uso generalizado
 * @returns Retorna el icono de bloqueo
 */

export const BloqueoIcono = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-ban" viewBox="0 0 16 16">
        <path d="M15 8a6.97 6.97 0 0 0-1.71-4.584l-9.874 9.875A7 7 0 0 0 15 8M2.71 12.584l9.874-9.875a7 7 0 0 0-9.874 9.874ZM16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0"/>
    </svg>
)

/**
 * Genera el icono de visualización para uso generalizado
 * @returns Retorna el icono de un ojo para visualziación
 */

export const OjoIcono = () => (
    <i className="fas fa-eye"></i>
)

/**
 * Genera el icono de descarga para uso generalizado
 * @returns Retorna el icono de descarga
 */

export const DescargaIcono = () => (
    <i className="bi bi-cloud-arrow-down-fill"></i>
)