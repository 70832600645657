import React, { useEffect, useState, useRef } from "react";
import Loading from "../components/loading";
import { BotonDangerText, BotonPrimaryText } from "../components/BotonesCustom";
import { Formik, Field } from "formik";
import { FieldBasic } from "../components/FieldBasic";
import { Modal } from "react-bootstrap";

const ModalNotaCredito = ({ showModal, onClose }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const initialValuesEmpty = {
    motivo: "",
    descripcion: "",
  };

  const [initialValues, setInitialValues] = useState(initialValuesEmpty);

  return (
    <Modal show={showModal} size="lg">
      {loadingForm ? (
        <Loading />
      ) : (
        <Formik enableReinitialize initialValues={initialValues}>
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            setSubmitting,
          }) => (
            <div role="document">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Nota credito
                  </h5>
                </div>

                <div className="modal-body">
                  <div className="row justify-content-center">
                    <div className="col-md">
                      <div className="p-2">
                        <div className="form-group pb-2">
                          <FieldBasic
                            name="Nota de crédito"
                            className="row"
                            required
                            errors={errors.texto}
                          >
                            <div className="col-sm-12 col-md-12 mb-3">
                              <label for="motivo" class="form-label">
                                Motivo: 
                              </label>
                              <Field
                                type="text"
                                name="motivo"
                                id="motivo"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Motivo"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.motivo}
                              />
                            </div>
                            <div className="col-sm-12 col-md-12">
                              <label for="descripcion" class="form-label">
                                Descripción: 
                              </label>
                              <Field
                                id="descripcion"
                                name="descripcion"
                                component="textarea"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Descripción"
                                value={values.descripcion}
                              />
                            </div>
                          </FieldBasic>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer">
                  {loadingSubmit ? (
                    <div className="text-center">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <BotonDangerText type="button" onClick={onClose}>
                        Cancelar
                      </BotonDangerText>
                      <BotonPrimaryText type="submit" disabled={isSubmitting}>
                        Guardar
                      </BotonPrimaryText>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default ModalNotaCredito;
