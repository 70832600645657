import React from 'react';

const CheckboxOnOff = ({ label, name, isChecked, onChange, ...props }) => {
  return (
    <div className="switch-container">
      <label className="switch">
        <input
          name={name}
          id={props.id}
          type="checkbox"
          checked={isChecked}
          onChange={onChange}
        />
        <span className="slider round"></span>
      </label>
      <span className="switch-label" for={name}  >{label}</span>
    </div>
  );
};

export default CheckboxOnOff;
