// UnidadesTable.js
import React, { useState, useEffect } from 'react';
import { useTable, useFilters, usePagination, useSortBy } from 'react-table';


const UnidadesTable = ({
  columns,
  data,
  fetchUrl,
  handleSortingChange,
  sortField,
  order,
  page,
  dataPaginated
}) => {

  const [pageNumber, setPageNumber] = useState('');
  const [firstUrl, setFirstUrl] = useState('');
  const [nextUrl, setNextUrl] = useState('');
  const [previousUrl, setPreviousUrl] = useState('');
  const [lastUrl, setLastUrl] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    setPageNumber(currentPage);
    setFirstUrl(dataPaginated.first_page	);
    setNextUrl(dataPaginated.next);
    setPreviousUrl(dataPaginated.previous);
    setLastUrl(dataPaginated.last_page);
    setCurrentPage(dataPaginated.current_page);
    setTotalPages(dataPaginated.total_pages);
    setTotalRecords(dataPaginated.count);
  }, [dataPaginated]);

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
  } = tableInstance;

  let typingTimer; // Variable para almacenar el temporizador

  const handleChange = (e) => {
    const value = e.target.value;
    setPageNumber(value);

    clearTimeout(typingTimer); // Limpiar el temporizador existente

    // Configurar un nuevo temporizador para esperar 500ms después de que el usuario deje de escribir
    typingTimer = setTimeout(() => {
      // Llamar a la función fetchUrl después del retraso
      if (/^\d+$/.test(value)) {
        const pageNumber = parseInt(value, 10);
        fetchUrl(pageNumber);
      }
    }, 700);
  };

  return (
    <>
      <div className="filter ">

        <div className="table-responsive dataTables_wrapper">
          <table {...getTableProps()} className="table display">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr
                  {...headerGroup.getHeaderGroupProps()}
                >
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps()}
                      onClick={() => (column.disableSortable ? null : handleSortingChange(column))}
                      style={{
                          cursor: column.disableSortable ? 'auto' : 'pointer'
                        }}
                    >
                      {column.render('Header')}
                      {column.canFilter ? column.render('Filter') : null}
                      {column.canSort && (
                        <span>
                          {column.disableSortable ? null : column.id === sortField ? (order === 'asc' ? <i className="fa fa-arrow-down" /> : <i className="fa fa-arrow-up" />) : ' ↕'}
                        </span>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()} className="">
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return <td {...cell.getCellProps()}> {cell.render('Cell')} </td>;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          <div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
            <div className="dataTables_info">
              Pagina {currentPage} de {totalPages} total registros {totalRecords}
            </div>

            {(totalPages > 1) && (
              <>
                <span className="table-index dataTables_info">
                  Ir a pagina
                  <input
                    type="text"
                    className="dataTables_length form-control form-control-sm d-inline-block mx-2 border-0 text-center w-25"
                    defaultValue={currentPage}
                    onChange={handleChange}
                    onKeyDown={(e) => {
                      // Obtener el código de la tecla presionada
                      const keyCode = e.keyCode || e.which;

                      // Permitir solo teclas numéricas (códigos de teclas del 48 al 57 son números)
                      if (keyCode < 48 || keyCode > 57) {
                        e.preventDefault(); // Evitar la pulsación de teclas que no son números
                      }
                    }}
                  />
                </span>

                <div
                  className="dataTables_paginate paging_simple_numbers"
                >
                  <button
                    className="paginate_button previous disabled transparent-button"
                    onClick={() => fetchUrl({url: firstUrl})}
                    disabled={currentPage === 1}
                    title='Primera'
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </button>

                  <button
                    className="paginate_button previous transparent-button"
                    onClick={() => fetchUrl({url: previousUrl})}
                    disabled={!previousUrl}
                    title='Anterior'
                  >
                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                  </button>

                  <button
                    className="paginate_button next transparent-button"
                    onClick={() => fetchUrl({url: nextUrl})}
                    disabled={!nextUrl}
                    title='Siguiente'
                  >
                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                  </button>

                  <button
                    className="paginate_button next transparent-button"
                    onClick={() => fetchUrl({url: lastUrl})}
                    disabled={totalPages === currentPage}
                    title='Ultima'
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </button>

                </div>
              </>
            )}
          </div>

        </div>

      </div>
    </>
  );
};

export default UnidadesTable;
