import React, { useState, useEffect } from 'react';
import axiosInstance from 'services/AxiosInstance';
import { handleErrors } from 'jsx/components/CitoPlus/components/ErrorHandlingComponent';
import pse_logo from 'images/pse_logo.png';

export const CargarSaldoSMS = () => {
  const [bancos, setBancos] = useState([]);
  const [datosRecarga, setDatosRecarga] = useState({ valor: 0 });  // Arreglado el estado
  const [formData, setFormData] = useState({
    bancoId: '',
    bancoNombre: '',
    tipo_persona: '',
  });
  const [error, setError] = useState(''); // Estado para almacenar errores
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    // Obtener la lista de bancos desde la API
    axiosInstance.get('/api/v3/pagos/pse-lista-bancos/')
      .then(response => {
        setBancos(response.data);
      })
      .catch(error => {
        handleErrors(error);
      });
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const msg = params.get('msg');
    if (msg) {
      setMessage(decodeURIComponent(msg));
    }
  }, []);

  useEffect(() => {
    // Obtener el valor de recarga desde la API
    axiosInstance.get('/api/v3/pagos/sms-valor-recarga/')
      .then(response => {
        setDatosRecarga(response.data);  // Se espera que response.data contenga el objeto { valor: ... }
      })
      .catch(error => {
        handleErrors(error);
      });
  }, []);

  const handleChangeBanco = (event) => {
    const bancoId = event.target.value;
    const bancoNombre = event.target.options[event.target.selectedIndex].text;
    setFormData({
      ...formData,
      bancoId,
      bancoNombre,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!formData.bancoId || !formData.tipo_persona) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    // Datos a enviar al backend
    const data = {
      bancoId: formData.bancoId,
      bancoNombre: formData.bancoNombre,
      tipo_persona: formData.tipo_persona,
      valor: datosRecarga.valor,  // Asegurarse de que `datosRecarga.valor` esté disponible
    };

    axiosInstance.post('/api/v3/pagos/pse-pago-recarga-sms/', data)
      .then(response => {
        // si tienen redirect_url
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        try {
          setError(error.response.data.message);
        } catch (e) {
          handleErrors(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <div className="d-flex align-items-center mb-4 justify-content-center">
        <h3>
          <img src={pse_logo} alt="PSE" className="mr-2" style={{ width: '20%' }} />
          Cargar saldo SMS
        </h3>
      </div>
      <div className="d-flex align-items-center mb-4 justify-content-center">
        {message && (
          <div className="alert alert-info" role="alert">
            {message}
          </div>
        )}
      </div>
      <div className="row justify-content-md-center">
        <div className="col-md-10">
          <div className="card">
            <div className="card-body">
              {/* Mostrar la alerta en caso de error */}
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <form onSubmit={handleSubmit}>
                <div className="row">
                  {/* Columna izquierda: Formulario */}
                  <div className="col-md-6">
                    <div className="form-group">
                      <label htmlFor="banco">Banco</label>
                      <select
                        className="form-select"
                        aria-label="Lista de Bancos"
                        id="banco"
                        name="banco"
                        value={formData.bancoId}
                        onChange={handleChangeBanco}
                        required
                      >
                        <option value="">Seleccione un banco</option>
                        {bancos.map((banco) => (
                          <option key={banco.codigoBanco} value={banco.codigoBanco}>
                            {banco.nombreBanco}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="form-group mt-3">
                      <label htmlFor="tipo_persona">Tipo de Persona</label>
                      <select
                        className="form-select"
                        aria-label="Tipo de Persona"
                        id="tipo_persona"
                        name="tipo_persona"
                        value={formData.tipo_persona}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Seleccione un tipo de persona</option>
                        <option value="0">Natural</option>
                        <option value="1">Jurídica</option>
                      </select>
                    </div>
                    {loading ? (
                      <button type="submit" className="btn btn-primary mt-4" disabled>
                        Cargando...
                      </button>
                    ) : (
                      <button type="submit" className="btn btn-primary mt-4">
                        Cargar saldo
                      </button>
                    )}
                  </div>

                  {/* Columna derecha: Detalle */}
                  <div className="col-md-6">
                    <div className="alert alert-info mt-3" role="alert">
                      <h5>Detalle de la transacción</h5>
                      <p>Total a recargar: $ {datosRecarga.total}</p>
                      <p>Valor: $ {datosRecarga.valor}</p>
                      <p>Impuesto: $ {datosRecarga.impuesto}</p>
                      <p>Detalle: Recarga SMS</p>
                    </div>
                    <div className="alert alert-warning" role="alert">
                      <p>Recuerda verificar tu banco antes de proceder.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CargarSaldoSMS;
