import React, { useMemo, useEffect, useState, useCallback } from 'react';
import CitoPlusSmallModal from '../../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../../services/DefaultParamets';
import Tables from '../../components/tables';
import SearchTextWithDateRange from '../../components/SearchWithDateRange';
import FiltroGrabacionLlamadas from './FilterList';
import { notifyError } from '../../components/CitoPlusToastr';
import { BotonPrimary } from '../../components/BotonesCustom';
import AudioPlayer from '../../components/CPAudioPlayer';
import Swal from "sweetalert2";

export const GrabacionesLlamadas = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState({});
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState(null);
  const [previousUrl, setPreviousUrl] = useState(null);

  const URL = '/api/v3/cdr/grabacion_llamadas/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams());

  const columns = useMemo(() => [
    /*{
      id: 'id',
      Header: 'ID',
      disableFilters: true,
      accessor: 'id'
    },*/
    {
      id: 'calldate',
      Header: 'Fecha',
      disableFilters: true,
      accessor: 'calldate'
    },
    {
      id: 'llamada',
      Header: 'Llamada',
      disableFilters: true,
      accessor: row => (
        <>
          <div className="call-info-container d-flex justify-content-start">
            <div className="call-info" title="Fuente">
              <i className={`fas ${getIcon(row.src)} call-icon`}></i>
              <span className="call-details">{row.src}</span>
            </div>
            <div className="arrow" title='Duración de la llamada'>
              <i className="fas fa-arrow-right"></i>
              <span className="call-duration">{row.billsec} seg</span>
            </div>
            <div className="call-info" title="Destino">
              <i className={`fas ${getIcon(row.dst)} call-icon`}></i>
              <span className="call-details">{row.dst}</span>
            </div>
          </div>
        </>
      )
    },
    {
      id: 'action',
      Header: "Grabación",
      disableFilters: true,
      disableSortable: true,
      accessor: row => row.grabacion && <AudioPlayer url={row.grabacion} />
    }
    // ... Agrega las demás columnas según tus necesidades
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedFetchUrl = useCallback(
    debounce((pageIndex, search, ordering) => {
      fetchUrl(pageIndex, search, ordering);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async (pageIndex = 1, search = searchValue, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...search,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      console.log(`NEXT: ${response.data.next}`);
      console.log(`DATA: ${response.data}`);
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedFetchUrl(1, value, [`${order === 'desc' ? '-' : ''}${sortField}`]);
  };

  const handleEnterPress = () => {
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange({});
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const getIcon = (number) => {
    // Verificamos si el número tiene tres dígitos y es uno de los números especiales
    if (number.length === 3) {
      if (number === "100" || number === "101" || number === "102") {
        return "fa-shield-alt"; // Ícono de vigilante
      } else {
        return "fa-user-tie"; // Ícono de residente
      }
    }
    return "fa-phone"; // Ícono predeterminado si no es un número de tres dígitos
  }

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Grabación de Llamadas</h4>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <FiltroGrabacionLlamadas
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              showDatePicker={true}
              isClearable
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
              totalRecords={totalRecords}
            />
          )}
        </div>
      </div>

      <div>
        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>
    </>
  );
};

export default GrabacionesLlamadas;
