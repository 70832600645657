import React, { Fragment, useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { FieldBasic } from "../components/FieldBasic";

const ConfiguracionFacturacion = () => {
  const initialValues = {
    razon_social: "",
    resolucion: "",
    consecutivo_factura: "",
  };

  return (
    <>
      <Fragment>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <h4 className="card-title">Parámetros F.F.E.E.</h4>
              </div>
              <div className="card-body">
                <div className="basic-form">
                  <Formik initialValues={initialValues}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      resetForm,
                      setSubmitting,
                    }) => (
                      <div role="document">
                        <form
                          className="form-valide"
                          encType="multipart/form-data"
                        >
                          <FieldBasic
                            name="Nota de crédito"
                            className="row"
                            required
                          >
                            <div className="col-sm-12 col-md-12 mb-2">
                              <label for="razon_social" class="form-label">
                                Razón social:
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="text"
                                name="razon_social"
                                id="razon_social"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Motivo"
                                value={values.razon_social}
                              />
                            </div>
                            <div className="col-sm-12 col-md-12 mb-2">
                              <label for="resolucion" class="form-label">
                                Resolución:
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="number"
                                name="resolucion"
                                id="resolucion"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Motivo"
                                value={values.resolucion}
                              />
                            </div>
                            <div className="col-sm-12 col-md-12 mb-3">
                              <label
                                for="consecutivo_factura"
                                class="form-label"
                              >
                                Consecutivo factura:
                                <span className="text-danger">*</span>
                              </label>
                              <Field
                                type="number"
                                name="consecutivo_factura"
                                id="consecutivo_factura"
                                autoComplete="off"
                                className="form-control"
                                placeholder="Motivo"
                                value={values.consecutivo_factura}
                              />
                            </div>
                          </FieldBasic>
                          <div className="card-footer text-end">
                            <button
                              type="submit"
                              className={`btn me-2 btn-citoplus-primary`}
                              disabled={isSubmitting}
                            >
                              Cambiar
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </>
  );
};

export default ConfiguracionFacturacion;
