import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PageTitle from "../../../../layouts/PageTitle";
import Loading from "../../components/loading";
import axiosInstance from "../../../../../services/AxiosInstance";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import * as Yup from "yup";
import { Formik } from "formik";
import { useNavigate } from 'react-router-dom';
import CustomClearIndicator from "../../components/MultiSelect";
import { handleErrors } from "../../components/ErrorHandlingComponent";
import { notifySuccess } from "../../components/CitoPlusToastr";

const formDocumentosSchema = Yup.object().shape({
  titulo: Yup.string().required("El título es obligatorio"),
  archivo: Yup.mixed().required("El archivo es obligatorio"),
  grupos: Yup.array().of(
    Yup.object().shape({
      slug: Yup.string().required(),
      nombre: Yup.string().required(),
    })
  ),
});

const getFileNameFromUrl = (url) => {
  const pathname = new URL(url).pathname;
  return pathname.substring(pathname.lastIndexOf('/') + 1);
};

export const FormDocumentos = () => {
  const initialValuesEmpty = {
    archivo: null,
    titulo: "",
    grupos: [],
  };

  const URL_GRUPOS = '/api/v3/usuarios/grupos_copropiedades/dropdown/';
  const { slug } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [gruposOptions, setGruposOptions] = useState([]);
  const [file, setFile] = useState(null);

  useEffect(() => {
    const fetchGrupOptions = async () => {
      try {
        const response = await axiosInstance.get(URL_GRUPOS);
        const data = await response.data;
        setGruposOptions(data);
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDocument = async () => {
      if (slug) {
        try {
          const response = await axiosInstance.get(`/api/v3/usuarios/documentos/${slug}/`);
          const data = await response.data;
          setInitialValues({
            archivo: null,
            titulo: data.titulo,
            grupos: data.grupo.map(grupo => ({ slug: grupo.slug, nombre: grupo.nombre })),
          });
          setFile(data.file);
        } catch (error) {
          handleErrors(error);
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchGrupOptions();
    fetchDocument();
  }, [slug]);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const formData = new FormData();
      if (values.archivo) {
        formData.append('file', values.archivo);
      }
      formData.append('titulo', values.titulo);
      formData.append(
        'grupos_slugs',
        JSON.stringify(values.grupos.map(grupo => ({ slug: grupo.slug, name: grupo.nombre })))
      );

      if (slug) {
        await axiosInstance.put(`/api/v3/usuarios/documentos/${slug}/`, formData);
        notifySuccess("Documento actualizado correctamente");
      } else {
        await axiosInstance.post('/api/v3/usuarios/documentos/', formData);
        notifySuccess("Documento cargado correctamente");
      }

      navigate('/documentos');
    } catch (error) {
      handleErrors(error, setSubmitting);
    } finally {
      setSubmitting(false);
    }
  };

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
  };

  const handleRemoveFile = (setFieldValue) => {
    setFile(null);
    setFieldValue('archivo', null);
  };

  const handleDelete = async () => {
    swal({
      title: "¿Estás seguro?",
      text: "Una vez eliminado, no podrás recuperar este documento.",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          await axiosInstance.delete(`/api/v3/usuarios/documentos/${slug}/`);
          notifySuccess("Documento eliminado correctamente");
          navigate('/documentos');
        } catch (error) {
          handleErrors(error);
        }
      }
    });
  };

  if (loading) {
    return (
      <>
        <PageTitle activeMenu={slug ? "Editar Documento" : "Agregar Documento"} motherMenu={"Gestion ADM"} />
        <div className="d-flex aling-items-center mb-4 flex-wrap">
          <h4 className="f-20 font-w600 me-auto">{slug ? "Editar Documento" : "Agregar Documento"}</h4>
        </div>
        <div className="card">
          <div className="card-body">
            <Loading />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="f-20 font-w600 me-auto">{slug ? "Editar Documento" : "Agregar Documento"}</h4>
      </div>
      <div className="card">
        <div className="card-body">
          <Formik
            initialValues={initialValues}
            validationSchema={formDocumentosSchema}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              setFieldTouched
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="titulo">
                      <label>Título </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      <input
                        type="text"
                        name="titulo"
                        id="titulo"
                        className="form-control"
                        value={values.titulo}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    <div
                      id="val-titulo-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.titulo}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="grupo">
                      <label>Grupo </label>
                    </div>
                    <div className="col-lg-7">
                      <CustomClearIndicator
                        id="grupos"
                        name="grupos"
                        options={gruposOptions}
                        isMulti={true}
                        onChange={(selectOptions) => {
                          setFieldValue("grupos", selectOptions.map((option) => {
                            let { value, label } = option;
                            return {
                              slug: value,
                              nombre: label
                            }
                          }));
                        }}
                        defaultsValues={values.grupos}
                      />
                    </div>
                    <div
                      id="val-grupos-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.grupos}
                    </div>
                  </div>

                  <div className="form-group mb-3 row">
                    <div className="col-lg-4 col-form-label" htmlFor="archivo">
                      <label>Archivo </label>
                      <span className="text-danger"> * </span>
                    </div>
                    <div className="col-lg-7">
                      {file && (
                        <div>
                          <span>{typeof file === 'string' ? getFileNameFromUrl(file) : file.name}</span>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              setFile(null);
                              setFieldValue('archivo', null);
                              const inputFile = document.getElementById('archivo-input');
                              if (inputFile) {
                                inputFile.value = '';
                              }
                            }}
                            className="btn"
                          >
                            <i className="fa fa-times"></i>
                          </button>
                        </div>
                      )}
                      <input
                        id='archivo-input'
                        name="archivo"
                        type="file"
                        className="form-control"
                        onChange={(event) => {
                          const selectedFile = event.target.files[0];
                          setFile(selectedFile);
                          setFieldValue('archivo', selectedFile);
                        }}
                        onBlur={handleBlur}
                      />
                      <div
                        id="val-archivo-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.archivo}
                      </div>
                    </div>
                  </div>

                  {loadingBtn ? (
                    <Loading />
                  ) : (
                    <div className="">
                      <Link to='/documentos' className="btn btn-citoplus-danger btn-sm me-3">
                        Cancelar
                      </Link>
                      <button
                        type="submit"
                        className="btn btn-citoplus-success btn-sm me-3"
                        disabled={isSubmitting}>
                        {slug ? "Actualizar" : "Agregar"}
                      </button>
                      {slug && (
                        <button
                          type="button"
                          className="btn btn-citoplus-danger btn-sm"
                          onClick={handleDelete}
                        >
                          Eliminar
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default FormDocumentos;
