import React, { useMemo, useEffect, useState, useCallback } from 'react';
import PageTitle from "../../../layouts/PageTitle";
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import SearchInput from '../components/input_search';
import { Link } from 'react-router-dom';

import { notifyError } from '../components/CitoPlusToastr';

export const EnvioMasivoSMS = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const URL = '/api/v3/mensajeria/mensajeria_sms/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  const columns = useMemo(() => [
    {
      id: 'nombre',
      Header: 'Titulo ',
      disableFilters: true,
      disableSortable: false,
      accessor: (row) => {
        return (
          <div className="align-left">
            <p className="paragraph">{row.mensaje}</p>
            {row.programacion_envio ? (
              <p className="paragraph">Programado: {row.programacion_envio}</p>
            ) : null}
          </div>
        )
      }
    },
    {
      id: 'todos',
      Header: 'Todos ',
      accessor: 'todos',
      disableFilters: true,
      disableSortable: false,
      Cell: ({ value }) => (
        <div className="align-right">
          {value ? <i className="fa fa-user-check" style={{ color: 'green' }} /> : <i className="fa fa-user-xmark" style={{ color: 'red' }} />}
        </div>
      ),
    },
    {
      Header: 'Detalles ',
      accessor: 'id',
      Cell: ({ row }) => (
        <div className='left-align-cell'>
          <p className="paragraph">Fallidos: {row.original.fallidos}</p>
          <p className="paragraph">Total: {row.original.total}</p>
        </div>
      ),
      disableFilters: true,
      disableSortable: true
    },
    /*{
      id: 'grupo',
      Header: 'Grupo',
      accessor: (row) => {
        // Verifica si 'grupo' es un array y tiene al menos un elemento
        if (Array.isArray(row.grupo) && row.grupo.length > 0) {
          // Mapea los nombres de los grupos y devuelve una lista
          const groupNames = row.grupo.map((group) => group.nombre);
          return <ul>{groupNames.map((name, index) => <li key={index}>{index + 1}: {name}</li>)}</ul>;
        } else {
          return '';
        }
      },
      disableFilters: true,
      disableSortable: false
    },*/
    {
      id: 'obs_costos',
      Header: 'Costos',
      accessor: 'obs_costos',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Costo</div>
      ),
      Cell: ({ value }) => (
        <div className='left-align-cell'>
          {value && value.length > 0 ? (
            value.split(',').map((observation, index) => (
              <p key={index} className="paragraph">{observation.trim()}</p>
            ))
          ) : ''}
        </div>
      ),
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;

    if (searchValue !== '') {
      defaultParams.search = searchValue;
      search = searchValue;
    } else {
      defaultParams.search = search;
    }

    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">SMS</h4>
        <Link
          to={'/cargar-saldo-sms/'}
          className="btn btn-citoplus-primary me-3 btn-sm"
        >
          <i className="fa fa-credit-card me-2"></i>
          Cargar Saldo
        </Link>
        <Link
          to={'/crear-sms/'}
          className="btn btn-citoplus-primary me-3 btn-sm"
        >
          <i className="fa fa-plus me-2"></i>
          Crear SMS
        </Link>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              value={searchValue}
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default EnvioMasivoSMS;
