import React, { useState, useEffect, useMemo, useRef } from "react";
import { useTable, useFilters, usePagination } from "react-table";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import axiosInstance from "services/AxiosInstance";
import Tables from "jsx/components/CitoPlus/components/Tables";
import { ButtonGroup } from "react-bootstrap";
import {
  CitoPlusLinkIconPrimary,
  CitoPlusLinkIconDanger,
} from "jsx/components/CitoPlus/components/CitoPlusLink";
import {
  OjoIcono,
  DescargaIcono,
} from "jsx/components/CitoPlus/components/Icons/BootstrapIconos";
import html2pdf from "html2pdf.js";
import { Button, Modal } from 'react-bootstrap';

export const Contratos = () => {
  const URL = `/api/v3/usuarios/contratos/`;
  const URL_CONTRATO = `/api/v3/usuarios/contratos/`;

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");
  const [dataPDF, setDataPDF] = useState("");

  const [showDocumentModal, setShowDocumentModal] = useState(false);

  const [dataPaginated, setDataPaginated] = useState({
    current_page: "",
    total_pages: "",
    next: "",
    previous: "",
    first_page: "",
    last_page: "",
    count: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadContrato, setLoadContrato] = useState(false);

  const columns = useMemo(
    () => [
      {
        id: "created_at",
        Header: "Creado",
        disableFilters: true,
        disableSortable: false,
        accessor: "created_at",
      },
      {
        Header: "Acciones",
        disableFilters: true,
        disableSortable: true,
        accessor: (row) => {
          return (
            <>
              <div className="form-group">
                <ButtonGroup aria-label="Botones de acciones visitantes">
                  <CitoPlusLinkIconDanger
                    title="Ver facturación"
                    onClick={() => openDocumentModal(row.slug, 2)}  
                  >
                    <OjoIcono />
                  </CitoPlusLinkIconDanger>
                  <CitoPlusLinkIconPrimary
                    title="Descargar contrato"
                    onClick={() => {
                      descargarContrato(row.slug, 1);
                    }}
                  >
                    <DescargaIcono />
                  </CitoPlusLinkIconPrimary>
                </ButtonGroup>
              </div>
            </>
          );
        },
      },
    ],
    []
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    usePagination
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);

  const fetchUrl = async ({
    pageIndex = 1,
    search = "",
    ordering = [],
    url = "",
  }) => {
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(URL, {
        params: {
          page: pageIndex,
          ordering: ordering,
        },
      });

      setDataPaginated({
        ...(({
          current_page,
          total_pages,
          next,
          previous,
          first_page,
          last_page,
          count,
        }) => ({
          current_page,
          total_pages,
          next,
          previous,
          first_page,
          last_page,
          count,
        }))(response.data),
      });

      setTotalPages(response.data.total_pages);
      setData(response.data.results);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder =
      sortField === id ? (order === "asc" ? "desc" : "asc") : "asc";

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl({
      pageIndex: 1,
      search: "",
      ordering: `${newOrder === "desc" ? "-" : ""}${id}`,
    });
  };

  const openDocumentModal = (slug, tipo) => {
    descargarContrato(slug, tipo)
    setShowDocumentModal(true);
  };

  const closeDocumentModal = () => {
    setShowDocumentModal(false);
  };

  const descargarContrato = async (slug, tipo) => {
    setLoadContrato(true);
    try {
      const response = await axiosInstance.get(
        `${URL_CONTRATO}${slug}/contrato/`
      );

      let data = response.data;

      if(tipo == 1){

        const iframe = document.createElement('iframe');
        iframe.style.position = 'absolute';
        iframe.style.left = '-9999px'; // Fuera de la vista
        document.body.appendChild(iframe);

        const iframeDoc = iframe.contentWindow.document;
        iframeDoc.open();
        iframeDoc.write(data);
        iframeDoc.close();

        iframe.onload = () => {
          const options = {
            margin: 10,
            filename: 'documento-completo.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
          };

          html2pdf().from(iframe.contentWindow.document.body).set(options).save().finally(() => {
            document.body.removeChild(iframe);
          });
        };
      }else{
        setDataPDF(data)
      }
    } catch (error) {
      handleErrors(error);
    } finally{
      setLoadContrato(false);
    }
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  return (
    <>
      <div className="d-flex aling-items-center mb-4 flex-wrap">
        <h4 className="fs-20 mb-0 me-auto">Contratos</h4>
      </div>
      <div className="card">
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              setLoading={setLoading}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              sortField={sortField}
              order={order}
              page={page}
              dataPaginated={dataPaginated}
            />
          )}
        </div>
      </div>

      <Modal show={showDocumentModal} onHide={closeDocumentModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Visualización del contrato.</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex justify-content-center">
            {loadContrato ? (
              <p>Cargando...</p>
            ) : (
                <iframe 
                title="Contrato"
                srcDoc={dataPDF}
                width="100%" 
                height="600px"
              />
            )}            
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeDocumentModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Contratos;
