import React, { useMemo, useEffect, useState, useCallback } from 'react';
import CitoPlusSmallModal from '../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../services/DefaultParamets';
import Tables from '../components/tables';
import TruncateText from '../components/truncateText';
import { CitoPlusLinkIconPrimary } from '../components/CitoPlusLink';
import Comentarios from '../components/sidebar_comments';
import SearchWithDateRange from '../components/SearchWithDateRange';
import { handleErrors } from '../components/ErrorHandlingComponent';

import noImagen from '../../../../images/no-image.jpg';

export const Minuta = () => {

  const URL = '/api/v3/vigilancia/minuta-administrador/';

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const titleCard = "Minuta"
  const activeMenu = "Seguridad"
  const motherMenu = "Minuta"

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Comentarios
  const [selectedComentarios, setSelectedComentarios] = useState([]);
  const [selectedPrePostData, setSelectedPrePostData] = useState({});
  const [showSidebarComments, setShowSidebarComments] = useState(false);
  const [urlComentarios, setUrlComentarios] = useState('');
  const [urlComentariosParams, setUrlComentariosParams] = useState({});

  const handleComentariosClick = (value, slug) => {
    const URL_NOTAS = `/api/v3/vigilancia/minuta/${slug}/comentarios/`
    setUrlComentarios(URL_NOTAS);

    setSelectedPrePostData({
      minuta: slug
    });

    setUrlComentariosParams({
      page_size: 100
    })
    setSelectedComentarios(value);
    setShowSidebarComments(true);
  }
  const handleNewComment = (e) => {
    // Recargar la lista despues del comentario
    fetchUrl(1, searchValue, '');
  }
  // Fin Comentarios

  const columns = useMemo(() => [
    {
      id: 'foto',
      Header: 'foto',
      accessor: 'foto',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Foto</div>
      ),
      Cell: ({ value }) => (
        value === null ? (
          <img
            src={noImagen}
            alt=""
            className="me-3 rounded"
            width={75}
          />
        ) : (
          <div className='image-container me-3 rounded'>
            <a href={value} data-lightbox="image">
              <img
                src={value}
                alt=""
                className="me-3 rounded"
                width={75}
                onError={e => {
                  e.target.onerror = null;
                  e.target.src = noImagen;
                }}
              />
            </a>
          </div>
        )
      ),
    },
    {
      id: "vigilante",
      Header: "Vigilante",
      disableFilters: true,
      disableSortable: true,
      accessor: row => {
        return (
          <div className="pt-4 left-align-cell">
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Vigilante:</h5>
              <p className='paragraph'>{row.vigilante_full_name}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Fecha:</h5>
              <TruncateText text={row.created_at} maxLength={50} className='mb-0' />
            </div>
          </div>
        );
      },
    },
    {
      id: 'detalles',
      accessor: 'detalles',
      disableFilters: true,
      disableSortable: true,
      Header: () => (
        <div className="left-align-header">Detalles</div>
      ),
      accessor: row => {
        return (
          <div
            className="pt-4 left-align-cell"
            style={{
              borderLeft: `5px solid ${row.prioridad.replace(/-/g, '')}`,
              paddingLeft: '8px',
            }}
          >
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Consecutivo:</h5>
              <p className='paragraph'>{row.consecutivo}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Asunto:</h5>
              <p className='paragraph'>{row.asunto}</p>
            </div>
            <div className="mb-1 d-flex">
              <h5 className="mb-1 fs-14 custom-label">Minuta:</h5>
              <TruncateText text={row.nota} maxLength={50} className='mb-0' />
            </div>
          </div>
        );
      },
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <div className="">
          <CitoPlusLinkIconPrimary
            href="#"
            className="position-relative"
            onClick={() => {
              handleComentariosClick(row.comentarios, row.slug)
            }}
          >
            <i className="fas fa-comments"></i>
            {row.total_comentarios > 0 && <span className="badge bg-light rounded-circle position-absolute top-0 start-100 translate-middle">{row.total_comentarios}</span>}
          </CitoPlusLinkIconPrimary>
        </div>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, filterParams = {}, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        setSmallModalTitle('Error de Página');
        setSmallModalBody('Error de Página');
        setSmallShowModal(true);
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const handleOpenModal = () => {
    setSmallShowModal(true);
  };

  const handleCloseModal = () => {
    setSmallShowModal(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className='card-title'>Minutas</h4>
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchWithDateRange
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
              showDatePicker={true}
              placeholder={'Buscar...'}
              placeholderText={'Fecha...'}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <div>

        <Comentarios
          showSidebarComments={showSidebarComments}
          setShowSidebarComments={setShowSidebarComments}
          // comentarios={selectedComentarios}
          urlComentarios={urlComentarios}
          urlComentariosParams={urlComentariosParams}
          urlPost={urlComentarios}
          selectedPrePostData={selectedPrePostData}
          handleNewComment={handleNewComment}
        />


        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onHide={handleCloseModal}
          // onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default Minuta;
