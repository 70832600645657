import React, { useMemo, useEffect, useState, useCallback } from 'react';
import CitoPlusSmallModal from '../../components/small_modal';
import { useTable, useFilters, usePagination } from 'react-table';
import '../../../table/FilteringTable/filtering.css';
import axiosInstance from '../../../../../services/AxiosInstance';
import { getDefaultParams } from '../../../../../services/DefaultParamets';
import Tables from '../../components/tables';
import SearchInput from '../../components/input_search';
import AddEditGruposModal from './AddEditGrupos';
import { notifyError, notifySuccess } from '../../components/CitoPlusToastr';

import { LinkPrimary } from '../../components/CitoPlusLink';
import { BotonPrimary, BotonDanger, BotonInfoText } from '../../components/BotonesCustom';

import ImportCSVModal from './GruposImportar';


import Swal from "sweetalert2";

export const GrupoCopropiedad = () => {

  const [smallModalTitle, setSmallModalTitle] = useState('');
  const [smallModalBody, setSmallModalBody] = useState('');
  const [smallShowModal, setSmallShowModal] = useState(false);

  const [importShowModal, setImportShowModal] = useState(false);

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [formAddEditGrupo, setformAddEditGrupo] = useState(false);

  const URL = '/api/v3/usuarios/grupos_copropiedades/';

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);
  const [selectedGrupoCopropiedadId, setSelectedGrupoCopropiedadId] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const columns = useMemo(() => [
    {
      id: 'nombre',
      Header: 'Nombre',
      disableFilters: true,
      accessor: (row) => {
        return (
          <>
            <div className='left-align-cell'>{row.nombre}</div>
            <div className='left-align-cell'>Total Viviendas: {row.total_viviendas}</div>
          </>
        )
      }
    },
    { id: 'desc', Header: 'Descripción', accessor: 'desc', disableFilters: true },
    {
      id: 'visible_porteria',
      Header: 'Visible por Portería',
      accessor: 'visible_porteria',
      disableFilters: true,
      Cell: ({ value }) => (
        <div className='text-center'>
          {value ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}
        </div>
      )
    },
    {
      id: 'estado',
      Header: 'Estado',
      accessor: 'estado',
      disableFilters: true,
      Cell: ({ value }) => (
        <div className='text-center'>
          {value ? <i className="fas fa-check"></i> : <i className="fas fa-times"></i>}
        </div>
      )
    },
    {
      id: 'action',
      Header: 'Acciones',
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => {
        return (

          <>
            <BotonPrimary
              onClick={() => {
                setEditMode(true);
                setSelectedGrupoCopropiedadId(row.slug);
                setformAddEditGrupo(true);
              }}
            >
              <i className="fas fa-pencil-alt"></i>
            </BotonPrimary>
            <BotonDanger
              onClick={() => {
                Swal.fire({
                  title: `¿Estás seguro de eliminar este grupo? ${row.nombre} con total de viviendas ${row.total_viviendas}`,
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonText: 'Si, Eliminar',
                  confirmButtonColor: '#ff5e17',
                  cancelButtonText: 'No, Cancelar',
                  cancelButtonColor: '#0f2242',
                  reverseButtons: true,
                }).then(willDelete => {
                  if (willDelete.isConfirmed) {
                    setLoading(true);
                    try {
                      axiosInstance.delete(`${URL}${row.slug}/`)
                        .then(response => {
                          if (response.status === 204) {
                            notifySuccess(`Grupo eliminado ${row.nombre}`);
                            fetchUrl(1, searchValue);
                          }
                        })
                    } catch (error) {
                      console.error("Error fetching data:", error);
                    } finally {
                      setLoading(false);
                    }
                  }
                })
              }}
            >
              <i className="fas fa-trash-alt"></i>
            </BotonDanger>
          </>
        )
      }
    }
    // ... Agrega las demás columnas según tus necesidades
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, search = '', ordering = []) => {
    defaultParams.page = pageIndex;
    defaultParams.search = search;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        notifyError('Página no encontrada');
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            page: pageIndex,
            search: search,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setTotalRecords(response.data.count);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error.response && error.response.status === 404) {
        setSmallModalTitle('Título del Modal');
        setSmallModalBody('Contenido del Modal');
        setSmallShowModal(true);
        console.log("Página no encontrada");
      } else {
        console.error('Error fetching data:', error);
      };
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setSearchValue(value);
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap me-2'>
        <h4 className="fs-20 font-w600 me-auto">Grupos</h4>
        <BotonInfoText
          onClick={() => setImportShowModal(true)}
        >
          <i className='fas fa-file-import me-2'></i>
          Importar CSV
        </BotonInfoText>
        <LinkPrimary
          to={"#"}
          onClick={() => setformAddEditGrupo(true)}
        >
          <i className="fas fa-plus me-2"></i>
          Agregar Grupo
        </LinkPrimary>
        <AddEditGruposModal
          showModal={formAddEditGrupo}
          onClose={() => {
            setformAddEditGrupo(false)
            setEditMode(false)
            setSelectedGrupoCopropiedadId(null)
          }}
          editMode={editMode}
          updateGruposList={fetchUrl}
          selectedGrupoCopropiedadId={selectedGrupoCopropiedadId}
        />
      </div>
      <div className="card">
        <div className="card-header">
          <div className="card-tools">
            <SearchInput
              onSearchChange={handleSearchChange}
              onEnterPress={handleEnterPress}
              onClearClick={handleClearClick}
            />
          </div>
        </div>
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
              totalRecords={totalRecords}
            />
          )}
        </div>
      </div>

      <div>
        {/* Resto del contenido del componente */}

        <ImportCSVModal
          show={importShowModal}
          onClose={() => setImportShowModal(false)}
        />

        {smallShowModal && (
          <CitoPlusSmallModal
            title={smallModalTitle}
            body={smallModalBody}
            show_modal={smallShowModal}
            onClose={() => setSmallShowModal(false)}
          />
        )}
      </div>

    </>
  );
};

export default GrupoCopropiedad;
