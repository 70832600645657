import React, { useEffect, useState, useRef } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ReactWebcam from "react-webcam";
import Loading from "../../components/loading";
import { BotonPrimaryText, BotonDangerText, BotonInfoText } from "../../components/BotonesCustom";
import { FieldBasic, SelectVivienda } from "../../components/FieldBasic";
import CustomSelect from "../../components/Select";
import axiosInstance from "../../../../../services/AxiosInstance";
import { notifySuccess, notifyError } from "../../components/CitoPlusToastr";
import { useVigilanteReloadContext } from "../../../../../context/VisitantesVigilantesContext";
import { LectorDeBarras } from "../../utils";

import imagen200300 from '../../../../../images/200x300.png';

const formGruposSchema = Yup.object().shape({
  identificacion: Yup.string()
    .required("Por favor, ingresa la identificación.")
    .matches(/^[0-9]+$/, 'La identificación debe ser un número')
    .min(7, "La identificación debe tener 7 dígitos")
    .max(15, "La identificación debe tener 15 dígitos")
    .nullable(true),
  nombre: Yup.string()
    .required("Por favor, ingresa el nombre y los apellidos.")
    .nullable(true),
  autorizado_por: Yup.string()
    .required("Por favor, ingresa el nombre de quien autoriza.")
    .nullable(true),
});

const IngresoVisitantes = ({ showModal, onClose }) => {
  const initialValuesEmpty = {
    identificacion: '',
    nombre: '',
    inmueble: null,
    autorizado_por: '',
    tipo_de_ingreso: { value: '0', label: 'VISITANTE' },
    foto: null,
    ingreso: true
  };

  const URL = "/api/v3/visitantes/ingreso-peatonal/";

  const { setReloadData } = useVigilanteReloadContext();

  const [ initialValues, setInitialValues ] = useState(initialValuesEmpty);
  const [ loadingForm, setLoadingForm ] = useState(false);
  const [ loadingSubmit, setLoadingSubmit ] = useState(false);
  const [ capturedPhoto, setCapturedPhoto ] = useState(null);
  const [ cameraOpen, setCameraOpen ] = useState(false);
  const webcamRef = useRef(null);
  const fileInputRef = useRef(null);

  let submitAction = null;

  const tipoVisitanteOptions = [
    { slug: "0", nombre: "VISITANTE" },
    { slug: "1", nombre: "DOMICILIO" },
    { slug: "2", nombre: "CONTRATISTA" },
    { slug: "3", nombre: "CLIENTE" },
    { slug: "4", nombre: "PROVEEDOR" },
    { slug: "5", nombre: "FAMILIAR" },
    { slug: "6", nombre: "ADMINISTRATIVO" },
  ];

  useEffect(() => {
    if (!showModal) {
      setCapturedPhoto(null);
      setCameraOpen(false);
    }
  }, [showModal]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    let _tipo_de_ingreso;

    if (typeof values.tipo_de_ingreso === 'string') {
      _tipo_de_ingreso = values.tipo_de_ingreso;
    } else if (values.tipo_de_ingreso instanceof Object) {
      _tipo_de_ingreso = values.tipo_de_ingreso.value;
    }

    const postData = {
      identificacion: values.identificacion,
      nombre: values.nombre,
      unidad_visita: values.inmueble,
      autorizado: values.autorizado_por,
      tipo_de_ingreso: _tipo_de_ingreso,
      foto: values.foto || null,
      ingreso: true
    }

    try {
      setLoadingSubmit(false);
      const response = await axiosInstance.post(URL, postData);
      setInitialValues(initialValuesEmpty);
      onClose();
      notifySuccess("✔️ El ingreso fue exitoso!");
      onClose();
      resetForm();
      setReloadData(prev => ({ ...prev, visitantes: true }));
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const handleSaveAndAddAnother = async (values, { setFieldValue, setSubmitting, resetForm }) => {
    const postData = {
      identificacion: values.identificacion,
      nombre: values.nombre,
      unidad_visita: values.inmueble,
      autorizado: values.autorizado_por,
      tipo_de_ingreso: values.tipo_de_ingreso.value,
      foto: values.foto || null,
      ingreso: true
    }

    try {
      setLoadingSubmit(false);
      const response = await axiosInstance.post(URL, postData);
      setInitialValues(initialValuesEmpty);
      notifySuccess("✔️ El ingreso fue exitoso !");
      setCapturedPhoto(null);
      setCameraOpen(false);
      resetForm();
      setReloadData(prev => ({ ...prev, visitantes: true }));
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setSubmitting(false);
      setLoadingSubmit(false);
    }
  };

  const handleClearForm = async (values, { setFieldValue, resetForm }) => {
    resetForm();
    setFieldValue('tipo_de_ingreso', { value: '0', label: 'VISITANTE' });
    setInitialValues(initialValues);
    setCapturedPhoto(null);
    setCameraOpen(false);
  };

  const capturePhoto = (setFieldValue) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      setCapturedPhoto(imageSrc);
      setFieldValue('foto', imageSrc);  // Actualizar el campo foto en Formik
      setCameraOpen(false);
    }
  };

  const handleFileUpload = (event, setFieldValue) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setCapturedPhoto(reader.result);
        setFieldValue('foto', reader.result);  // Actualizar el campo foto en Formik
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  function handleErrorResponse(error) {
    let errorMessages = [];

    // Verificar si el error tiene una respuesta y datos
    if (error.response && error.response.data) {
      const errorData = error.response.data;

      if (errorData.type === "validation_error" && Array.isArray(errorData.errors)) {
        errorData.errors.forEach(err => {
          // Combina el atributo y el mensaje de detalle
          const fieldMessage = `${err.attr}: ${err.detail}`;
          errorMessages.push(fieldMessage);
        });
      } else if (errorData.message) {
        errorMessages.push(errorData.message);
      } else {
        errorMessages.push("An unexpected error occurred.");
      }
    } else {
      errorMessages.push(error.message || "An unexpected error occurred.");
    }

    const errorMessage = errorMessages.join("\n");

    // Mostrar el mensaje de error con react-toastify
    notifyError(`Error: ${errorMessage}`);
  };

  return (
    <Modal show={showModal} size="lg">
      {loadingForm ? (
        <Loading />
      ) : (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={formGruposSchema}
          onSubmit={(values, { setFieldValue, setSubmitting, isSubmitting, resetForm }) => {
            if (submitAction === "handleSubmit") {
              handleSubmit(values, { setFieldValue, setSubmitting, resetForm });
            } else if (submitAction === "handleSaveAndAddAnother") {
              handleSaveAndAddAnother(values, { setFieldValue, setSubmitting, resetForm });
            }
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            resetForm,
            setSubmitting
          }) => (
            <div role="document">
              <form onSubmit={handleSubmit}>
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">
                    Ingreso Visitante Peatonal
                  </h5>
                </div>

                <div className="modal-body">
                  <div className="row justify-content-center">
                    <div className="container col">
                      <div className="row">
                        <div className="col">
                          <div className="row">
                            <BotonPrimaryText
                              type="button"
                              onClick={() => setCameraOpen(!cameraOpen)}
                            >
                              {cameraOpen ? "Cerrar Cámara" : "Tomar Foto"}
                            </BotonPrimaryText>
                          </div>
                        </div>
                        <div className="col">
                          <div className="row">
                            {cameraOpen ? (
                              <BotonInfoText
                                type="button"
                                onClick={() => capturePhoto(setFieldValue)}
                              >
                                Capturar Foto
                              </BotonInfoText>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  className="form-file-input form-control"
                                  accept="image/*"
                                  ref={fileInputRef}
                                  onChange={(event) => handleFileUpload(event, setFieldValue)}
                                  style={{ display: 'none' }}
                                />
                                <BotonPrimaryText
                                  type="button"
                                  onClick={handleUploadClick}
                                >
                                  Subir Foto
                                </BotonPrimaryText>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="p-2">
                          <div style={{ height: 300, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {cameraOpen ? (
                              <ReactWebcam
                                audio={false}
                                ref={webcamRef}
                                screenshotFormat="image/jpeg"
                                width={200}
                                height={300}
                                minScreenshotHeight={1024}
                                minScreenshotWidth={1536}
                                style={{
                                  objectFit: 'cover',
                                }}
                                videoConstraints={{
                                  width: 1024,
                                  height: 1536,
                                  facingMode: "user"
                                }}
                              />
                            ) : (
                              <div style={{ height: '100%' }}>
                                {capturedPhoto ? (
                                  <img
                                    src={capturedPhoto}
                                    className="img-fluid"
                                    alt="Captured"
                                    style={{
                                      width: '200px',
                                      height: '300px',
                                      objectFit: 'cover',
                                    }}
                                  />
                                ) : (
                                  <img
                                    src={imagen200300}
                                    className="img-fluid"
                                    alt="Placeholder"
                                    style={{
                                      objectFit: 'cover',
                                    }}
                                  />
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md">
                      <div className="p-2">
                        <div className="form-group pb-2">
                          <FieldBasic
                            name="identificacion"
                            required
                            errors={errors.identificacion}
                          >
                            <Field
                              type="text"
                              name="identificacion"
                              id="identificacion"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Identificación"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.identificacion}
                              onKeyDown={(e) => LectorDeBarras(e, { setFieldValue })}
                            />
                          </FieldBasic>
                        </div>
                        <div className="form-group pb-2">
                          <FieldBasic
                            name="nombre"
                            required
                            errors={errors.nombre}
                          >
                            <Field
                              type="text"
                              name="nombre"
                              id="nombre"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Nombre y apellidos"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.nombre}
                            />
                          </FieldBasic>
                        </div>
                        <div className="form-group pb-2">
                          <FieldBasic
                            name="autorizado_por"
                            required
                            errors={errors.autorizado_por}
                          >
                            <Field
                              type="text"
                              name="autorizado_por"
                              id="autorizado_por"
                              autoComplete="off"
                              className="form-control"
                              placeholder="Autorizado por"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.autorizado_por}
                            />
                          </FieldBasic>
                        </div>
                        <div className="form-group pb-2">
                          <CustomSelect
                            id="tipo_de_ingreso"
                            name="tipo_de_ingreso"
                            options={tipoVisitanteOptions}
                            initSelection={{ value: 'VISITANTE', label: 'VISITANTE' }}
                            // initSelection={{'VISITANTE' : 'VISITANTE'}}
                            defaultValue={values.tipo_de_ingreso}
                            onChange={(selectedOption) => {
                              setFieldValue('tipo_de_ingreso', selectedOption ? selectedOption.value : null);
                            }}
                          />
                        </div>
                        <div className="form-group pb-2">
                          <SelectVivienda
                            id="inmueble"
                            name="inmueble"
                            isSearchable="true"
                            isClearable="true"
                            errors={errors.inmueble}
                            onChange={(selectedOptions) => {
                              setFieldValue('inmueble', selectedOptions ? selectedOptions.label : null);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="modal-footer">
                  {loadingSubmit ? (
                    <div className="text-center">
                      <Loading />
                    </div>
                  ) : (
                    <>
                      <BotonDangerText
                        type="button"
                        onClick={onClose}
                      >
                        Cancelar
                      </BotonDangerText>
                      <BotonDangerText
                        type="button"
                        onClick={() => handleClearForm(values, { setFieldValue, setSubmitting, resetForm })}
                        disabled={isSubmitting}
                      >
                        Limpiar
                      </BotonDangerText>
                      <BotonPrimaryText
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => {
                          submitAction = "handleSubmit";
                        }}
                      >
                        Guardar
                      </BotonPrimaryText>
                      <BotonPrimaryText
                        type="submit"
                        disabled={isSubmitting}
                        onClick={() => {
                          submitAction = "handleSaveAndAddAnother";
                          // return handleSaveAndAddAnother(values, { setFieldValue, setSubmitting, isSubmitting, resetForm })
                        }}
                      >
                        Guardar y añadir otro
                      </BotonPrimaryText>
                    </>
                  )}
                </div>
              </form>
            </div>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default IngresoVisitantes;
