import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useTable, useFilters, usePagination } from 'react-table';
// import '../../table/FilteringTable/filtering.css';
import axiosInstance from 'services/AxiosInstance';
import { getDefaultParams } from 'services/DefaultParamets';
import Tables from 'jsx/components/CitoPlus/components/tables';
import TruncateText from 'jsx/components/CitoPlus/components/truncateText';
import { handleErrors } from 'jsx/components/CitoPlus/components/ErrorHandlingComponent';
import { BotonDanger, BotonDangerText, BotonPrimaryText } from 'jsx/components/CitoPlus/components/BotonesCustom';
import MinutaCategoriaModal from 'jsx/components/CitoPlus/administradores/Seguridad/MinutaCategoriaModal';

import _ from 'lodash';

import noImagen from 'images/no-image.jpg';

export const Minuta = () => {

  const URL = '/api/v3/vigilancia/minuta-categorias-administradores/';

  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [sortField, setSortField] = useState('');
  const [order, setOrder] = useState('asc');

  const [nextUrl, setNextUrl] = useState([]);
  const [previousUrl, setPreviousUrl] = useState([]);

  const [defaultParams, setDefaultParams] = useState(getDefaultParams);

  // Moda Agregar/Editar Categoria
  const [showModal, setShowModal] = useState(false);
  const [uuidMinuta, setUuidMinuta] = useState(null);
  const [modalEditMode, setModalEditMode] = useState(false);
  const [categoriaUUID, setCategoriaUUID] = useState(null);

  const columns = useMemo(() => [
    {
      id: "nombre",
      Header: "Nombre",
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => (
        <TruncateText text={row.nombre} />
      )
    },
    {
      id: "color",
      Header: "Color",
      disableFilters: true,
      disableSortable: true,
      accessor: (row) => (
        <div className="d-flex align-items-center">
          <div
            className="rounded-circle"
            style={{
              width: '20px',
              height: '20px',
              // remover _
              backgroundColor:  _.replace(row.color, /_/g, ''),
              marginRight: '10px'
            }}
          >
          </div>
          <span>{row.color}</span>
        </div>
      )
    },
    {
      id: 'acciones',
      Header: 'Acciones',
      accessor: (row) => (
        <div className="">
          <BotonDangerText
            onClick={() => console.log(row)}
          >
            <i className="fas fa-trash"></i>
          </BotonDangerText>
          <BotonPrimaryText
            onClick={() => console.log(row)}
          >
            <i className="fas fa-edit"></i>
          </BotonPrimaryText>
        </div>
      ),
      disableFilters: true,
      disableSortable: true,
    },
  ], []);

  const debounce = (func, delay) => {
    let timeoutId;
    return function (...args) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
    };
  };

  const debouncedfetchUrl = useCallback(
    debounce((pageIndex, search) => {
      fetchUrl(pageIndex, search);
    }, 500), // El valor 500 es el tiempo de espera en milisegundos
    []
  );

  useEffect(() => {
    fetchUrl(1);
  }, []);


  const fetchUrl = async (pageIndex = 1, filterParams = {}, ordering = []) => {
    defaultParams.page = pageIndex;
    setLoading(true);
    try {
      if (pageIndex < 1 || pageIndex > totalPages) {
        return;
      }
      const response = await axiosInstance.get(
        URL,
        {
          params: {
            ...defaultParams,
            ...filterParams,
            page: pageIndex,
            ordering: ordering
          }
        }
      );
      setNextUrl(response.data.next);
      setPreviousUrl(response.data.previous);
      setTotalPages(response.data.total_pages);
      setCurrentPage(response.data.current_page);
      setData(response.data.results);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSearchChange = (value) => {
    setLoading(true);
    debouncedfetchUrl(1, value);
  };

  const handleEnterPress = () => {
    // Lógica para manejar la tecla Enter, si es necesario
    console.log('Presionó Enter');
  };

  const handleClearClick = () => {
    handleSearchChange('');
  };

  const handleSortingChange = (column) => {
    const { id } = column;

    const newOrder = sortField === id ? (order === 'asc' ? 'desc' : 'asc') : 'asc';

    setSortField(id);
    setOrder(newOrder);
    setLoading(true);
    fetchUrl(1, searchValue, `${newOrder === 'desc' ? '-' : ''}${id}`);
  };

  const tableInstance = useTable(
    {
      columns,
      data,
      manualPagination: true,
      pageCount: totalPages,
    },
    useFilters,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page
  } = tableInstance;

  return (
    <>
      <div className='d-flex aling-items-center mb-4 flex-wrap'>
        <h4 className="fs-20 font-w600 me-auto">Minutas Categorias</h4>
        <BotonPrimaryText
          onClick={() => {
            setShowModal(true);
            setCategoriaUUID(null);
          }}
        >
          <i className="fas fa-plus me-2"></i>
          Agregar Categoria
        </BotonPrimaryText>
      </div>
      <div className="card">
        <div className="card-body">
          {loading ? (
            <p>Cargando...</p>
          ) : data.length === 0 ? (
            <p>No hay datos disponibles.</p>
          ) : (
            <Tables
              columns={columns}
              data={data}
              loading={loading}
              totalPages={totalPages}
              currentPage={currentPage}
              fetchUrl={fetchUrl}
              handleSortingChange={handleSortingChange}
              nextUrl={nextUrl}
              previousUrl={previousUrl}
              sortField={sortField}
              order={order}
              page={page}
            />
          )}
        </div>
      </div>

      <MinutaCategoriaModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
        uuidMinuta={uuidMinuta}
        editMode={modalEditMode}
        url={URL}
      />
    </>
  );
};

export default Minuta;
