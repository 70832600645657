import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import Loading from '../components/loading';
import axiosInstance from '../../../../services/AxiosInstance';
import { handleErrors } from '../components/ErrorHandlingComponent';

const FacturaPark = ({
  isOpen,
  onClose,
  URL = '',
  dataFromPatch = {},
}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(dataFromPatch);

  const getFacturaDetail = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      setData(response.data);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    getFacturaDetail();

    // Limpieza al desmontar el componente
    return () => {
      setData({});
    };
  }, [URL]);

  const printReceipt = () => {
    const contenido = document.getElementById('print-receipt').innerHTML;
    const ventanaImpresion = window.open('', '', 'height=600,width=800');
    ventanaImpresion.document.write('<html><head><title>Impresión de Recibo</title>');
    ventanaImpresion.document.write('<style>body { font-family: Roboto, arial; }</style>');
    ventanaImpresion.document.write('</head><body>');
    ventanaImpresion.document.write(contenido);
    ventanaImpresion.document.write('</body></html>');
    ventanaImpresion.document.close();
    ventanaImpresion.print();
    ventanaImpresion.close();
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP' }).format(value);
  };

  if (loading) {
    return (
      <Modal show={isOpen} onHide={onClose}>
        <Modal.Header closeButton>
          <Modal.Title>Recibo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loading />
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Recibo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="receipt text-left" id="print-receipt">
          <h3>{data.nombre_copropiedad}</h3>
          <p>{data.direccion}</p>
          <p>{data.telefono_fijo}</p>
          <p>{data.consecutivo}</p>
          {data.unidad_visita && <p><b>INMUEBLE: </b> {data.unidad_visita}</p>}
          {data.park && <p><b>PARQUEADERO: </b> {data.park}</p>}
          <p><b>TARIFA:</b> {data.duracion?.tipo === 'M' ? 'MINUTOS' : 'HORA'}</p>
          <p><b>VIGILANTE INGRESO:</b> {data.nombre_guarda_entrada}</p>
          {data.h_salida && <p><b>VIGILANTE SALIDA:</b> {data.nombre_guarda_salida}</p>}
          <p><b>PLACA:</b> {data.vehiculo}</p>
          {data.park_bill_data?.tipo && <p><b>TIPO VEHÍCULO:</b> {data.park_bill_data.tipo}</p>}
          <p><b>FECHA ENTRADA:</b> {data.h_ingreso}</p>
          {data.h_salida && <p><b>FECHA SALIDA:</b> {data.h_salida}</p>}
          <p><b>TIEMPO:</b> {data.duracion?.duracion} {data.duracion?.tipo === 'M' ? 'MINUTOS' : 'HORA'}</p>
          <p><b>TIEMPO GRACIA:</b> {data.total_gracia}</p>
          <p><b>VALOR A PAGAR:</b> {formatCurrency((data.total ?? 0) - (data.impuesto ?? 0))}</p>
          <p><b>IMPUESTO:</b> {formatCurrency(data.impuesto)}</p>
          <p><b>TOTAL A PAGAR:</b> {formatCurrency(data.total ?? 0)}</p>
          <div className="pt-3"></div>
          <p className="text-justify">
            <strong>
              {data.footer_text
                ? data.footer_text
                : 'Nuestra responsabilidad está limitada a la custodia del vehículo...'}
            </strong>
          </p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-citoplus-danger" onClick={onClose}>
          Cerrar
        </button>
        <button className="btn btn-citoplus-success" onClick={printReceipt}>
          Imprimir
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default FacturaPark;
