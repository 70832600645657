import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Formik, resetForm } from "formik";
import axiosInstance from 'services/AxiosInstance';
import swal from "sweetalert";
import * as Yup from "yup";
import { BotonPrimary, BotonPrimaryText } from "jsx/components/CitoPlus/components/BotonesCustom";
import CheckboxOnOff from "jsx/components/CitoPlus/components/CheckboxOnOff";
import { notifyError, notifySuccess } from "jsx/components/CitoPlus/components/CitoPlusToastr";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import { set } from "lodash";


const notificacionesSchema = Yup.object().shape({
});


const NotificacionesConfiguraciones = () => {

  const initialValuesEmpty = {
    send_whatsapp: true,
    notify_correspondencia: true,
    notify_visitante: true,
    notify_pqr: true,
    notify_autorizaciones: true,
    notify_cartelera: true,
    notify_documentos: true,
  };

  const URL = "/api/v3/notificaciones/configuracion/admin/";
  const [initialValues, setInitialValues] = useState(initialValuesEmpty);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
    fetchNotificacionesConfiguraciones();
  }, []);

  const fetchNotificacionesConfiguraciones = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      // Verifica si el arreglo no está vacío
      if (response.data.length > 0) {
        setInitialValues(response.data[0]); // Usa los datos del servidor si están disponibles
      } else {
        setInitialValues(initialValuesEmpty); // Usa los valores por defecto si el arreglo está vacío
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }

  const handleSubmit = async (values, { setSubmitting, resetForm } ) => {
    setSubmitting(true);
    try {
      const response = await axiosInstance.post(URL, values);
      notifySuccess("Configuración de notificaciones guardada con éxito");
    } catch (error) {
      handleErrors(error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  }

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Notificaciones Configuraciones</h4>
            </div>
            <div className="card-body">
              <div className="basic-form">
                <Formik
                  initialValues={initialValues}
                  validationSchema={notificacionesSchema}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                  }) => (
                    <form
                      className="form-valide"
                      onSubmit={handleSubmit}>
                      <div className="row">
                        <fieldset className="">
                          <legend>Canales de notificaciones </legend>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <CheckboxOnOff
                                label="Enviar notificaciones via WhatsApp"
                                id="send_whatsapp"
                                name="send_whatsapp"
                                isChecked={values.send_whatsapp}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </fieldset>

                        <fieldset className="pt-5">
                          <legend className="w-auto">Notificaciones </legend>
                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_visitante"
                                name="notify_visitante"
                                label="Enviar notificaciones de visitantes"
                                isChecked={values.notify_visitante}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_correspondencia"
                                name="notify_correspondencia"
                                label="Enviar notificaciones de correspondencia"
                                isChecked={values.notify_correspondencia}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_pqr"
                                name="notify_pqr"
                                label="Enviar notificaciones de PQR"
                                isChecked={values.notify_pqr}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_autorizaciones"
                                name="notify_autorizaciones"
                                label="Enviar notificaciones de autorizaciones"
                                isChecked={values.notify_autorizaciones}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_cartelera"
                                name="notify_cartelera"
                                label="Enviar notificaciones de cartelera"
                                isChecked={values.notify_cartelera}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="form-group mb-3 row">
                            <div className="form-group">
                              <CheckboxOnOff
                                id="notify_documentos"
                                name="notify_documentos"
                                label="Enviar notificaciones de documentos"
                                isChecked={values.notify_documentos}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </fieldset>

                        <div className="card-footer text-end">
                          <BotonPrimaryText
                            type="submit"
                            className="btn btn-primary"
                            disabled={isSubmitting}
                          >
                            <i className="fa fa-save me-2"></i>
                            Guardar
                          </BotonPrimaryText>
                        </div>

                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificacionesConfiguraciones;
