import React, { useEffect, useState } from "react";
import axiosInstance from "services/AxiosInstance";
import { handleErrors } from "jsx/components/CitoPlus/components/ErrorHandlingComponent";
import { Spinner } from 'react-bootstrap';
import pse_logo from 'images/pse_logo.png';
import { LinkPrimary } from "jsx/components/CitoPlus/components/CitoPlusLink";
import _ from 'lodash';


export const PagarServicio = () => {

  const URL = "/api/v3/usuarios/perfil/me/precios-del-servicio/";
  const [bancos, setBancos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tiposPago, setTiposPago] = useState([]);
  const [pagoSeleccionado, setPagoSeleccionado] = useState({});
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const [formData, setFormData] = useState({
    bancoId: '',
    bancoNombre: '',
    tipo_persona: '',
  });

  useEffect(() => {
    fetchTiposPago();
  }, []);

  useEffect(() => {
    // Obtener la lista de bancos desde la API
    axiosInstance.get('/api/v3/pagos/pse-lista-bancos/')
      .then(response => {
        setBancos(response.data);
      })
      .catch(error => {
        handleErrors(error);
      });
  }, []);

  const fetchTiposPago = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(URL);
      const data = response.data;
      setTiposPago(data);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const msg = params.get('msg');
    if (msg) {
      setMessage(decodeURIComponent(msg));
    }
  }, []);

  const handleChangeBanco = (event) => {
    const bancoId = event.target.value;
    const bancoNombre = event.target.options[event.target.selectedIndex].text;
    setFormData({
      ...formData,
      bancoId,
      bancoNombre,
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setLoading(true);

    if (!formData.bancoId || !formData.tipo_persona) {
      alert('Por favor, complete todos los campos.');
      return;
    }

    // Datos a enviar al backend
    const data = {
      bancoId: formData.bancoId,
      bancoNombre: formData.bancoNombre,
      tipo_persona: formData.tipo_persona,
      tiempo_seleccionado: pagoSeleccionado.name,
      valor: pagoSeleccionado.valor,
    };

    axiosInstance.post('/api/v3/pagos/pago-servicio-citoplus/', data)
      .then(response => {
        // si tienen redirect_url
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else {
          alert(response.data.message);
        }
      })
      .catch(error => {
        try {
          setError(error.response.data.message);
        } catch (e) {
          handleErrors(error);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  if (loading) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Spinner animation="border" role="status" />
      </div>
    );
  }

  return (
    <>
      <div className="d-flex align-items-center mb-4 justify-content-center">
        <img src={pse_logo} alt="PSE" className="mr-2" style={{ width: '5%' }} />
        <h3>Pagar Servicio</h3>
      </div>
      <div className="d-flex align-items-center mb-4 justify-content-center">
        {message && (
          <div className="alert alert-info" role="alert">
            {message}
          </div>
        )}
      </div>
      <div className="d-flex align-items-center mb-4 justify-content-center">
        <div className="col-md-10">
          <div className="card">
            <div className="card-body">
              <div className="alert alert-info align-items-center justify-content-center" role="alert">
                Seleccione el tiempo de pago
              </div>
              <div className="container">
                <div className="row justify-content-center">
                  {tiposPago.map((tipoPago, index) => (
                    tipoPago.valor !== null ? (
                      <div key={index} className="col-6 col-md-6 col-lg-6 mb-3">
                        {tipoPago.descripcion ? (
                          <LinkPrimary
                            to="#"
                            onClick={() => setPagoSeleccionado(tipoPago)}
                            style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                          >
                            {_.capitalize(tipoPago.descripcion)}
                          </LinkPrimary>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-primary w-100"
                            onClick={() => setPagoSeleccionado(tipoPago)}
                          >
                            <i className="fas fa-credit-card me-2"></i>
                            {tipoPago.name}
                          </button>
                        )}
                      </div>
                    ) : null
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pagoSeleccionado.name && (
        <div className="row justify-content-md-center">
          <div className="col-md-10">
            <div className="card">
              <div className="card-body">
                {/* Mostrar la alerta en caso de error */}
                {error && (
                  <div className="alert alert-danger" role="alert">
                    {error}
                  </div>
                )}
                <form onSubmit={handleSubmit}>
                  <div className="row">
                    {/* Columna izquierda: Formulario */}
                    <div className="col-md-6">
                      <div className="form-group">
                        <label htmlFor="banco">Banco</label>
                        <select
                          className="form-select"
                          aria-label="Lista de Bancos"
                          id="banco"
                          name="banco"
                          value={formData.bancoId}
                          onChange={handleChangeBanco}
                          required
                        >
                          <option value="">Seleccione un banco</option>
                          {bancos.map((banco) => (
                            <option key={banco.codigoBanco} value={banco.codigoBanco}>
                              {banco.nombreBanco}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="form-group mt-3">
                        <label htmlFor="tipo_persona">Tipo de Persona</label>
                        <select
                          className="form-select"
                          aria-label="Tipo de Persona"
                          id="tipo_persona"
                          name="tipo_persona"
                          value={formData.tipo_persona}
                          onChange={handleChange}
                          required
                        >
                          <option value="">Seleccione un tipo de persona</option>
                          <option value="0">Natural</option>
                          <option value="1">Jurídica</option>
                        </select>
                      </div>
                      {loading ? (
                        <button type="submit" className="btn btn-primary mt-4" disabled>
                          Cargando...
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary mt-4">
                          Pagar
                        </button>
                      )}
                    </div>

                    {/* Columna derecha: Detalle */}
                    <div className="col-md-6">
                      <div className="alert alert-info mt-3" role="alert">
                        <h5>Detalle de la transacción</h5>
                        <p>Total a recargar: $ {pagoSeleccionado.valor}</p>
                        {pagoSeleccionado.descripcion ? (
                          <p>Detalle: {pagoSeleccionado.descripcion}</p>
                        ) : (
                          <p>Detalle: Pago del Servicio CitoPlus</p>
                        )}
                      </div>
                      <div className="alert alert-warning" role="alert">
                        <p>Recuerda verificar tu banco antes de proceder.</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PagarServicio;
