import React, { useState, useEffect } from 'react';
import axiosInstance from 'services/AxiosInstance';
import { Form, Spinner, Row, Col, Alert, Button } from 'react-bootstrap';
import { CardPlanoParqueaderos } from './CardPlanoParqueaderos';
import _ from 'lodash';

const Parking = () => {
  const [parkings, setParkings] = useState([]);
  const [filteredParkings, setFilteredParkings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('TODOS');
  const [searchTerm, setSearchTerm] = useState('');
  const [ocupado, setOcupado] = useState('TODOS');

  const fetchParkings = async () => {
    setLoading(true);
    try {
      const response = await axiosInstance.get('/api/v3/parking/plano-parqueaderos/');
      setParkings(response.data);
      setFilteredParkings(response.data); // Iniciar con todos los parqueaderos cargados
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchParkings();
  }, []);

  // useEffect para combinar filtros
  useEffect(() => {
    const filtered = _.filter(parkings, (p) => {
      const matchesFilter = filter === 'TODOS' || p.tipo_parqueadero === filter;
      const matchesOcupado = ocupado === 'TODOS' || p.ocupado === (ocupado === 'OCUPADO');
      const matchesSearch = p.no_parqueadero.toString().includes(searchTerm);

      // Retorna solo los parqueaderos que cumplan con todos los criterios
      return matchesFilter && matchesOcupado && matchesSearch;
    });

    setFilteredParkings(filtered);
  }, [filter, ocupado, searchTerm, parkings]);

  if (loading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" />
      </div>
    );
  }

  if (error) {
    return <Alert variant="danger">Error: {error.message}</Alert>;
  }

  return (
    <div>
      <div className="filters-group d-flex mb-3">
        {/* Input para búsqueda */}
        <Form.Control
          type="text"
          placeholder="Buscar"
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ width: 200, marginRight: 10 }}
        />

        {/* Select para filtro de tipo de parqueadero */}
        <Form.Select
          defaultValue="TODOS"
          onChange={(e) => setFilter(e.target.value)}
          style={{ width: 200 }}
        >
          <option value="TODOS">TODOS</option>
          <option value="COMUN">COMUN</option>
          <option value="DE_VISITANTES">VISITANTES</option>
          <option value="PRIVADA">PRIVADA</option>
          <option value="DISCAPACITADOS">DISCAPACITADOS</option>
          <option value="BICICLETERO">BICICLETERO</option>
          <option value="DESHABILITADO">DESHABILITADO</option>
          <option value="DISPONIBLE">DISPONIBLE</option>
        </Form.Select>

        {/* Select para filtro de estado de ocupación */}
        <Form.Select
          defaultValue="TODOS"
          onChange={(e) => setOcupado(e.target.value)}
          style={{ width: 200, marginLeft: 10 }}
        >
          <option value="TODOS">TODOS</option>
          <option value="OCUPADO">OCUPADO</option>
          <option value="DISPONIBLE">DISPONIBLE</option>
        </Form.Select>

        {/* Botón de recarga */}
        <Button onClick={fetchParkings} style={{ marginLeft: 10 }} size='sm'>
          <i className="fas fa-sync-alt me-2"></i>
          Recargar
        </Button>
      </div>

      {/* Grid de parqueaderos */}
      <Row className="g-3" style={{ marginTop: 20 }}>
        {filteredParkings.map((parking) => (
          <Col key={parking.id}>
            <CardPlanoParqueaderos parking={parking} />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Parking;
